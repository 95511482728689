import { SortDirection } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type ClusterInstance = {
  id: string;
  metadata: { cloud_provider: string; k8s_version: string };
  cloud_provider: string;
  k8s_version: string;
  last_scan_id: string;
  region: string;
  name: string;
  type: string;
};
type SortOrder = 'asc' | 'desc';

type TableData = ClusterInstance[];

export default function UseLARDetailsTableSort(
  data: TableData,
  order: SortOrder,
  orderByColumnName: string,
) {
  const [dataToShow, setDataToShow] = useState<ClusterInstance[]>([]);
  const [sortOrder, setSortOrder] = useState<SortDirection>(order);
  const [orderBy, setOrderBy] = useState<string>(orderByColumnName);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function titlesComparator(a: ClusterInstance, b: ClusterInstance) {
    if (b.name < a.name) {
      return -1;
    } else if (b.name > a.name) {
      return 1;
    } else {
      return 0;
    }
  }

  const getComparator = useCallback((order: any, orderBy: any) => {
    switch (orderBy) {
      case 'name':
        return order === 'desc'
          ? (a: ClusterInstance, b: ClusterInstance) => titlesComparator(a, b)
          : (a: ClusterInstance, b: ClusterInstance) => -titlesComparator(a, b);

      default:
        return order === 'desc'
          ? (a: ClusterInstance, b: ClusterInstance) => titlesComparator(a, b)
          : (a: ClusterInstance, b: ClusterInstance) => -titlesComparator(a, b);
    }
  }, []);

  const stableSort = (
    array: any[],
    comparator: (sortOrder: any, orderBy: any) => number,
  ) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return 0;
    });
    return stabilizedThis.map((el) => el[0]);
  };

  useEffect(() => {
    if (data) {
      setDataToShow(data);
    }
  }, [data]);

  const sortedData = useMemo(() => {
    const data = stableSort(dataToShow, getComparator(sortOrder, orderBy));
    return data;
  }, [dataToShow, getComparator, sortOrder, orderBy]);

  return {
    sortOrder,
    orderBy,
    handleRequestSort,
    sortedData,
    setSortOrder,
  };
}
