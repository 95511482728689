'use client';
/*
 * Documentation:
 * Summary Banner — https://app.subframe.com/library?component=Summary+Banner_c9583b69-c9f9-4ec2-b22e-46abfcc5ac23
 * Badge — https://app.subframe.com/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface SummaryItemProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  value?: string;
  badge?: React.ReactNode;
  className?: string;
}

const SummaryItem = React.forwardRef<HTMLElement, SummaryItemProps>(
  function SummaryItem(
    { name, value, badge, className, ...otherProps }: SummaryItemProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex w-full flex-col items-start',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex items-start gap-2">
          {name ? (
            <span className="text-body font-body text-subtext-color">
              {name}
            </span>
          ) : null}
          {badge ? <div className="flex items-start">{badge}</div> : null}
        </div>
        {value ? (
          <span className="text-body-bold font-body-bold text-default-font">
            {value}
          </span>
        ) : null}
      </div>
    );
  },
);

interface SummaryBannerRootProps extends React.HTMLAttributes<HTMLDivElement> {
  items?: React.ReactNode;
  className?: string;
}

const SummaryBannerRoot = React.forwardRef<HTMLElement, SummaryBannerRootProps>(
  function SummaryBannerRoot(
    { items, className, ...otherProps }: SummaryBannerRootProps,
    ref,
  ) {
    return items ? (
      <div
        className={SubframeCore.twClassNames(
          'flex w-full items-start gap-4 rounded bg-neutral-50 pt-6 pr-6 pb-6 pl-6',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {items}
      </div>
    ) : null;
  },
);

export const SummaryBanner = Object.assign(SummaryBannerRoot, {
  SummaryItem,
});
