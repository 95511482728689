/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

export type ResolvedEntitlementFeaturesItem =
  (typeof ResolvedEntitlementFeaturesItem)[keyof typeof ResolvedEntitlementFeaturesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResolvedEntitlementFeaturesItem = {
  request_addon: 'request_addon',
  request_cloud: 'request_cloud',
  request_upgrade_plan: 'request_upgrade_plan',
  request_upgrade_template: 'request_upgrade_template',
  add_cluster: 'add_cluster',
  add_cloud_connection: 'add_cloud_connection',
} as const;
