/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

export type LarIgnoreReason =
  (typeof LarIgnoreReason)[keyof typeof LarIgnoreReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LarIgnoreReason = {
  FalsePositive: 'FalsePositive',
  ByDesign: 'ByDesign',
  DevTestCluster: 'DevTestCluster',
  Other: 'Other',
} as const;
