import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends Omit<LinkProps, 'className' | 'style' | 'children'> {
  children?: React.ReactNode;
  to: string;
  onClick?: (event: any) => void;
  onContextMenu?: (event: any) => void;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

export const RouterLink = ({
  children,
  to,
  onClick,
  onContextMenu,
  className,
  id,
  style,
  ...otherProps
}: Props) => {
  return (
    <Link
      id={id}
      to={to}
      style={{ textDecoration: 'none', color: 'inherit', ...style }}
      onClick={onClick}
      onContextMenu={onContextMenu}
      className={className}
      {...otherProps}
    >
      {children}
    </Link>
  );
};
