import React from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column';
  spacing?: 1 | 2 | 4 | 8 | 'fill';
  alignItems?: 'start' | 'center' | 'end';
  justifyContent?: 'start' | 'center' | 'end';
  width?: 'fill';
  height?: 'fill';
}

export const Stack = React.forwardRef<HTMLDivElement, Props>(function Stack(
  props,
  ref,
) {
  const {
    className,
    direction = 'column',
    width,
    spacing,
    alignItems,
    justifyContent,
    height,
    ...otherProps
  } = props;

  return (
    <div
      className={classNames(className, 'flex', {
        'flex-row': direction === 'row',
        'flex-col': direction === 'column',
        'items-start': alignItems === 'start',
        'items-center': alignItems === 'center',
        'items-end': alignItems === 'end',
        'justify-start': justifyContent === 'start',
        'justify-center': justifyContent === 'center',
        'justify-end': justifyContent === 'end',
        'w-full': width === 'fill',
        'h-full': height === 'fill',
        'gap-1': spacing === 1,
        'gap-2': spacing === 2,
        'gap-4': spacing === 4,
        'gap-8': spacing === 8,
        'justify-between': spacing === 'fill',
      })}
      {...otherProps}
      ref={ref}
    />
  );
});
