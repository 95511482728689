import { useContext } from 'react';
import {
  UserAccountContext,
  UserAccountContextProps,
} from 'contexts/UserAccountContext';

// ----------------------------------------------------------------------

export default function useUserAccountState(): UserAccountContextProps {
  return useContext(UserAccountContext);
}
