// components
import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import TextView from 'components/TextView';

// hooks
import { useTheme } from '@mui/material/styles';

import { Spacer } from 'components/Spacer';
import { Switch } from 'subframe/index';

const ClusterPropertiesSkeleton = () => {
  const theme = useTheme();

  const clusterPropertiesSectionSkeletonChildren = (
    <Box
      sx={{
        background: theme.palette.grey[900],
        borderRadius: 1,
        padding: theme.spacing(3),
        alignItems: 'center',
        mt: theme.spacing(2),
      }}
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingBottom: theme.spacing(3),
          }}
        >
          Cluster Properties
        </Typography>
      </Box>
      <Grid container columnSpacing={{ sm: 2, md: 15 }}>
        <Grid item sm={12} md={6}>
          <TextView label="Cluster Name">
            <Skeleton width={150} sx={{ mr: 1 }} />
            <Skeleton variant="circular" width={20} height={20} />
          </TextView>
          <TextView label="Environment">
            <Skeleton width={150} sx={{ mr: 1 }} />
            <Skeleton variant="circular" width={20} height={20} />
          </TextView>

          <TextView label="UUID">
            <Skeleton width={200} />
          </TextView>
          <TextView label="Date Created">
            <Skeleton width={200} />
          </TextView>
          <Spacer value={15} />
          <TextView label="Labels">
            <Box height={40}>
              <Skeleton width={100} />
              <Skeleton width={100} />
              <Skeleton width={100} />
            </Box>
          </TextView>
        </Grid>

        <Grid item sm={12} md={6}>
          <TextView label="Version">
            <Skeleton width={150} />
          </TextView>
          <TextView label="Provider">
            <Skeleton width={100} />
          </TextView>
          <TextView label="Location">
            <Skeleton width={150} />
          </TextView>
          <TextView label="Nodes">
            <Skeleton width={50} />
          </TextView>

          <TextView label="Deactivate Cluster">
            <Skeleton width={50} />
          </TextView>
        </Grid>
      </Grid>
    </Box>
  );

  const chkkPropertiesSectionSkeletonChildren = (
    <Box
      sx={{
        background: theme.palette.grey[800],
        borderRadius: 1,
        padding: theme.spacing(3),
        alignItems: 'center',
        marginTop: theme.spacing(3),
      }}
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingBottom: theme.spacing(3),
          }}
        >
          Chkk Properties
        </Typography>
        <Grid container columnSpacing={{ sm: 2, md: 15 }}>
          <Grid item sm={12} md={6}>
            <TextView label="Onboarded Time">
              <Skeleton width={200} />
            </TextView>
          </Grid>
          <Grid item sm={12} md={6}>
            <TextView label="Last Scan">
              <Skeleton width={200} />
            </TextView>
          </Grid>
          <Grid item sm={12} md={6}>
            <TextView label="K8s Connector Version">
              <Skeleton width={200} />
            </TextView>
          </Grid>
          <Grid item sm={12} md={12}>
            <Typography
              variant="h5"
              sx={{
                marginBottom: '8px',
              }}
            >
              Filter Rules
            </Typography>
            <Stack
              sx={{
                height: '200px',
                width: '100%',
                background: 'rgba(145, 158, 171, 0.02)', //theme.palette.grey[800],
                overflow: 'auto',
                overflowY: 'scroll',
                borderRadius: '8px',
                color: theme.palette.grey[500],
                padding: '0px 16px',
              }}
              spacing={2}
              justifyContent="center"
            >
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  const notificationsSectionSkeletonChildren = (
    <Box
      sx={{
        background: theme.palette.grey[800],
        borderRadius: 1,
        padding: theme.spacing(3),
        alignItems: 'center',
        marginTop: theme.spacing(3),
      }}
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingBottom: theme.spacing(3),
          }}
        >
          Notifications
        </Typography>
      </Box>

      <Box
        sx={{
          color: theme.palette.grey[700],
          alignItems: 'center',
          display: 'flex',
        }}
      >
        (Coming Soon)
      </Box>
    </Box>
  );

  return (
    <Container maxWidth={'xl'}>
      {clusterPropertiesSectionSkeletonChildren}
      {chkkPropertiesSectionSkeletonChildren}
      {notificationsSectionSkeletonChildren}
    </Container>
  );
};

export default ClusterPropertiesSkeleton;
