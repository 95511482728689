import React from 'react';
// components
import { Box, SxProps, Typography } from '@mui/material';
interface TextFieldCustomProps {
  sx: SxProps;
  content: string;
}
const TextFieldCustom: React.FC<TextFieldCustomProps> = ({ sx, content }) => {
  return (
    <Box sx={sx}>
      <Typography
        variant="body2"
        sx={{
          marginBottom: '16px',
        }}
      >
        <pre style={{ fontFamily: 'inherit' }}>{content}</pre>
      </Typography>
    </Box>
  );
};

export default TextFieldCustom;
