import { useEffect, useState } from 'react';
import { Tabs } from 'subframe/components/Tabs';

interface TabsProps {
  tabs: {
    id: string;
    title: string | React.ReactNode;
    content: React.ReactNode;
  }[];
  initialTabId?: string;
  onTabChange?: (newId: string) => void;
}

export function TabsWithContent(props: TabsProps): JSX.Element {
  const [tab, setTab] = useState<string>(
    props.tabs.find((t) => t.id === props.initialTabId)?.id || props.tabs[0].id,
  );

  useEffect(() => {
    if (props.initialTabId) {
      setTab(props.initialTabId);
    }
  }, [props.initialTabId]);

  return (
    <>
      <Tabs data-cy="tabs" className="w-auto mr-auto">
        {props.tabs.map((curTab) => {
          return (
            <Tabs.Item
              key={curTab.id}
              data-cy={curTab.id}
              active={tab === curTab.id}
              onClick={() => {
                if (props.onTabChange !== undefined) {
                  props.onTabChange(curTab.id);
                }
                setTab(curTab.id);
              }}
            >
              {curTab.title}
            </Tabs.Item>
          );
        })}
      </Tabs>
      {props.tabs.find((curTab) => curTab.id === tab)?.content}
    </>
  );
}
