/* Release: 40bc7ee5 (Latest – unreleased) */
import React, { ReactNode, useState } from 'react';
import { useListSubscriptions } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import { ResolvedEntitlementFeaturesItem } from 'api/models';
import { AtomicTooltip } from './design-system';
import FeatureAccessDialog from './dialogs/FeatureAccessDialog';
import { getMostRelevanSubscription } from 'utils/subscription_plan';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  upgradePlanType?: 'cluster' | 'addon';
  feature: ResolvedEntitlementFeaturesItem;
  showLock: boolean;
  showExampleData?: boolean;
  tooltipText?: string;
  defaultAction?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: ReactNode;
}

export const LockingWrapper = React.forwardRef<HTMLElement, Props>(
  function LockingWrapper({
    upgradePlanType,
    feature,
    showLock,
    showExampleData = false,
    tooltipText,
    children,
    defaultAction,
    ...otherProps
  }: Props) {
    const [openUpgradeSubscriptionDialog, setOpenUpgradeSubscriptionDialog] =
      useState(false);
    const { account, currentOrganization } = useUserAccountState();
    const { data: Subscriptions } = useListSubscriptions(
      currentOrganization.slug,
      {},
      {
        request: { headers: { Authorization: `Bearer ${account.token}` } },
      },
    );
    const currentSubscription = getMostRelevanSubscription(
      Subscriptions?.subscriptions,
    );
    const currentPlan = currentSubscription?.plan;

    // differentiating b/w undefined and '' to handle upcoming upgrades table rows where we don't want to show tooltip in any case
    // since subframe table rows do not handle tooltip position correctly yet
    const tooltipMessage =
      tooltipText === undefined
        ? showLock
          ? 'This feature is not available for your current Subscription, click to upgrade now'
          : `Request ${
              feature === 'request_upgrade_template'
                ? 'Preverified Upgrade Template'
                : 'Upgrade Plan'
            }`
        : tooltipText;

    return (
      <>
        <AtomicTooltip
          tooltipContent={tooltipMessage}
          style={{ display: tooltipMessage ? '' : 'none' }}
        >
          <div
            onContextMenu={(event) => {
              if (showLock && !showExampleData && !tooltipText) {
                event.stopPropagation();
                event.preventDefault();
                setOpenUpgradeSubscriptionDialog(true);
              } else {
                defaultAction && defaultAction(event);
              }
            }}
            onClick={(event) => {
              if (showLock && !showExampleData && !tooltipText) {
                event.stopPropagation();
                event.preventDefault();
                setOpenUpgradeSubscriptionDialog(true);
              } else {
                defaultAction && defaultAction(event);
              }
            }}
            {...otherProps}
          >
            {children}
          </div>
        </AtomicTooltip>
        <FeatureAccessDialog
          upgradePlanType={upgradePlanType}
          open={openUpgradeSubscriptionDialog}
          setOpen={setOpenUpgradeSubscriptionDialog}
          currentPlan={currentPlan || ''}
          feature={feature}
        />
      </>
    );
  },
);
