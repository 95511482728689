import * as SubframeCore from '@subframe/core';
import React from 'react';
import styles from './AtomicTooltip.module.scss';
import { Tooltip } from 'subframe/index';

interface TooltipRootProps extends React.HTMLAttributes<HTMLDivElement> {
  tooltipContent?: React.ReactNode | string;
  side?: 'top' | 'right' | 'bottom' | 'left';
  children?: React.ReactNode;
  align?: 'start' | 'center' | 'end';
}

const TooltipRoot = React.forwardRef<HTMLElement, TooltipRootProps>(
  function TooltipRoot(
    {
      children = 'Tooltip',
      tooltipContent,
      side = 'bottom',
      align = 'center',
      ...otherProps
    }: TooltipRootProps,
    ref,
  ) {
    return (
      <>
        {tooltipContent ? (
          <SubframeCore.Tooltip.Provider delayDuration={200}>
            <SubframeCore.Tooltip.Root>
              <SubframeCore.Tooltip.Trigger asChild={true}>
                {children}
              </SubframeCore.Tooltip.Trigger>
              <SubframeCore.Tooltip.Portal>
                <SubframeCore.Tooltip.Content
                  side={side}
                  align={align}
                  sideOffset={8}
                  asChild={true}
                  className={styles['tooltip-content']}
                  {...otherProps}
                  style={{ display: !!tooltipContent ? '' : 'None' }}
                >
                  <Tooltip data-cy={'tooltip-content'}>
                    {tooltipContent}
                  </Tooltip>
                </SubframeCore.Tooltip.Content>
              </SubframeCore.Tooltip.Portal>
            </SubframeCore.Tooltip.Root>
          </SubframeCore.Tooltip.Provider>
        ) : (
          <> {children}</>
        )}
      </>
    );
  },
);

export const moreTooltipContent = (contentList: any[], start?: number) => {
  if (contentList) {
    const sliceEnd = contentList.length - 12 ? 11 : contentList.length; // if more than 11 items, show 10 items and '... x more' at the end, otherwise show all items
    return (
      contentList.slice(start != undefined ? start : 0, sliceEnd).join(', ') +
      `${
        contentList.length - sliceEnd > 0
          ? ' +' + (contentList.length - 11) + ' more'
          : ''
      }`
    );
  }
  return '';
};

export const AtomicTooltip = TooltipRoot;
