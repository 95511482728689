type Props = {
  initials: string;
  color: string;
  width?: string;
  height?: string;
};

export default function InitialsIcon({ initials, color, ...other }: Props) {
  return (
    <svg viewBox="0 0 120 120" style={{ borderRadius: '50%', ...other }}>
      <rect
        x="0"
        y="0"
        width="120"
        height="120"
        rx="15"
        ry="15"
        fill={color}
      ></rect>
      <text
        x="50%"
        y="50%"
        fontSize="60"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#FFFFFF"
      >
        {initials}
      </text>
    </svg>
  );
}
