'use client';
/*
 * Documentation:
 * Details List — https://app.subframe.com/library?component=Details+List_936d99e7-8a24-40f9-a4ce-c876fc56a6bd
 * Badge — https://app.subframe.com/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface DetailsListRowProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: SubframeCore.IconName;
  label?: string;
  value?: string;
  className?: string;
}

const DetailsListRow = React.forwardRef<HTMLElement, DetailsListRowProps>(
  function DetailsListRow(
    {
      icon = 'FeatherMail',
      label,
      value,
      className,
      ...otherProps
    }: DetailsListRowProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex w-full items-center justify-between pt-2 pb-2',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex items-center gap-1.5">
          <SubframeCore.Icon
            className="text-body font-body text-neutral-600"
            name={icon}
          />
          {label ? (
            <span className="text-body font-body text-neutral-600">
              {label}
            </span>
          ) : null}
        </div>
        {value ? (
          <span className="text-body-bold font-body-bold text-default-font">
            {value}
          </span>
        ) : null}
      </div>
    );
  },
);

interface DetailsListBadgeRowProps
  extends React.HTMLAttributes<HTMLDivElement> {
  icon?: SubframeCore.IconName;
  label?: string;
  badges?: React.ReactNode;
  className?: string;
}

const DetailsListBadgeRow = React.forwardRef<
  HTMLElement,
  DetailsListBadgeRowProps
>(function DetailsListBadgeRow(
  {
    icon = 'FeatherStar',
    label,
    badges,
    className,
    ...otherProps
  }: DetailsListBadgeRowProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'flex w-full items-start justify-between pt-2 pb-2',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex items-center gap-1.5">
        <SubframeCore.Icon
          className="text-body font-body text-neutral-600"
          name={icon}
        />
        {label ? (
          <span className="text-body font-body text-neutral-600">{label}</span>
        ) : null}
      </div>
      {badges ? (
        <div className="flex items-start justify-between">{badges}</div>
      ) : null}
    </div>
  );
});

interface DetailsListRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const DetailsListRoot = React.forwardRef<HTMLElement, DetailsListRootProps>(
  function DetailsListRoot(
    { children, className, ...otherProps }: DetailsListRootProps,
    ref,
  ) {
    return children ? (
      <div
        className={SubframeCore.twClassNames(
          'flex w-full flex-col items-start',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children}
      </div>
    ) : null;
  },
);

export const DetailsList = Object.assign(DetailsListRoot, {
  DetailsListRow,
  DetailsListBadgeRow,
});
