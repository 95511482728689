/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * The type of support level
 */
export type ClusterSupportLevelType =
  (typeof ClusterSupportLevelType)[keyof typeof ClusterSupportLevelType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClusterSupportLevelType = {
  GeneralSupport: 'GeneralSupport',
  LongTermSupport: 'LongTermSupport',
} as const;
