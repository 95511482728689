// @subframe/sync-disable
// custom props/logic added with "// custom code" comments
'use client';
/*
 * Documentation:
 * Step - Base — https://app.subframe.com/cd4f5307efe3/library?component=Step+-+Base_2ee2e687-5d05-4b53-a0e7-dfbd539ae5c0
 * Avatar — https://app.subframe.com/cd4f5307efe3/library?component=Avatar_bec25ae6-5010-4485-b46b-cf79e3943ab2
 * Icon with background — https://app.subframe.com/cd4f5307efe3/library?component=Icon+with+background_c5d68c0e-4c0c-4cff-8d8c-6ff334859b3a
 * Tooltip — https://app.subframe.com/cd4f5307efe3/library?component=Tooltip_ccebd1e9-f6ac-4737-8376-0dfacd90c9f3
 * Badge — https://app.subframe.com/cd4f5307efe3/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 * Button — https://app.subframe.com/cd4f5307efe3/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 * Dropdown Menu — https://app.subframe.com/cd4f5307efe3/library?component=Dropdown+Menu_99951515-459b-4286-919e-a89e7549b43b
 * Icon Button — https://app.subframe.com/cd4f5307efe3/library?component=Icon+Button_af9405b1-8c54-4e01-9786-5aad308224f6
 * Accordion — https://app.subframe.com/cd4f5307efe3/library?component=Accordion_d2e81e20-863a-4027-826a-991d8910efd9
 * [Design system] Copy to clipboard — https://app.subframe.com/cd4f5307efe3/library?component=%5BDesign+system%5D+Copy+to+clipboard_c58ef7de-f105-487a-971c-99701c362001
 * Check - Base — https://app.subframe.com/cd4f5307efe3/library?component=Check+-+Base_2230b05d-3651-4c3f-a1b8-a0c8457f1c87
 * [Deprecated]-Table Header - 4 columns — https://app.subframe.com/cd4f5307efe3/library?component=%5BDeprecated%5D-Table+Header+-+4+columns_abd75603-31e6-4c80-adda-f45c334f2191
 * [Deprecated]-Table Row - 4 columns — https://app.subframe.com/cd4f5307efe3/library?component=%5BDeprecated%5D-Table+Row+-+4+columns_b328f442-5ef8-46bb-bf8e-315355d93245
 * Tabs — https://app.subframe.com/cd4f5307efe3/library?component=Tabs_e1ad5091-8ad8-4319-b1f7-3e47f0256c20
 * Text Area — https://app.subframe.com/cd4f5307efe3/library?component=Text+Area_4ec05ee8-8f1c-46b2-b863-5419aa7f5cce
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { Avatar } from './Avatar';
import { IconWithBackground } from './IconWithBackground';
import { Tooltip } from './Tooltip';
import { DropdownMenu } from './DropdownMenu';
import { Accordion } from './Accordion';
import { IconButton } from './IconButton';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const Container = React.forwardRef<HTMLElement, ContainerProps>(
  function Container(
    { children, className, ...otherProps }: ContainerProps,
    ref,
  ) {
    return children ? (
      <div
        className={SubframeCore.twClassNames(
          'flex flex-col items-start gap-1',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children}
      </div>
    ) : null;
  },
);

interface BulletedLineProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string;
  className?: string;
}

const BulletedLine = React.forwardRef<HTMLElement, BulletedLineProps>(
  function BulletedLine(
    { children, className, ...otherProps }: BulletedLineProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex items-start gap-2 pl-1',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <span className="text-body font-body text-default-font">–</span>
        {children ? (
          <span className="whitespace-pre-wrap text-body font-body text-default-font">
            {children}
          </span>
        ) : null}
      </div>
    );
  },
);

interface TextWithLinkProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  url?: string;
  urlTitle?: string;
  className?: string;
}

const TextWithLink = React.forwardRef<HTMLElement, TextWithLinkProps>(
  function TextWithLink(
    { label, url, urlTitle, className, ...otherProps }: TextWithLinkProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex flex-col items-start gap-1',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {label ? (
          <span className="text-body font-body text-default-font">{label}</span>
        ) : null}
        <div className="flex items-center gap-1">
          <SubframeCore.Icon
            className="text-body font-body text-brand-600"
            name="FeatherExternalLink"
          />
          {url ? (
            // custom code : start
            <a
              href={url}
              className="hoverable-link text-body-bold font-body-bold text-brand-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              {urlTitle || url}
            </a> // custom code : end
          ) : null}
        </div>
      </div>
    );
  },
);

interface EditClusterNameButtonProps
  extends SubframeCore.TypescriptHelpers.Optional<
    React.ComponentProps<typeof SubframeCore.Icon>,
    'name'
  > {
  popoverContents?: React.ReactNode;
  className?: string;
}

const EditClusterNameButton = React.forwardRef<
  HTMLElement,
  EditClusterNameButtonProps
>(function EditClusterNameButton(
  { popoverContents, className, ...otherProps }: EditClusterNameButtonProps,
  ref,
) {
  return (
    <SubframeCore.Popover.Root>
      <SubframeCore.Popover.Trigger asChild={true}>
        <SubframeCore.Icon
          className={SubframeCore.twClassNames(
            'group/e53671dc cursor-pointer text-body font-body text-subtext-color hover:text-default-font',
            className,
          )}
          name="FeatherEdit2"
          ref={ref as any}
          {...otherProps}
        />
      </SubframeCore.Popover.Trigger>
      <SubframeCore.Popover.Portal>
        <SubframeCore.Popover.Content
          side="bottom"
          align="center"
          sideOffset={8}
          asChild={true}
        >
          {popoverContents ? (
            <div className="flex flex-col items-start gap-3 bg-neutral-100 pt-3 pr-3 pb-3 pl-3 shadow-large">
              {popoverContents}
            </div>
          ) : null}
        </SubframeCore.Popover.Content>
      </SubframeCore.Popover.Portal>
    </SubframeCore.Popover.Root>
  );
});

interface CommentProps extends React.HTMLAttributes<HTMLDivElement> {
  avatar?: React.ReactNode;
  comment?: string;
  className?: string;
}

const Comment = React.forwardRef<HTMLElement, CommentProps>(function Comment(
  { avatar, comment, className, ...otherProps }: CommentProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames('flex items-start gap-2', className)}
      ref={ref as any}
      {...otherProps}
    >
      {avatar ? <div className="flex items-start gap-2">{avatar}</div> : null}
      <div className="flex items-start gap-2 pt-0.5">
        {comment ? (
          <span className="whitespace-pre-wrap text-label font-label text-default-font">
            {comment}
          </span>
        ) : null}
      </div>
    </div>
  );
});

interface AddCommentButtonProps
  extends React.ComponentProps<typeof IconButton> {
  popoverContents?: React.ReactNode;
  className?: string;
}

const AddCommentButton = React.forwardRef<HTMLElement, AddCommentButtonProps>(
  function AddCommentButton(
    { popoverContents, className, ...otherProps }: AddCommentButtonProps,
    ref,
  ) {
    return (
      <SubframeCore.Popover.Root>
        <SubframeCore.Popover.Trigger asChild={true}>
          <IconButton
            className={className}
            size="small"
            icon="FeatherMessageSquare"
            ref={ref as any}
            {...otherProps}
          />
        </SubframeCore.Popover.Trigger>
        <SubframeCore.Popover.Portal>
          <SubframeCore.Popover.Content
            side="bottom"
            align="center"
            sideOffset={8}
            asChild={true}
          >
            {popoverContents ? (
              <div className="flex flex-col items-start gap-3 bg-neutral-100 pt-3 pr-3 pb-3 pl-3 shadow-large">
                {popoverContents}
              </div>
            ) : null}
          </SubframeCore.Popover.Content>
        </SubframeCore.Popover.Portal>
      </SubframeCore.Popover.Root>
    );
  },
);

interface StepBaseRootProps extends React.HTMLAttributes<HTMLDivElement> {
  stepTitle?: string;
  stepBody?: string;
  lastStep?: boolean;
  variant?: 'default' | 'error' | 'success' | 'loading' | 'waiting' | 'skipped';
  stepNumber?: string;
  children?: React.ReactNode;
  actionButtons?: React.ReactNode;
  stepTitleTooltip?: React.ReactNode;
  badges?: React.ReactNode;
  className?: string;
  defaultOpen?: boolean; // custom code : added for more fine-grained control over trigger conditions
  open?: boolean; // custom code : added for more fine-grained control over trigger conditions
  onOpenChange?: (open: boolean) => void; // custom code : added for more fine-grained control over trigger conditions
}

const StepBaseRoot = React.forwardRef<HTMLElement, StepBaseRootProps>(
  function StepBaseRoot(
    {
      stepTitle,
      stepBody,
      lastStep = false,
      variant = 'default',
      stepNumber,
      children,
      actionButtons,
      stepTitleTooltip,
      badges,
      className,
      defaultOpen, // custom code : added for more fine-grained control over trigger conditions
      open, // custom code : added for more fine-grained control over trigger conditions
      onOpenChange, // custom code : added for more fine-grained control over trigger conditions
      ...otherProps
    }: StepBaseRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/2ee2e687 flex w-full cursor-pointer flex-col items-start gap-2',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div
          className={SubframeCore.twClassNames(
            'flex w-full items-start gap-2 bg-default-background pt-2 pr-2 pb-2 pl-2',
            { 'bg-neutral-200': variant === 'waiting' },
          )}
        >
          <div className="flex flex-col items-center gap-2 self-stretch pt-1">
            <Avatar variant="neutral">{stepNumber}</Avatar>
            <div
              className={SubframeCore.twClassNames(
                'flex w-px grow shrink-0 basis-0 flex-col items-start gap-2 bg-neutral-border',
                { hidden: lastStep },
              )}
            />
          </div>
          <Accordion
            // custom code : start
            defaultOpen={defaultOpen}
            open={open}
            onOpenChange={onOpenChange}
            // custom code : end
            className="h-auto grow shrink-0 basis-0"
            trigger={
              <div className="flex w-full items-center gap-2 pt-2 pr-3 pb-2 pl-3">
                <SubframeCore.Tooltip.Provider>
                  <SubframeCore.Tooltip.Root>
                    <SubframeCore.Tooltip.Trigger asChild={true}>
                      <div className="flex grow shrink-0 basis-0 items-center gap-4">
                        <div className="flex items-center gap-2">
                          {stepTitle ? (
                            <span
                              className={SubframeCore.twClassNames(
                                'text-subheader font-subheader text-default-font',
                                { 'text-neutral-50': variant === 'waiting' },
                              )}
                            >
                              {stepTitle}
                            </span>
                          ) : null}
                          <IconWithBackground
                            className={SubframeCore.twClassNames('hidden', {
                              flex: variant === 'success',
                            })}
                            variant="success"
                          />
                          <IconWithBackground
                            className={SubframeCore.twClassNames('hidden', {
                              flex: variant === 'error',
                            })}
                            variant="error"
                            size="x-small"
                            icon="FeatherAlertTriangle"
                          />
                          <SubframeCore.Tooltip.Provider>
                            <SubframeCore.Tooltip.Root>
                              <SubframeCore.Tooltip.Trigger asChild={true}>
                                <IconWithBackground
                                  className="hidden"
                                  variant="neutral"
                                  icon="FeatherMinus"
                                />
                              </SubframeCore.Tooltip.Trigger>
                              <SubframeCore.Tooltip.Portal>
                                <SubframeCore.Tooltip.Content
                                  side="bottom"
                                  align="center"
                                  sideOffset={8}
                                  asChild={true}
                                >
                                  <Tooltip>
                                    This step is waiting on completion of a
                                    previous step.
                                  </Tooltip>
                                </SubframeCore.Tooltip.Content>
                              </SubframeCore.Tooltip.Portal>
                            </SubframeCore.Tooltip.Root>
                          </SubframeCore.Tooltip.Provider>
                          <SubframeCore.Tooltip.Provider>
                            <SubframeCore.Tooltip.Root>
                              <SubframeCore.Tooltip.Trigger asChild={true}>
                                <IconWithBackground
                                  className={SubframeCore.twClassNames(
                                    'hidden',
                                    {
                                      flex:
                                        variant === 'skipped' ||
                                        variant === 'waiting',
                                      hidden: variant === 'loading',
                                    },
                                  )}
                                  variant="neutral"
                                  icon="FeatherMinus"
                                />
                              </SubframeCore.Tooltip.Trigger>
                              <SubframeCore.Tooltip.Portal>
                                <SubframeCore.Tooltip.Content
                                  side="bottom"
                                  align="center"
                                  sideOffset={8}
                                  asChild={true}
                                >
                                  <Tooltip>This step has been skipped</Tooltip>
                                </SubframeCore.Tooltip.Content>
                              </SubframeCore.Tooltip.Portal>
                            </SubframeCore.Tooltip.Root>
                          </SubframeCore.Tooltip.Provider>
                          <SubframeCore.Tooltip.Provider>
                            <SubframeCore.Tooltip.Root>
                              <SubframeCore.Tooltip.Trigger asChild={true}>
                                <Avatar
                                  className="hidden"
                                  size="x-small"
                                  image="https://res.cloudinary.com/subframe/image/upload/v1711417507/shared/fychrij7dzl8wgq2zjq9.avif"
                                >
                                  AB
                                </Avatar>
                              </SubframeCore.Tooltip.Trigger>
                              <SubframeCore.Tooltip.Portal>
                                <SubframeCore.Tooltip.Content
                                  side="bottom"
                                  align="center"
                                  sideOffset={8}
                                  asChild={true}
                                >
                                  <Tooltip>
                                    Jane Doe marked this Step as completed TK
                                    d/mo/yr ago
                                  </Tooltip>
                                </SubframeCore.Tooltip.Content>
                              </SubframeCore.Tooltip.Portal>
                            </SubframeCore.Tooltip.Root>
                          </SubframeCore.Tooltip.Provider>
                          <SubframeCore.Loader
                            className={SubframeCore.twClassNames(
                              'hidden text-body font-body text-brand-600',
                              { 'inline-block': variant === 'loading' },
                            )}
                          />
                        </div>
                        {badges ? (
                          <div className="flex items-center gap-2 self-stretch">
                            {badges}
                          </div>
                        ) : null}
                      </div>
                    </SubframeCore.Tooltip.Trigger>
                    <SubframeCore.Tooltip.Portal>
                      <SubframeCore.Tooltip.Content
                        side="bottom"
                        align="start"
                        sideOffset={4}
                        asChild={true}
                      >
                        {stepTitleTooltip ? (
                          <div className="flex items-start justify-between">
                            {stepTitleTooltip}
                          </div>
                        ) : null}
                      </SubframeCore.Tooltip.Content>
                    </SubframeCore.Tooltip.Portal>
                  </SubframeCore.Tooltip.Root>
                </SubframeCore.Tooltip.Provider>
                {actionButtons ? (
                  <div className="flex items-center justify-end gap-2 self-stretch">
                    {actionButtons}
                  </div>
                ) : null}
                <Accordion.Chevron />
              </div>
            }
          >
            {children ? (
              <div className="flex w-full flex-col items-start gap-3 border border-solid border-neutral-border pt-3 pr-3 pb-3 pl-3">
                {children}
              </div>
            ) : null}
          </Accordion>
        </div>
      </div>
    );
  },
);

export const StepBase = Object.assign(StepBaseRoot, {
  Container,
  BulletedLine,
  TextWithLink,
  EditClusterNameButton,
  Comment,
  AddCommentButton,
});
