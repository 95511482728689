// @mui
import { useTheme } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

// components
import Iconify from 'components/Iconify';
import { ARViews, ClustersViewLayout } from 'models/general';
import { AtomicTooltip } from 'components/design-system';
import { Switch } from 'subframe/components/Switch';

type ToggleFunction = (newAlignment: ClustersViewLayout) => void;
type FlippedToggleFunction = (newView: ARViews) => void;

const ToggleButtonStyle = ({
  value,
  icon,
  isSelected,
  onClick,
}: {
  value: string;
  icon: string;
  isSelected?: boolean;
  onClick: VoidFunction;
}) => {
  const theme = useTheme();

  const selectedColor = {
    backgroundColor: isSelected ? theme.palette.grey[700] : '',
    padding: 1,
    '&:hover': {
      backgroundColor: isSelected ? theme.palette.grey[800] : '',
    },
  };

  return (
    <ToggleButton
      onClick={onClick}
      sx={{ borderRadius: '50%', ...selectedColor }}
      value={value}
    >
      <Iconify
        icon={icon}
        sx={{
          color: theme.palette.common.white,
        }}
      />
    </ToggleButton>
  );
};

export const AlignmentToggleButton = ({
  alignment,
  handleAlignment,
}: {
  handleAlignment: ToggleFunction;
  alignment?: ClustersViewLayout;
}) => {
  const theme = useTheme();

  return (
    <ToggleButtonGroup
      aria-label="cluster alignment"
      sx={{
        backgroundColor: theme.palette.grey[900],
        border: `1px solid ${theme.palette.grey[800]}`,
        borderRadius: '24px',
        height: 32,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ToggleButtonStyle
        onClick={() => handleAlignment(ClustersViewLayout.GRID)}
        isSelected={alignment === ClustersViewLayout.GRID}
        value={ClustersViewLayout.GRID}
        icon="lucide:layout-grid"
      />
      <ToggleButtonStyle
        onClick={() => handleAlignment(ClustersViewLayout.LIST)}
        isSelected={alignment === ClustersViewLayout.LIST}
        value={ClustersViewLayout.LIST}
        icon="teenyicons:list-layout-outline"
      />
    </ToggleButtonGroup>
  );
};

export const FAVToggleButton = ({
  view,
  handleView,
  disableToggle,
}: {
  handleView: FlippedToggleFunction;
  view: ARViews;
  disableToggle: boolean;
}) => {
  let tooltipText = '';
  if (disableToggle) {
    tooltipText =
      "You haven't onboarded any clusters. Add Cluster to see data from your environment.";
  } else {
    tooltipText =
      view === 'real'
        ? 'Show example data'
        : 'Show results from your onboarded clusters';
  }

  return (
    <AtomicTooltip tooltipContent={tooltipText}>
      <Switch
        checked={view === 'example'}
        onCheckedChange={(checked) => {
          if (!disableToggle) handleView(checked ? 'example' : 'real');
        }}
        id="example-cluster-toggle-button"
        disabled={disableToggle}
      />
    </AtomicTooltip>
  );
};
