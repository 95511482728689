import { Icon, Text } from '@subframe/core';
import { Stack } from './utils/Stack';

type Mode = 'error' | 'warning' | 'info' | 'success';

interface MessageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  mode: Mode;
  message: string;
}

export default function MessageBox({
  mode,
  message,
  ...rest
}: MessageBoxProps) {
  const modeColorMap: Record<Mode, string> = {
    error: 'var(--error-300)',
    warning: 'var(--warning-300)',
    info: 'var(--neutral-300)',
    success: 'var(--success-300)',
  };

  const modeIconMap: Record<Mode, String> = {
    error: 'FeatherXCircle',
    warning: 'FeatherAlertCircle',
    info: 'FeatherInfo',
    success: 'FeatherCheck',
  };

  return (
    <Stack spacing={1} alignItems={'center'} className="pt-[25%]">
      <Text
        variant={'section-header'}
        color={'subtext'}
        style={{
          color: `${modeColorMap[mode]} !important`,
        }}
      >
        <Icon
          name={modeIconMap[mode]}
          style={{ color: `${modeColorMap[mode]} !important` }}
          color={'subtext'}
        />
      </Text>

      <Text variant={'subheader'} color={'subtext'}>
        {message}
      </Text>
    </Stack>
  );
}
