/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * The kind of plan a subscription follows
 */
export type SubscriptionPlan =
  (typeof SubscriptionPlan)[keyof typeof SubscriptionPlan];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionPlan = {
  FreeTrial: 'FreeTrial',
  GuidedPOC: 'GuidedPOC',
  Demo: 'Demo',
  Community: 'Community',
  Starter: 'Starter',
  Business: 'Business',
  Enterprise: 'Enterprise',
} as const;
