import classNames from 'classnames';
import React from 'react';
import styles from './VisuallyHidden.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const VisuallyHidden = React.forwardRef<HTMLDivElement, Props>(
  function VisuallyHidden(props, ref) {
    const { className, ...otherProps } = props;
    return (
      <div
        ref={ref}
        className={classNames(className, styles.root)}
        {...otherProps}
      />
    );
  },
);
