/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

export type UpgradeStageStepStatusDetailsReason =
  (typeof UpgradeStageStepStatusDetailsReason)[keyof typeof UpgradeStageStepStatusDetailsReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpgradeStageStepStatusDetailsReason = {
  WillUpgradeLater: 'WillUpgradeLater',
  IncorrectRecommendation: 'IncorrectRecommendation',
  OwnedByAnotherTeam: 'OwnedByAnotherTeam',
  RequireAdditionalInfo: 'RequireAdditionalInfo',
  UpgradeToADifferentVersion: 'UpgradeToADifferentVersion',
  Other: 'Other',
} as const;
