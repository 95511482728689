import { Stack, Typography, styled } from '@mui/material';

export const FieldStack = styled(Stack)(() => ({
  width: '100%',
}));

export const FieldLabel = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '6px',
}));
