import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Badge,
  TablePagination,
  Stack,
  Skeleton,
  TableSortLabel,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import UseLARDetailsTableSort from 'hooks/useLARDetailsTableSort';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { VisuallyHidden } from 'components/utils/VisuallyHidden';

import Scrollbar from './Scrollbar';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

const LARDeepDiveViewTableRow = ({ cluster }: { cluster: any }) => {
  const theme = useTheme();
  let { larId } = useParams<{ larId: string }>();
  const { logEvent } = AnalyticsEventLogger();
  const rowLink = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/availability-risks/${
      larId?.toLowerCase() || ''
    }/cluster/${cluster?.id}`,
  );

  const detailsText = `${cluster.metadata.cloud_provider} | ${
    cluster.metadata?.k8s_version || ''
  } `;

  return (
    <TableRow
      id="lar-details-table-row"
      sx={{
        textDecoration: 'none',
        boxShadow: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24)',
        height: '1rem',
        borderBottom: 'none',
        cursor: 'pointer',
        '&:hover': {
          '& .hover-change': {
            color: theme.palette.primary.main,
          },
          backgroundColor: theme.palette.grey[800],
        },
      }}
      onClick={() => {
        logEvent('lar-view-table-row-click', {
          larId: larId,
          clusterId: cluster?.id,
        });
      }}
      component={RouterLink}
      to={rowLink}
    >
      <TableCell className="hover-change">
        <Typography id="lar-details-row-cluster-name">
          {cluster.name}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.grey[500],
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          variant="subtitle2"
        >
          {detailsText}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
export default function LARDeepDiveViewTable({
  clusters,
  loading,
}: {
  clusters: any[];
  loading: boolean;
}) {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [displayData, setDisplayData] = useState(clusters);
  const { sortOrder, orderBy, handleRequestSort, sortedData, setSortOrder } =
    UseLARDetailsTableSort(clusters, 'asc', 'name');

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setDisplayData(sortedData);
  }, [clusters, sortedData]);
  return (
    <Scrollbar sx={{ width: 'inherit' }}>
      <Stack alignItems={'center'}>
        <TableContainer
          sx={{
            minWidth: 720,
            minHeight: '27rem',
            backgroundColor: theme.palette.grey[900],
            boxShadow: '0px 12px 24px -4px rgba(0, 0, 0, 0.12)',
            borderRadius: 'var(--rounded)',
          }}
        >
          {loading || displayData?.length > 0 ? (
            <Table sx={{ borderRadius: '16px 0px 0px 0px' }}>
              <TableHead
                sx={{
                  backgroundColor: theme.palette.grey[800],
                  minHeight: '56px',
                  boxShadow: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24)',
                }}
              >
                <TableCell sx={{ width: '55%' }}>
                  {' '}
                  <TableSortLabel
                    direction={
                      orderBy === 'name' ? (sortOrder as 'asc' | 'desc') : 'asc'
                    }
                    onClick={() => handleRequestSort('name')}
                    active={orderBy === 'name'}
                  >
                    {orderBy === 'name' && (
                      <VisuallyHidden>
                        {sortOrder === 'asc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </VisuallyHidden>
                    )}
                    Affected Clusters
                  </TableSortLabel>
                </TableCell>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow
                    sx={{
                      boxShadow: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24)',
                      borderBottom: 'none',
                      cursor: 'pointer',
                      height: '1rem',
                    }}
                  >
                    <TableCell>
                      <Skeleton height={20} width={'90%'} sx={{ m: 1 }} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {!loading &&
                      displayData?.length > 0 &&
                      displayData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((cluster) => (
                          <LARDeepDiveViewTableRow
                            key={cluster?.id}
                            cluster={cluster}
                          />
                        ))}
                  </>
                )}
              </TableBody>
            </Table>
          ) : (
            <>
              {!loading && clusters?.length < 1 && (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  marginTop={16}
                >
                  <Typography fontSize={24}> No matching results</Typography>
                </Stack>
              )}
            </>
          )}
        </TableContainer>
        {!(!loading && clusters?.length < 1) && (
          <TablePagination
            id="lar-details-table-pagination"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={clusters.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Stack>
    </Scrollbar>
  );
}
