import React from 'react';

export default function CircularProgressWithLabel({
  children,
  value,
  total,
  radius = 20,
}: {
  children: React.ReactNode;
  value: number;
  total: number;
  radius?: number;
}) {
  // if value is 0, then show at least 4% of the circle
  const progressPercent = Math.max(value / total, 0.04);
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - progressPercent * circumference;
  const strokeWidth = radius * 0.3; // Adjust the factor as needed for the thickness of the progress bar
  const diameter = 2 * (radius + strokeWidth); // Including stroke width

  return (
    <div
      className="relative"
      style={{ width: `${diameter}px`, height: `${diameter}px` }} // dynamic styles don't work with tailwindcss
    >
      <svg
        viewBox={`0 0 ${diameter} ${diameter}`}
        xmlns="http://www.w3.org/2000/svg"
        className="h-full"
      >
        <circle
          cx={diameter / 2}
          cy={diameter / 2}
          r={radius}
          fill="none"
          className="stroke-current text-neutral-200"
          strokeWidth={strokeWidth}
        />
        <circle
          cx={diameter / 2}
          cy={diameter / 2}
          r={radius}
          fill="none"
          className="stroke-current text-brand-600 transition-[stroke-dashoffset] duration-300 ease-in-out delay-[0ms]"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          transform={`rotate(-90 ${diameter / 2} ${diameter / 2})`}
        />
      </svg>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-white font-bold">
        {children}
      </div>
    </div>
  );
}
