import { Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from './Iconify';
import { Card } from 'components/utils/Card';
import { Stack } from 'components/utils/Stack';
import styles from './ARSigCategoryCard.module.scss';
import classNames from 'classnames';
import { HoverCard, Icon, Text } from '@subframe/core';
import React from 'react';
import * as SubframeCore from '@subframe/core';
import { convertToLowerCaseWithDashes } from 'utils/filters';

type ARSigCategoryCardProps = {
  title: string;
  icon: string;
  tooltip?: React.ReactNode /* TODO(izhan): Had to replace string tooltip with react node to handle custom tooltips. */;
  onClick?: () => void;
  isClicked?: boolean;
  loading?: boolean;
  NACounts?: number;
  subText?: string;
};

function ARSigCategoryCardRoot({
  title,
  icon,
  tooltip,
  onClick,
  isClicked,
  loading,
  NACounts,
  subText = 'Need attention',
}: ARSigCategoryCardProps) {
  const theme = useTheme();
  return (
    <Card
      id={`${convertToLowerCaseWithDashes(title)}-category-card`}
      className={classNames(styles.root, {
        [styles.selected]: isClicked,
      })}
      onClick={onClick}
      interactive
      spacing={4}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Iconify icon={icon} />
        <Text variant="subheader" ellipsis id="overview-header">
          {title}
        </Text>
      </Stack>
      <Stack>
        <Stack direction="row" alignItems="center" className="flex gap-2">
          <Text variant="body-bold" color="subtext">
            {subText}{' '}
          </Text>
          {/* TODO(izhan): Hovercard used here instead of tooltip to make it consistent with cluster cards on clusters page. */}
          {tooltip && (
            <HoverCard.Root openDelay={200}>
              <HoverCard.Trigger asChild={true}>
                <Icon
                  className={styles['bodyText']}
                  name="FeatherInfo"
                  data-cy={`${convertToLowerCaseWithDashes(
                    title,
                  )}-need-attention-icon`}
                />
              </HoverCard.Trigger>
              <HoverCard.Portal>
                <HoverCard.Content
                  side="bottom"
                  align="center"
                  sideOffset={8}
                  asChild={true}
                >
                  <div
                    className={styles['tooltip-stack']}
                    data-cy={`${convertToLowerCaseWithDashes(
                      title,
                    )}-need-attention-tooltip`}
                  >
                    {tooltip}
                  </div>
                </HoverCard.Content>
              </HoverCard.Portal>
            </HoverCard.Root>
          )}
        </Stack>

        {loading ? (
          <Skeleton width={30} height={38} />
        ) : (
          <Text
            variant="section-header"
            id={`${convertToLowerCaseWithDashes(title)}-category-card-count`}
          >
            {NACounts || 0}
          </Text>
        )}
      </Stack>
    </Card>
  );
}

interface RiskPopoverDataProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  critical?: string;
  high?: string;
  medium?: string;
  low?: string;
  ignored?: string;
  total?: string;
  className?: string;
  detailedCounts?: boolean;
}

{
  /* TODO(izhan): Refactored RiskPopoverData from cluster cards to display counts for cluster details. */
}
{
  /* TODO (laiba) : update with cluster card migration to tailwind*/
}
const RiskPopoverData = React.forwardRef<HTMLElement, RiskPopoverDataProps>(
  function RiskPopoverData(
    {
      title = 'Remediated',
      detailedCounts = false,
      critical = '1',
      high = '6',
      medium = '12',
      low = '4',
      ignored = '0',
      total = '23',
      className,
      ...otherProps
    }: RiskPopoverDataProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex w-52 flex-col items-start gap-2 bg-default-font',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <span className="text-label font-label text-black">{title}</span>
        {detailedCounts && (
          <>
            <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
            <div className="flex w-full flex-col items-start gap-1">
              <div className="flex w-full items-center gap-2">
                <span className="grow shrink-0 basis-0 text-label font-label text-black">
                  Critical
                </span>
                <span
                  className="text-label-bold font-label-bold text-black"
                  data-cy="critical-count"
                >
                  {critical}
                </span>
              </div>
              <div className="flex w-full items-center gap-2">
                <span className="grow shrink-0 basis-0 text-label font-label text-black">
                  High
                </span>
                <span
                  className="text-label-bold font-label-bold text-black"
                  data-cy="high-count"
                >
                  {high}
                </span>
              </div>
              <div className="flex w-full items-center gap-2">
                <span className="grow shrink-0 basis-0 text-label font-label text-black">
                  Medium
                </span>
                <span
                  className="text-label-bold font-label-bold text-black"
                  data-cy="medium-count"
                >
                  {medium}
                </span>
              </div>
              <div className="flex w-full items-center gap-2">
                <span className="grow shrink-0 basis-0 text-label font-label text-black">
                  Low
                </span>
                <span
                  className="text-label-bold font-label-bold text-black"
                  data-cy="low-count"
                >
                  {low}
                </span>
              </div>
              <div className="flex w-full items-center gap-2">
                <span className="grow shrink-0 basis-0 text-label font-label text-black">
                  Ignored
                </span>
                <span
                  className="text-label-bold font-label-bold text-black"
                  data-cy="ignored-count"
                >
                  {ignored}
                </span>
              </div>
            </div>
            <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
            <div className="flex w-full items-center gap-2">
              <span className="grow shrink-0 basis-0 text-label-bold font-label-bold text-black">
                Total
              </span>
              <span
                className="text-label-bold font-label-bold text-black"
                data-cy="total-count"
              >
                {' '}
                {total}
              </span>
            </div>
          </>
        )}
      </div>
    );
  },
);

export const ARSigCategoryCard = Object.assign(ARSigCategoryCardRoot, {
  RiskPopoverData,
});
