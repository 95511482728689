import Centered from 'components/Centered';
import { LoaderContainer } from 'components/Loader';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useLocation } from 'react-router';
import ClustersPageSkeleton from './ClustersPageSkeleton';

export default function RouteSkeletons({ pathname }: { pathname: string }) {
  const location = useLocation();
  const currentpath = location.pathname.toString();

  const clusterPath = currentpath?.split('clusters/')[1] || '';
  const clusterId = clusterPath.split('/')[0] || '';

  const clustersPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  const clusterIdPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters/${clusterId}',
  );

  switch (pathname) {
    case clustersPath:
      return <ClustersPageSkeleton showHeader={true} />;
    case clusterIdPath:
      return <></>;

    default:
      return (
        <Centered>
          <LoaderContainer />
        </Centered>
      );
  }
}
