import { useState, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Grid, Typography, styled } from '@mui/material';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel';
import { SPINNER } from '../config';

// ----------------------------------------------------------------------

type ButtonProps = {
  onClick: VoidFunction;
  showText: boolean;
  tasksLeft: number;
  totalTasksNumber: number;
  isCollapse?: boolean;
};

const ColoredContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  margin: theme.spacing(1, 2, 1, 2),
  width: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: '8px',
  display: 'flex',
  paddingLeft: 1,
  '&:hover': {
    backgroundColor: theme.palette.grey[800],
  },
}));

export default function QuickStartButton({
  onClick,
  showText,
  tasksLeft,
  totalTasksNumber,
  isCollapse,
  ...other
}: ButtonProps) {
  const theme = useTheme();
  const [showLabel, setShowLabel] = useState(isCollapse);

  useEffect(() => {
    setTimeout(() => {
      setShowLabel(isCollapse);
    }, 200);
  }, [isCollapse]);

  return (
    <ColoredContainer
      id="quick-start-button"
      onClick={onClick}
      maxWidth={'xl'}
      {...other}
    >
      <Grid container alignItems="center">
        <Grid
          justifyContent="center"
          alignItems="center"
          item
          container
          xs={isCollapse ? 12 : 4}
        >
          <CircularProgressWithLabel
            value={totalTasksNumber - tasksLeft}
            total={totalTasksNumber}
          >
            {totalTasksNumber - tasksLeft}
          </CircularProgressWithLabel>
        </Grid>
        {showLabel ? null : (
          <Grid
            sx={{ display: showText ? 'flex' : 'none' }}
            alignItems="center"
            container
            item
            xs={8}
          >
            <Typography sx={{ color: theme.palette.common.white }} variant="h4">
              Quick Start
            </Typography>
            <Typography
              sx={{ color: theme.palette.grey[200] }}
              variant="subtitle1"
            >
              {`${tasksLeft} Remaining Task${tasksLeft > 1 ? 's' : ''}`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </ColoredContainer>
  );
}
