'use client';
/*
 * Documentation:
 * Informational Header — https://app.subframe.com/library?component=Informational+Header_4cf9a88e-9d62-46e2-bdb7-c24b54eff2d7
 * Info tooltip — https://app.subframe.com/library?component=Info+tooltip_58466bc5-c62e-47fb-9394-3b607f233dfa
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { Tooltip } from './Tooltip';
import { InfoTooltip } from './InfoTooltip';

interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  iconName?: SubframeCore.IconName;
  children?: string;
  headingSlot?: React.ReactNode;
  bodySlot?: React.ReactNode;
  icon?: React.ReactNode;
  variant?: 'default' | 'clickable';
  moreElements?: React.ReactNode;
  className?: string;
}

const Item = React.forwardRef<HTMLElement, ItemProps>(function Item(
  {
    title,
    iconName = null,
    children,
    headingSlot,
    bodySlot,
    icon,
    variant = 'default',
    moreElements,
    className,
    ...otherProps
  }: ItemProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/fa8752eb flex items-start gap-2',
        {
          'border border-solid border-neutral-border bg-neutral-50 pt-2 pr-2 pb-2 pl-2 hover:bg-neutral-100 active:bg-neutral-100':
            variant === 'clickable',
        },
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex flex-col items-start gap-1">
        <div className="flex items-center gap-1">
          {icon ? <div className="flex items-center gap-1">{icon}</div> : null}
          <SubframeCore.Icon
            className="text-body font-body text-subtext-color"
            name={iconName}
          />
          {title ? (
            <span className="text-body font-body text-subtext-color">
              {title}
            </span>
          ) : null}
          {headingSlot ? (
            <div className="flex items-center gap-1">{headingSlot}</div>
          ) : null}
        </div>
        <div className="flex items-start gap-2">
          {bodySlot ? (
            <div className="flex items-start gap-2">{bodySlot}</div>
          ) : null}
          {moreElements ? (
            <div className="flex items-start gap-2">{moreElements}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
});

interface InformationalHeaderRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const InformationalHeaderRoot = React.forwardRef<
  HTMLElement,
  InformationalHeaderRootProps
>(function InformationalHeaderRoot(
  { children, className, ...otherProps }: InformationalHeaderRootProps,
  ref,
) {
  return children ? (
    <div
      className={SubframeCore.twClassNames(
        'flex w-full items-start gap-8 pb-2 pl-2',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      {children}
    </div>
  ) : null;
});

export const InformationalHeader = Object.assign(InformationalHeaderRoot, {
  Item,
});
