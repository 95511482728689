import { useEffect } from 'react';
import { m, useAnimation } from 'framer-motion';

import { Box } from '@mui/material';
import Iconify from 'components/Iconify';

const LoadingIcon = ({ animating }: { animating: boolean }) => {
  const controller = useAnimation();

  useEffect(() => {
    if (animating) {
      controller.start({
        rotateZ: 360,
        transition: {
          repeat: Infinity,
          duration: 1,
        },
      });
    } else {
      controller.start({
        rotateZ: (0 + 180) % 360,
        transition: {
          duration: 1,
        },
      });
    }
  }, [animating, controller]);

  return (
    <Box
      component={m.div}
      sx={{ display: 'flex' }}
      animate={controller}
      initial={{ rotateZ: 0 }}
    >
      <Iconify width={16} height={16} icon="akar-icons:arrow-cycle" />
    </Box>
  );
};

export default LoadingIcon;
