/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * ???
 */
export type IntegrationType =
  (typeof IntegrationType)[keyof typeof IntegrationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IntegrationType = {
  slack_app: 'slack_app',
  github_app: 'github_app',
  jira: 'jira',
  'alert-manager': 'alert-manager',
  jira_app: 'jira_app',
} as const;
