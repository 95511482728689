import React from 'react';
import { useNavigate } from 'react-router';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useUserAccountState from 'hooks/useUserAccountState';

import { IngestionToken } from 'api/models';

import { Select, Button } from 'subframe/index';
import { RouterLink } from './RouterLink';

interface TokenSelectorProps extends React.ComponentProps<typeof Select> {
  tokens: IngestionToken[];
  helpText?: string;
}

export default function TokenSelector({
  tokens,
  helpText,
  ...props
}: TokenSelectorProps) {
  const { currentOrganization } = useUserAccountState();
  const navigate = useNavigate();
  const basePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/settings',
  );
  return (
    <Select
      helpText={helpText}
      placeholder="Select Token"
      {...props}
      data-cy="token-selector"
    >
      <RouterLink to={`${basePath}/tokens/new`} className="w-full">
        <Button
          className="h-8 w-full flex-none justify-start"
          variant="neutral-tertiary"
          icon="FeatherPlus"
        >
          Add Token
        </Button>
      </RouterLink>
      {tokens?.map((token, index) => (
        <Select.Item
          key={token.token_id}
          value={token.token}
          data-cy={`list-tokens-${index + 1}`}
        >
          {token.token_name ||
            `token-${currentOrganization.name.toLowerCase()}`}
        </Select.Item>
      ))}
    </Select>
  );
}
