'use client';

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { Accordion } from './Accordion';

interface AccordionWithUnderlineRootProps
  extends React.ComponentProps<typeof Accordion> {
  headerText?: string;
  children?: React.ReactNode;
  className?: string;
}

const AccordionWithUnderlineRoot = React.forwardRef<
  HTMLElement,
  AccordionWithUnderlineRootProps
>(function AccordionWithUnderlineRoot(
  {
    headerText,
    children,
    className,
    ...otherProps
  }: AccordionWithUnderlineRootProps,
  ref,
) {
  return (
    <Accordion
      className={SubframeCore.twClassNames('h-auto w-full', className)}
      trigger={
        <div className="flex w-full items-center gap-2 border-b border-solid border-neutral-border bg-neutral-50 pt-2 pr-3 pb-2 pl-3">
          {headerText ? (
            <span className="w-full grow shrink-0 basis-0 text-body font-body text-default-font">
              {headerText}
            </span>
          ) : null}
          <Accordion.Chevron />
        </div>
      }
      defaultOpen={false}
      ref={ref as any}
      {...otherProps}
    >
      {children ? (
        <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-start gap-2 pt-2 pr-3 pb-2 pl-3">
          {children}
        </div>
      ) : null}
    </Accordion>
  );
});

export const AccordionWithUnderline = AccordionWithUnderlineRoot;
