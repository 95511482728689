import React from 'react';
import { Badge } from 'subframe/index';
import { AtomicTooltip } from './AtomicTooltip';
import { ResolvedEntitlement } from 'api/models';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { RouterLink } from 'components/RouterLink';

interface EntitlementBadgeRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  entitlementType: 'upgrade_template' | 'node' | 'upgrade_plan' | 'cluster';
  children?: React.ReactNode;
  entitlement: ResolvedEntitlement | undefined;
  consumptionAmount: number;
  showSubscriptionLink?: boolean;
}

enum EntitlementBadgeLimitTypes {
  LIMIT_APPROACHING = 'Approaching Limit',
  LIMIT_REACHED = 'Reached Limit',
  LIMIT_EXCEEDED = 'Exceeded Limit',
}

enum ClusterEntitlementTooltip {
  LIMIT_APPROACHING = 'Cluster count is approaching the <entitlement_limit> clusters included with your subscription.',
  LIMIT_REACHED = 'Cluster count has reached the <entitlement_limit> clusters included with your subscription.',
  LIMIT_EXCEEDED = 'Cluster count has exceeded the <entitlement_limit> clusters included with your subscription.',
}

enum NodeEntitlementTooltip {
  LIMIT_APPROACHING = 'Node count is approaching the <entitlement_limit> nodes included with your subscription.',
  LIMIT_REACHED = 'Node count has reached the <entitlement_limit> nodes included with your subscription.',
  LIMIT_EXCEEDED = 'Node count has exceeded the <entitlement_limit> nodes included with your subscription.',
}

enum UpgradePlanEntitlementTooltip {
  LIMIT_APPROACHING = 'You have consumed <consumption_amount> out of the <entitlement_limit> Upgrade Plans included with your subscription.',
  LIMIT_REACHED = 'You have consumed all <entitlement_limit> Upgrade Plans included with your subscription.',
  LIMIT_EXCEEDED = 'You have exceeded the <entitlement_limit> Upgrade Plans included with your subscription.',
}

enum UpgradeTemplateEntitlementTooltip {
  LIMIT_APPROACHING = 'You have consumed <consumption_amount> out of the <entitlement_limit> Upgrade Templates included with your subscription.',
  LIMIT_REACHED = 'You have consumed all <entitlement_limit> Upgrade Templates included with your subscription.',
  LIMIT_EXCEEDED = 'You have exceeded the <entitlement_limit> Upgrade Templates included with your subscription.',
}

enum tooltipFormatTypes {
  ENTITLEMENT_LIMIT = '<entitlement_limit>',
  CONSUMPTION_AMOUNT = '<consumption_amount>',
}

enum EntitlementLimitTypeColorVariant {
  LIMIT_APPROACHING = 'warning',
  LIMIT_REACHED = 'warning',
  LIMIT_EXCEEDED = 'error',
}

interface Entitlement {
  limitType: EntitlementBadgeLimitTypes;
  tooltipText: string | undefined;
  colorVariant: EntitlementLimitTypeColorVariant;
  entitlementLimit?: number;
}

const EntitlementBadgeRoot = ({
  entitlementType,
  entitlement,
  consumptionAmount,
  showSubscriptionLink = true,
}: EntitlementBadgeRootProps) => {
  if (
    entitlement === undefined || //entitlement is not available
    (!entitlement.features?.includes('request_upgrade_plan') &&
      entitlementType === 'upgrade_plan') //user does not have upgrade plan entitlement
  ) {
    return <></>;
  }

  const getFormattedTooltip = (
    tooltipText:
      | NodeEntitlementTooltip
      | UpgradePlanEntitlementTooltip
      | ClusterEntitlementTooltip
      | UpgradeTemplateEntitlementTooltip,
    entitlementAmount: number,
  ) => {
    return tooltipText
      .replace(
        tooltipFormatTypes.ENTITLEMENT_LIMIT,
        entitlementAmount.toString(),
      )
      .replace(
        tooltipFormatTypes.CONSUMPTION_AMOUNT,
        consumptionAmount.toString(),
      );
  };

  const getEntitlement = (): Entitlement | undefined => {
    switch (entitlementType) {
      case 'node':
        if (entitlement.node_count?.limit) {
          if (consumptionAmount > entitlement.node_count.limit) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_EXCEEDED,
              tooltipText: getFormattedTooltip(
                NodeEntitlementTooltip.LIMIT_EXCEEDED,
                entitlement.node_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_EXCEEDED,
              entitlementLimit: entitlement.node_count.limit,
            };
          }
          if (consumptionAmount === entitlement.node_count.limit) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_REACHED,
              tooltipText: getFormattedTooltip(
                NodeEntitlementTooltip.LIMIT_REACHED,
                entitlement.node_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_REACHED,
              entitlementLimit: entitlement.node_count.limit,
            };
          }
          if (
            entitlement.node_count.limit - consumptionAmount <= 10 &&
            consumptionAmount > 0
          ) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_APPROACHING,
              tooltipText: getFormattedTooltip(
                NodeEntitlementTooltip.LIMIT_APPROACHING,
                entitlement.node_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_APPROACHING,
              entitlementLimit: entitlement.node_count.limit,
            };
          }
        }
        return undefined;

      case 'upgrade_plan':
        if (entitlement.upgrade_plan_count?.limit) {
          if (consumptionAmount > entitlement.upgrade_plan_count.limit) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_EXCEEDED,
              tooltipText: getFormattedTooltip(
                UpgradePlanEntitlementTooltip.LIMIT_EXCEEDED,
                entitlement.upgrade_plan_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_EXCEEDED,
              entitlementLimit: entitlement.upgrade_plan_count.limit,
            };
          }
          if (consumptionAmount === entitlement.upgrade_plan_count.limit) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_REACHED,
              tooltipText: getFormattedTooltip(
                UpgradePlanEntitlementTooltip.LIMIT_REACHED,
                entitlement.upgrade_plan_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_REACHED,
              entitlementLimit: entitlement.upgrade_plan_count.limit,
            };
          }
          if (
            entitlement.upgrade_plan_count.limit - consumptionAmount <= 5 &&
            consumptionAmount > 0
          ) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_APPROACHING,
              tooltipText: getFormattedTooltip(
                UpgradePlanEntitlementTooltip.LIMIT_APPROACHING,
                entitlement.upgrade_plan_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_APPROACHING,
              entitlementLimit: entitlement.upgrade_plan_count.limit,
            };
          }
        }
        return undefined;
      case 'upgrade_template':
        if (entitlement.upgrade_template_count?.limit) {
          if (consumptionAmount > entitlement.upgrade_template_count.limit) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_EXCEEDED,
              tooltipText: getFormattedTooltip(
                UpgradeTemplateEntitlementTooltip.LIMIT_EXCEEDED,
                entitlement.upgrade_template_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_EXCEEDED,
              entitlementLimit: entitlement.upgrade_template_count.limit,
            };
          }
          if (consumptionAmount === entitlement.upgrade_template_count.limit) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_REACHED,
              tooltipText: getFormattedTooltip(
                UpgradeTemplateEntitlementTooltip.LIMIT_REACHED,
                entitlement.upgrade_template_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_REACHED,
              entitlementLimit: entitlement.upgrade_template_count.limit,
            };
          }
          if (
            entitlement.upgrade_template_count.limit - consumptionAmount <= 5 &&
            consumptionAmount > 0
          ) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_APPROACHING,
              tooltipText: getFormattedTooltip(
                UpgradeTemplateEntitlementTooltip.LIMIT_APPROACHING,
                entitlement.upgrade_template_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_APPROACHING,
              entitlementLimit: entitlement.upgrade_template_count.limit,
            };
          }
        }
        return undefined;
      case 'cluster':
        if (entitlement?.cluster_count?.limit) {
          if (consumptionAmount > entitlement.cluster_count.limit) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_EXCEEDED,
              tooltipText: getFormattedTooltip(
                ClusterEntitlementTooltip.LIMIT_EXCEEDED,
                entitlement.cluster_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_EXCEEDED,
              entitlementLimit: entitlement.cluster_count.limit,
            };
          }
          if (consumptionAmount === entitlement.cluster_count.limit) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_REACHED,
              tooltipText: getFormattedTooltip(
                ClusterEntitlementTooltip.LIMIT_REACHED,
                entitlement.cluster_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_REACHED,
              entitlementLimit: entitlement.cluster_count.limit,
            };
          }
          if (
            entitlement.cluster_count.limit - consumptionAmount <= 10 &&
            consumptionAmount > 0
          ) {
            return {
              limitType: EntitlementBadgeLimitTypes.LIMIT_APPROACHING,
              tooltipText: getFormattedTooltip(
                ClusterEntitlementTooltip.LIMIT_APPROACHING,
                entitlement.cluster_count.limit,
              ),
              colorVariant: EntitlementLimitTypeColorVariant.LIMIT_APPROACHING,
              entitlementLimit: entitlement.cluster_count.limit,
            };
          }
        }
        return undefined;
    }
  };

  const currentEntitlement = getEntitlement();
  const newPath = useAccountIdRoute(
    '/orgs/:orgId/subscriptions?view=subscription',
  );

  return (
    <>
      {currentEntitlement ? (
        <AtomicTooltip
          tooltipContent={
            !currentEntitlement?.tooltipText ? undefined : (
              <>
                {currentEntitlement?.tooltipText}{' '}
                {showSubscriptionLink && (
                  <>
                    <RouterLink to={newPath} className="hoverable-link">
                      Click here
                    </RouterLink>{' '}
                    to see details.
                  </>
                )}
              </>
            )
          }
        >
          <Badge
            variant={currentEntitlement?.colorVariant}
            icon="FeatherAlertTriangle"
          >
            {`${currentEntitlement?.limitType}`}
          </Badge>
        </AtomicTooltip>
      ) : (
        <></>
      )}
    </>
  );
};

export const EntitlementBadge = EntitlementBadgeRoot;
