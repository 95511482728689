import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'warning2';

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  warning2: string;
  error: string;
  error2: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@mui/material' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#FFEC33',
  main: '#FFB900',
  dark: '#0C53B7',
  darker: '#04297A',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#6EFFE3',
  main: '#3BE8B0',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#cc6f3d',
  main: '#F98A4B',
  dark: '#B78103',
  darker: '#7A4F01',
};

const WARNING2 = {
  lighter: '#F98A4B',
  light: '#F98A4B',
  main: '#F98A4B',
  dark: '#F98A4B',
  darker: '#F98A4B',
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#cc5056',
  main: '#FC636B',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const ERROR2 = {
  lighter: '#B72136',
  light: '#B72136',
  main: '#B72136',
  dark: '#B71D18',
  darker: '#B72136',
};

const GREY = {
  grey: '#110F0E',
  0: '#FFFFFF',
  // TailwindCSS's Stone palette (https://tailwindcss.com/docs/customizing-colors)
  30: '#fafaf9',
  100: '#f5f5f4',
  200: '#e7e5e4',
  300: '#d6d3d1',
  400: '#a8a29e',
  500: '#8B8D91',
  600: '#57534e',
  700: '#44403c',
  800: '#292524',
  900: '#1c1917',
  // Stone400
  500_8: alpha('#8B8D91', 0.08),
  500_12: alpha('#8B8D91', 0.12),
  500_16: alpha('#8B8D91', 0.16),
  500_24: alpha('#8B8D91', 0.24),
  500_32: alpha('#8B8D91', 0.32),
  500_48: alpha('#8B8D91', 0.48),
  500_56: alpha('#8B8D91', 0.56),
  500_80: alpha('#8B8D91', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC', '#5B8930'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6', '#FDA92D'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4', '#D24D57'],
};

const COMMON = {
  common: {
    black: '#000',
    white: '#fff',
    red: '#FC636B',
    pink: '#E91E63',
    green: '#3BE8B0',
  },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  warning2: { ...WARNING2, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  error2: { ...ERROR2, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: GREY[30], secondary: GREY[500], disabled: GREY[600] },
    background: {
      paper: GREY[900],
      default: GREY['grey'],
      neutral: GREY[500_16],
    },
    action: { active: GREY[500], ...COMMON.action },
  },
} as const;

export default palette;
