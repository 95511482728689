//hooks
import { useEffect, useState } from 'react';

//models
import { severityBadgeMap } from 'src/models/scans';

//components
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import {
  Cluster,
  ClusterScan,
  ClusterScannedSignature,
  LARStatus,
} from 'api/models';
import { capitalizeFirstLetter } from 'utils/stringOperations';
import BaseTable from './design-system/Table/BaseTable';
import { Stack } from './utils/Stack';
import Label from './Label';
import { Icon } from '@subframe/core';
import { CommentTooltip } from './design-system/CommentTooltip';
import { Badge } from 'subframe/index';
import { markAsReasonDisplay } from 'pages/kba/models/markAs';

interface TableProps {
  cluster: Cluster;
  scan: ClusterScan;
  tableData: ClusterScannedSignature[];
  tableView: string;
  filterClustersType: {
    severity: string;
  };
  searchSigName: string;
  title: string;
  hideSeverity?: boolean;
  isLoading: boolean;
  larStatus?: LARStatus[];
}

export const ClusterDetailsTable = ({
  cluster,
  tableData,
  tableView,
  filterClustersType,
  searchSigName,
  hideSeverity,
  larStatus,
}: TableProps) => {
  const sortedData = tableData.sort((a, b) => {
    if (a.detected === b.detected) {
      return 0;
    }
    if (a.detected) {
      return 1;
    }
    return -1;
  });

  const [displayData, setDisplayData] =
    useState<ClusterScannedSignature[]>(sortedData);

  const { severity } = filterClustersType;

  useEffect(() => {
    if (!searchSigName && !severity) {
      setDisplayData(sortedData);
    }
    let found = sortedData;
    if (searchSigName) {
      found = sortedData.filter((item) =>
        item.title.toLowerCase().includes(searchSigName.toLowerCase()),
      );
    }
    if (severity) {
      found =
        severity === 'all'
          ? found
          : found.filter(
              (item) => item.severity.toLowerCase() === severity.toLowerCase(),
            );
    }
    setDisplayData(found);
  }, [severity, searchSigName, tableView]);

  const tableRowBasePath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );

  return (
    <Stack spacing={2}>
      <BaseTable
        settings={{
          localStorageKey: 'cluster_details_table',
          dataCyPrefix: 'cluster-details-table',
        }}
        data={displayData || []}
        noMatchingData={!displayData.length}
        rowLink={(row) =>
          `${tableRowBasePath}/${cluster.id}/${
            row.detected ? 'lar' : 'arsig'
          }/${row.signature_id.toLowerCase()}`
        }
        columns={[
          {
            id: 'signature-id',
            title: 'ID',
            titleStyle: { width: '15%' },
            cellType: 'text-cell',
            render: (row: ClusterScannedSignature) => {
              return `${row?.signature_id}`;
            },
          },
          {
            id: 'signature-title',
            title: 'Title',
            titleStyle: { width: '70%' },
            cellType: 'cell',
            render: (row: ClusterScannedSignature) => {
              const markAsStatus = larStatus?.find(
                (status) =>
                  status.lar_id.toLowerCase() ===
                    row?.signature_id.toLowerCase() &&
                  status.ignore_status?.reason,
              );

              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '8px',
                  }}
                >
                  <span
                    style={{ font: 'var(--body)', color: 'var(--neutral-500)' }}
                  >
                    {row?.title}
                  </span>

                  {markAsStatus?.ignore_status?.reason && (
                    <Badge variant="neutral">
                      {`Marked as: ${
                        markAsReasonDisplay[markAsStatus.ignore_status.reason]
                      }`}
                    </Badge>
                  )}
                  {markAsStatus?.ignore_status?.comment && (
                    <CommentTooltip
                      userName={markAsStatus.ignore_status.user?.name}
                      picture={markAsStatus.ignore_status.user?.picture}
                      time={markAsStatus.ignore_status.updated}
                      comment={markAsStatus.ignore_status.comment}
                    />
                  )}
                </div>
              );
            },
          },
          {
            id: 'signature-severity',
            title: 'Severity',
            cellType: 'cell',
            titleStyle: { width: '15%' },
            render: (row: ClusterScannedSignature) => {
              return (
                <Label
                  variant={'ghost'}
                  color={
                    severityBadgeMap[row?.severity.toLowerCase()] ||
                    ('info' as any)
                  }
                >
                  {capitalizeFirstLetter(row?.severity)}
                </Label>
              );
            },
          },
        ].filter((col) => col.id !== 'signature-severity' || !hideSeverity)}
        actions={[
          {
            id: 'navigate',
            render: () => {
              return <Icon name="FeatherChevronRight" />;
            },
          },
        ]}
      />
    </Stack>
  );
};
