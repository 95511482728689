import { ReactNode, createContext, useState } from 'react';
import { DialogType, AlertDialogDetails } from 'models/dialog';
// ----------------------------------------------------------------------

type DialogContextProps = {
  currentDialog: DialogType;
  alertDialogDetails: AlertDialogDetails;
  setDialogType: (type: DialogType) => void;
  setAlertDialogDetails: (details: AlertDialogDetails) => void;
};

const initialState: DialogContextProps = {
  currentDialog: '',
  alertDialogDetails: {
    type: 'success',
    message: '',
  },
  setDialogType: (type: DialogType) => {},
  setAlertDialogDetails: (details: AlertDialogDetails) => {},
};

const DialogContext = createContext(initialState);

type DialogDetailsProviderProps = {
  children: ReactNode;
};

function DialogDetailsProvider({ children }: DialogDetailsProviderProps) {
  const [state, setState] = useState(initialState);

  const setDialogType = (type: DialogType) => {
    setState((s) => ({ ...s, currentDialog: type }));
  };

  const setAlertDialogDetails = (details: AlertDialogDetails) =>
    setState((s) => ({ ...s, alertDialogDetails: details }));

  return (
    <DialogContext.Provider
      value={{ ...state, setDialogType, setAlertDialogDetails }}
    >
      {children}
    </DialogContext.Provider>
  );
}

export { DialogDetailsProvider, DialogContext };
