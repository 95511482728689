import { AlignmentToggleButton } from 'components/AlignmentToggleButton';
import Page from 'components/Page';
import SectionHeader from 'layouts/dashboard/SectionHeader';
import { useTheme } from '@mui/material/styles';
import FilterButton from 'components/FilterButton';
import { Card, Grid, Stack } from '@mui/material';
import { Button } from 'subframe/index';
import { ClustersViewLayout } from 'models/general';
import { Searchbar } from 'components/design-system/Searchbar';

const GRID_CLUSTER_CARD_MIN_HEIGHT = '226px';

export default function ClustersPageSkeleton({
  showHeader,
  clustersViewLayout,
  cardWidth = {
    xs: 12,
    md: 6,
    lg: 4,
    xl: 4,
  },
}: {
  showHeader?: boolean;
  clustersViewLayout?: ClustersViewLayout;
  cardWidth?: {
    xs: number;
    md: number;
    lg: number;
    xl: number;
  };
}) {
  const theme = useTheme();
  return (
    <Page title="Clusters">
      <Stack width={'100%'} spacing={showHeader ? 6 : 0}>
        {showHeader && (
          <SectionHeader
            styleType="sticky"
            AlignmentToggleButton={
              <div
                style={{
                  margin: theme.spacing(0, 2),
                }}
              >
                <AlignmentToggleButton
                  alignment={clustersViewLayout}
                  handleAlignment={() => {}}
                />
              </div>
            }
            SearchBar={<Searchbar onChange={() => {}} />}
            FilterButton={
              <FilterButton
                handleOpenFilterClick={() => {}}
                open={false}
                handleClose={() => {}}
                anchorEl={null}
                FilterForm={null}
                isSkeleton={true}
              />
            }
            actionButton={
              <Button
                size="large"
                onClick={() => {}}
                style={{
                  marginLeft: 2,
                  height: '32px',
                  color: theme.palette.common.white,
                  width: '130px',
                }}
              >
                Add Cluster
              </Button>
            }
            title="Clusters"
          />
        )}

        <Grid
          container
          spacing={3}
          sx={{
            paddingTop: theme.spacing(4),
            width: '100%',
            justifyContent: 'initial',
          }}
        >
          <Grid
            item
            xs={cardWidth.xs}
            md={cardWidth.md}
            lg={cardWidth.lg}
            xl={cardWidth.xl}
          >
            <Card
              sx={{
                display: 'flex',
                //p: 3,
                minHeight: GRID_CLUSTER_CARD_MIN_HEIGHT,
                paddingBottom: 3,
                cursor: 'pointer',
                '&:hover': {
                  '& .hover-change': {},
                  backgroundColor: theme.palette.grey[800],
                },
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
}
