import { m } from 'framer-motion';
import Logo from './Logo';
import styles from './LoadingScreen.module.scss';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export default function LoadingScreen({ className, ...otherProps }: Props) {
  const logoSize = 64;

  return (
    <div className={classNames(className, styles.root)} {...otherProps}>
      <m.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Logo
          disabledLink
          showText={false}
          style={{ width: logoSize, height: logoSize }}
        />
      </m.div>

      <m.div
        className={styles.loadingRing1}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
      />

      <m.div
        className={styles.loadingRing2}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          ease: 'linear',
          duration: 3.2,
          repeat: Infinity,
        }}
      />
    </div>
  );
}
