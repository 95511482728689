import {
  styled,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

export const ConceptDetailsHead = styled(Typography)(({ theme }) => ({
  display: 'block',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  cursor: 'pointer',
  paddingLeft: '0',
}));

export const ExpandIcon = styled(Stack)(({ theme }) => ({
  cursor: 'pointer',
  paddingLeft: '0',
}));

export const AccordionStyled = styled(Accordion)(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  width: '100%',
  padding: 'none',
  boxShadow: 'none !important',
  marginTop: '6.4 !important',
}));

export const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    opacity: 0.7,
  },
  minHeight: '48px !important',
}));

export const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  boxShadow: 'none',
  border: 'none',
}));
