'use client';
/*
 * Documentation:
 * Pricing Plan Card - Chkk Copy — https://app.subframe.com/library?component=Pricing+Plan+Card+-+Chkk+Copy_e6014746-ff2b-4b56-ac03-c92143472969
 * Badge — https://app.subframe.com/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { Badge } from './Badge';

interface PlanFeatureItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string;
  className?: string;
}

const PlanFeatureItem = React.forwardRef<HTMLElement, PlanFeatureItemProps>(
  function PlanFeatureItem(
    { children, className, ...otherProps }: PlanFeatureItemProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex items-center gap-3',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <SubframeCore.Icon
          className="text-subheader font-subheader text-brand-700"
          name="FeatherCheck"
        />
        {children ? (
          <span className="text-body font-body text-default-font">
            {children}
          </span>
        ) : null}
      </div>
    );
  },
);

interface PricingPlanCardChkkCopyRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  price?: string;
  planName?: string;
  currentPlan?: boolean;
  children?: React.ReactNode;
  description?: string;
  button?: React.ReactNode;
  renewalFrequency?: string;
  className?: string;
}

const PricingPlanCardChkkCopyRoot = React.forwardRef<
  HTMLElement,
  PricingPlanCardChkkCopyRootProps
>(function PricingPlanCardChkkCopyRoot(
  {
    price,
    planName,
    currentPlan = false,
    children,
    description,
    button,
    renewalFrequency,
    className,
    ...otherProps
  }: PricingPlanCardChkkCopyRootProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/e6014746 flex w-64 flex-col items-start gap-5 rounded border border-solid border-neutral-border bg-default-background pt-5 pr-4 pb-5 pl-4 shadow',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-5">
        <div className="flex flex-col items-start gap-3">
          <div className="flex items-center gap-2">
            {planName ? (
              <span className="text-section-header font-section-header text-default-font">
                {planName}
              </span>
            ) : null}
            <Badge
              className={SubframeCore.twClassNames('hidden', {
                flex: currentPlan,
              })}
              variant="neutral"
            >
              Current plan
            </Badge>
          </div>
          <div className="flex items-end gap-1">
            {price ? (
              <span className="text-subheader font-subheader text-default-font">
                {price}
              </span>
            ) : null}
            {renewalFrequency ? (
              <span className="text-body font-body text-default-font">
                {renewalFrequency}
              </span>
            ) : null}
          </div>
          {description ? (
            <span className="text-label font-label text-neutral-700">
              {description}
            </span>
          ) : null}
        </div>
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
        {children ? (
          <div className="flex w-full flex-col items-start gap-3">
            {children}
          </div>
        ) : null}
      </div>
      {button ? (
        <div className="flex w-full flex-col items-start gap-5">{button}</div>
      ) : null}
    </div>
  );
});

export const PricingPlanCardChkkCopy = Object.assign(
  PricingPlanCardChkkCopyRoot,
  {
    PlanFeatureItem,
  },
);
