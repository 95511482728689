export const severityBadgeMap: Record<string, string> = {
  critical: 'error',
  high: 'warning2',
  medium: 'info',
  low: 'success',
};

export const severityScore: Record<string, number> = {
  Critical: 4,
  High: 3,
  Medium: 2,
  Low: 1,
};
