import { useTheme } from '@mui/material/styles';
import { IconButton, Popover } from '@mui/material';

// components
import Iconify from 'components/Iconify';

type Props = {
  FilterForm: JSX.Element | null;
  handleOpenFilterClick: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: VoidFunction;
  filterSelected?: boolean;
  isSkeleton?: boolean;
};

export default function FilterButton({
  handleOpenFilterClick,
  open,
  anchorEl,
  handleClose,
  FilterForm,
  filterSelected,
  isSkeleton,
}: Props) {
  const theme = useTheme();

  return (
    <>
      <IconButton
        id={isSkeleton ? '' : 'filter-button'}
        onClick={handleOpenFilterClick}
        sx={{
          backgroundColor: filterSelected
            ? theme.palette.grey[700]
            : theme.palette.grey[900],
          border: `1px solid ${
            filterSelected ? theme.palette.grey[600] : theme.palette.grey[800]
          }`,
          height: 32,
          width: 32,
        }}
      >
        <Iconify
          sx={{ color: theme.palette.common.white }}
          icon={'icon-park-outline:filter'}
          width={16}
          height={16}
        />
      </IconButton>
      <Popover
        id={isSkeleton ? '' : 'filter-by-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          marginTop: 2,
        }}
      >
        {FilterForm}
      </Popover>
    </>
  );
}
