'use client';

import React, { SyntheticEvent, useEffect, useState } from 'react';
import * as SubframeCore from '@subframe/core';
import { IconButton, TextField } from 'subframe/index';

type ChangeFunction = (
  event: SyntheticEvent<Element, Event>,
  newValue: string,
) => void;

interface SearchbarRootProps {
  onChange: ChangeFunction;
  className?: string;
  view?: 'example' | 'real';
}

const SearchbarRoot = React.forwardRef<HTMLElement, SearchbarRootProps>(
  function SearchbarRoot(
    { onChange, view, className, ...otherProps }: SearchbarRootProps,
    ref,
  ) {
    const [searchKeyword, setSearchKeyword] = useState('');

    // handle search keyword reset, for now the only usecase is example/real view switch
    useEffect(() => {
      if (searchKeyword && view) {
        setSearchKeyword('');
      }
    }, [view]);

    return (
      <div
        className={SubframeCore.twClassNames(
          'flex flex-col items-center gap-2 rounded pt-2 pr-2 pb-2 pl-2',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <TextField
          className="h-auto w-56 flex-none"
          label=""
          helpText=""
          icon="FeatherSearch"
        >
          <div className="flex w-full grow shrink-0 basis-0 items-center gap-2">
            <TextField.Input
              id={`search-bar`}
              placeholder={'Search'}
              onChange={(event) => {
                setSearchKeyword(event.target.value);
                onChange(event, event.target.value);
              }}
              value={searchKeyword}
            />
            {searchKeyword ? (
              <IconButton
                size="small"
                className="text-body font-body text-subtext-color"
                icon="FeatherX"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setSearchKeyword('');
                  onChange(event, '');
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </TextField>
      </div>
    );
  },
);

export const Searchbar = SearchbarRoot;
