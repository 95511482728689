'use client';
/*
 * Documentation:
 * Card - CTA — https://app.subframe.com/library?component=Card+-+CTA_b2416fd1-705a-42b0-9d03-39bd66e074be
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface CardCtaRootProps extends React.HTMLAttributes<HTMLDivElement> {
  cta?: string;
  icon?: SubframeCore.IconName;
  className?: string;
}

const CardCtaRoot = React.forwardRef<HTMLElement, CardCtaRootProps>(
  function CardCtaRoot(
    { cta, icon = 'FeatherPlus', className, ...otherProps }: CardCtaRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/b2416fd1 flex h-48 w-80 cursor-pointer flex-col items-center justify-center gap-2 rounded border border-dashed border-neutral-border pt-6 pr-6 pb-6 pl-6 hover:border hover:border-dashed hover:border-neutral-border hover:bg-neutral-100',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <SubframeCore.Icon
          className="text-section-header font-section-header text-neutral-500"
          name={icon}
        />
        {cta ? (
          <span className="text-body-bold font-body-bold text-neutral-500">
            {cta}
          </span>
        ) : null}
      </div>
    );
  },
);

export const CardCta = CardCtaRoot;
