'use client';
/*
 * Documentation:
 * CTA Card — https://app.subframe.com/library?component=CTA+Card_49303866-eb4c-48c1-880b-e18e2b70193e
 * Icon with background — https://app.subframe.com/library?component=Icon+with+background_c5d68c0e-4c0c-4cff-8d8c-6ff334859b3a
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface CtaCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'outline' | 'fill';
  leftSlot?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

const CtaCardRoot = React.forwardRef<HTMLElement, CtaCardRootProps>(
  function CtaCardRoot(
    {
      variant = 'outline',
      leftSlot,
      children,
      className,
      ...otherProps
    }: CtaCardRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/49303866 flex cursor-pointer items-center gap-4 rounded border border-solid border-neutral-border bg-default-background pt-4 pr-4 pb-4 pl-4 hover:bg-neutral-50 active:bg-neutral-100',
          {
            'rounded border-none bg-neutral-50 shadow-none hover:bg-neutral-100 active:bg-neutral-50':
              variant === 'fill',
          },
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {leftSlot ? <div className="flex items-start">{leftSlot}</div> : null}
        {children ? (
          <div className="flex grow shrink-0 basis-0 flex-col items-start">
            {children}
          </div>
        ) : null}
        <SubframeCore.Icon
          className="text-section-header font-section-header text-neutral-400 group-hover/49303866:text-subtext-color"
          name="FeatherChevronRight"
        />
      </div>
    );
  },
);

export const CtaCard = CtaCardRoot;
