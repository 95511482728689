export const truncateAtLength = (target: string, length: number) => {
  if (target) {
    if (target.length <= length) {
      return target;
    }
    return `${target.slice(0, length - 3)}...`;
  }
  return '';
};

export const capitalizeString = (str: string) => {
  if (str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  }
  return '';
};

export const formatQueryParams = (params: any) => {
  if (params && typeof params === 'object') {
    const paramList = [];
    for (const [key, value] of Object.entries(params)) {
      if (typeof key === 'string') {
        if (Array.isArray(value)) {
          for (const item of value) {
            if (typeof item === 'string') {
              paramList.push(
                `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
              );
            }
          }
        } else if (typeof value === 'string') {
          paramList.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
          );
        }
      }
    }
    return paramList.join('&');
  }
  return '';
};
