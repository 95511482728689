import { Stack, Typography, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DialogAnimate } from '../animate';
import Iconify from 'components/Iconify';
import { Spacer } from 'components/Spacer';
import { AlertDialogType } from 'models/dialog';
import useDialog from 'hooks/useDialog';
import { Button } from 'subframe/index';
interface AlertDialogProps {
  open: boolean;
  handleClose: () => void;
  type: AlertDialogType;
  message: string;
  sxProp?: SxProps;
}

export const AlertDialog = ({
  open,
  handleClose,
  type,
  message,
  sxProp,
}: AlertDialogProps) => {
  const theme = useTheme();

  const { setDialogType } = useDialog();

  const alertContextMapping: Record<
    string,
    {
      icon: string;
      color: string;
      sx?: object;
    }
  > = {
    success: {
      icon: 'akar-icons:circle-check-fill',
      color: theme.palette.success.main,
      sx: {
        color: theme.palette.success.main,
        border: `3px solid ${theme.palette.success.main}`,
        borderRadius: '50%',
      },
    },
    error: {
      icon: 'gridicons:cross-circle',
      color: theme.palette.error.main,
      sx: {
        color: theme.palette.error.main,
        border: `3px solid ${theme.palette.error.main}`,
        borderRadius: '50%',
      },
    },
  };

  return (
    <DialogAnimate open={open} onClose={handleClose} sx={{ ...sxProp }}>
      <Stack alignItems={'center'} sx={{ p: 3 }}>
        <Iconify
          icon={alertContextMapping[type].icon}
          color={alertContextMapping[type].color}
          width={60}
          height={60}
          sx={alertContextMapping[type].sx}
        />
        <Spacer value={15} />
        <Typography variant="h6" align="center">
          {message}
        </Typography>
        <Spacer value={20} />
        <Button
          size="large"
          id="alert-dialog-ok-button"
          onClick={() => {
            setDialogType('');
            handleClose();
          }}
        >
          OK
        </Button>
      </Stack>
    </DialogAnimate>
  );
};
