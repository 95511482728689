'use client';
/*
 * Documentation:
 * Steps — https://app.subframe.com/cd4f5307efe3/library?component=Steps_3ef39526-e60d-4356-b74d-e44c176f3cc4
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { Avatar } from './Avatar';
import { IconWithBackground } from './IconWithBackground';

interface StepProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  firstStep?: boolean;
  lastStep?: boolean;
  variant?:
    | 'default'
    | 'success-inactive'
    | 'error-inactive'
    | 'in-progress-inactive'
    | 'blocked-inactive'
    | 'in-progress-active'
    | 'success-active'
    | 'error-active'
    | 'blocked-active';
  stepNumber?: string;
  className?: string;
}

const Step = React.forwardRef<HTMLElement, StepProps>(function Step(
  {
    name,
    firstStep = false,
    lastStep = false,
    variant = 'default',
    stepNumber,
    className,
    ...otherProps
  }: StepProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/d8c2bfb3 flex w-full cursor-pointer flex-col items-start gap-2',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex w-full flex-col items-center justify-center gap-1">
        <div className="flex w-full items-center justify-center gap-2">
          <div
            className={SubframeCore.twClassNames(
              'flex h-px grow shrink-0 basis-0 flex-col items-center gap-2 bg-neutral-300',
              { 'bg-transparent': firstStep },
            )}
          />
          <div className="flex items-center justify-center gap-2">
            <Avatar
              className={SubframeCore.twClassNames({
                hidden:
                  variant === 'blocked-active' ||
                  variant === 'error-active' ||
                  variant === 'success-active' ||
                  variant === 'in-progress-active',
              })}
              variant="neutral"
              size="small"
            >
              {stepNumber}
            </Avatar>
            <Avatar
              className={SubframeCore.twClassNames('hidden', {
                flex:
                  variant === 'blocked-active' ||
                  variant === 'error-active' ||
                  variant === 'success-active' ||
                  variant === 'in-progress-active',
                hidden:
                  variant === 'blocked-inactive' ||
                  variant === 'in-progress-inactive' ||
                  variant === 'error-inactive' ||
                  variant === 'success-inactive',
              })}
              variant="neutral"
              size="small"
            >
              {stepNumber}
            </Avatar>
          </div>
          <div
            className={SubframeCore.twClassNames(
              'flex h-px grow shrink-0 basis-0 flex-col items-center gap-2 bg-neutral-300',
              { 'bg-transparent': lastStep },
            )}
          />
        </div>
        <div className="flex grow shrink-0 basis-0 items-center justify-center gap-2 pt-1 pr-1 pb-1 pl-1 text-center">
          {name ? (
            <span
              className={SubframeCore.twClassNames(
                'grow shrink-0 basis-0 text-body font-body text-subtext-color',
                {
                  'text-body-bold font-body-bold text-default-font':
                    variant === 'blocked-active' ||
                    variant === 'error-active' ||
                    variant === 'success-active' ||
                    variant === 'in-progress-active',
                },
              )}
            >
              {name}
            </span>
          ) : null}
          <IconWithBackground
            className={SubframeCore.twClassNames('hidden', {
              flex:
                variant === 'success-active' || variant === 'success-inactive',
              hidden:
                variant === 'blocked-inactive' ||
                variant === 'in-progress-inactive' ||
                variant === 'error-inactive',
            })}
            variant="success"
            size="x-small"
            icon="FeatherCheck"
          />
          <IconWithBackground
            className={SubframeCore.twClassNames('hidden', {
              flex: variant === 'error-active' || variant === 'error-inactive',
              hidden:
                variant === 'blocked-inactive' ||
                variant === 'in-progress-inactive' ||
                variant === 'success-inactive',
            })}
            variant="error"
            size="x-small"
            icon="FeatherX"
          />
          <IconWithBackground
            className={SubframeCore.twClassNames('hidden', {
              flex:
                variant === 'blocked-active' || variant === 'blocked-inactive',
              hidden:
                variant === 'in-progress-inactive' ||
                variant === 'error-inactive' ||
                variant === 'success-inactive',
            })}
            icon="FeatherStopCircle"
          />
        </div>
      </div>
    </div>
  );
});

interface StepsRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  className?: string;
}

const StepsRoot = React.forwardRef<HTMLElement, StepsRootProps>(
  function StepsRoot(
    { children, className, ...otherProps }: StepsRootProps,
    ref,
  ) {
    return children ? (
      <div
        className={SubframeCore.twClassNames(
          'flex w-full items-start rounded bg-neutral-50 pt-3 pb-3 pl-3',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children}
      </div>
    ) : null;
  },
);

export const Steps = Object.assign(StepsRoot, {
  Step,
});
