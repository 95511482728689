import amplitude, { AmplitudeClient } from 'amplitude-js';
import { createContext, ReactNode, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { proxyAmplitudeEvent } from 'api/frontend';

type EventLogger = (event: string, metadata?: {}) => void;
type OptTracking = () => void;

interface InternalAmplitudeClient extends AmplitudeClient {
  _ua: {
    browser: {
      name: string;
      major: string;
    };
    os: {
      name: string;
    };
  };
}

type AmplitudeContextProps = {
  enabled: boolean;
  clientKey: string;
  instance: AmplitudeClient | null;
  ampIdentify: (userId: string, name: string, email: string) => void;
  ampLogEvent: EventLogger;
  ampOptInTracking: OptTracking;
  ampOptOutTracking: OptTracking;
};

const initialState: AmplitudeContextProps = {
  enabled: true,
  clientKey: '',
  instance: null,
  ampIdentify: () => {},
  ampLogEvent: () => {},
  ampOptInTracking: () => {},
  ampOptOutTracking: () => {},
};

const AmplitudeContext = createContext(initialState);

type AmplitudeProviderProps = {
  children: ReactNode;
  ampLogEvent?: EventLogger;
  ampOptInTracking?: OptTracking;
  ampOptOutTracking?: OptTracking;
};

function AmplitudeProvider(args: AmplitudeProviderProps) {
  const [state, setState] = useState<AmplitudeContextProps>(initialState);

  const createLogEvent = async (event: any) => {
    try {
      await proxyAmplitudeEvent({ logDetails: event });
    } catch (error) {
      // not important to handle for the moment
    }
  };

  useEffect(() => {
    if (
      import.meta.env.VITE_ENABLE_ANALYTICS === 'true' &&
      !window.navigator.userAgent.includes('heartbeat_canary')
    ) {
      const k = import.meta.env.VITE_AMPLITUDE_KEY;
      const i = amplitude.getInstance() as InternalAmplitudeClient;
      i.init(k, '', { transport: 'beacon' });
      const iCb = (userId: string, name: string, email: string) => {
        if (email && name && userId) {
          const payloadObject = {
            event_type: 'login',
            device_id: i.options.deviceId,
            session_id: i.getSessionId(),
            platform: i.options.platform,
            os_name: i._ua.browser.name,
            os_version: i._ua.browser.major,
            device_model: i._ua.os.name,
            language: i.options.language,
            user_properties: {
              $set: {
                email: email,
                name: name,
                user_id: userId,
              },
            },
          };
          createLogEvent(payloadObject);
        }
      };

      let lCb: EventLogger;
      if (args.ampLogEvent) {
        lCb = args.ampLogEvent;
      } else {
        lCb = (event: string, metadata?: {}) => {
          const payloadObject = {
            event_type: event,
            event_properties: metadata || {},
            device_id: i.options.deviceId,
            user_id: '',
            session_id: i.getSessionId(),
            platform: i.options.platform,
            os_name: i._ua.browser.name,
            os_version: i._ua.browser.major,
            device_model: i._ua.os.name,
            language: i.options.language,
          };
          createLogEvent(payloadObject);
        };
      }
      let optInCb: OptTracking;
      if (args.ampOptInTracking) {
        optInCb = args.ampOptInTracking;
      } else {
        optInCb = () => {
          amplitude?.getInstance().setOptOut(false);
        };
      }
      let optOutCb: OptTracking;
      if (args.ampOptOutTracking) {
        optOutCb = args.ampOptOutTracking;
      } else {
        optOutCb = () => {
          //delete amplitude cookie
          const k = import.meta.env.VITE_AMPLITUDE_KEY;
          Cookies.remove('amp_' + k?.substring(0, 6)); //amplitude cookies are created as amp_{first six digits of api key}
          //stop amplitude analytics
          amplitude?.getInstance().setOptOut(true);
        };
      }

      setState({
        enabled: true,
        clientKey: k,
        instance: i,
        ampIdentify: iCb,
        ampLogEvent: lCb,
        ampOptInTracking: optInCb,
        ampOptOutTracking: optOutCb,
      });
    }
  }, [args.ampLogEvent]);

  return (
    <AmplitudeContext.Provider value={state}>
      {args.children}
    </AmplitudeContext.Provider>
  );
}

export { AmplitudeProvider, AmplitudeContext };
