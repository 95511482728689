'use client';
/*
 * Documentation:
 * Conversation — https://app.subframe.com/cd4f5307efe3/library?component=Conversation_69420770-6e13-4436-a9f8-be2de029867f
 * Chat Messages — https://app.subframe.com/cd4f5307efe3/library?component=Chat+Messages_dd45f7a1-a942-4200-8956-3494c669197a
 * See all button — https://app.subframe.com/cd4f5307efe3/library?component=See+all+button_6c277935-fa63-49cf-84d2-037df9f9a413
 * Details List — https://app.subframe.com/cd4f5307efe3/library?component=Details+List_936d99e7-8a24-40f9-a4ce-c876fc56a6bd
 * Reply Message - Base — https://app.subframe.com/cd4f5307efe3/library?component=Reply+Message+-+Base_a35432e7-806e-4495-8897-0867ff505337
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface ConversationRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  replyElements?: React.ReactNode;
  className?: string;
}

const ConversationRoot = React.forwardRef<HTMLElement, ConversationRootProps>(
  function ConversationRoot(
    {
      children,
      replyElements,
      className,
      ...otherProps
    }: ConversationRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex w-full flex-col items-start gap-2 rounded border border-solid border-neutral-border bg-default-background pt-3 pr-3 pb-3 pl-3 shadow',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children ? (
          <div className="flex w-full flex-col items-start gap-2">
            {children}
          </div>
        ) : null}
        {replyElements ? (
          <div className="flex w-full flex-col items-start gap-2">
            {replyElements}
          </div>
        ) : null}
      </div>
    );
  },
);

export const Conversation = ConversationRoot;
