'use client';
/*
 * Documentation:
 * Product Usage Item — https://app.subframe.com/library?component=Product+Usage+Item_6eff2723-128b-4c91-babe-66cda27a08cf
 * Badge — https://app.subframe.com/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 * Progress — https://app.subframe.com/library?component=Progress_60964db0-a1bf-428b-b9d5-f34cdf58ea77
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface ProductUsageItemRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  name?: string;
  headingSlot?: React.ReactNode;
  progressBar?: React.ReactNode;
  progressValue?: number;
  totalCount?: string;
  value?: string;
  className?: string;
}

const ProductUsageItemRoot = React.forwardRef<
  HTMLElement,
  ProductUsageItemRootProps
>(function ProductUsageItemRoot(
  {
    name,
    headingSlot,
    progressBar,
    progressValue = 100,
    totalCount,
    value,
    className,
    ...otherProps
  }: ProductUsageItemRootProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'flex w-full max-w-[320px] flex-col items-start justify-center gap-2',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex items-center gap-1">
        {name ? (
          <span className="text-body font-body text-default-font">{name}</span>
        ) : null}
        {headingSlot ? (
          <div className="flex items-center">{headingSlot}</div>
        ) : null}
      </div>
      {value ? (
        <span className="text-section-header font-section-header text-default-font">
          {value}
        </span>
      ) : null}
      {progressBar ? (
        <div className="flex w-full max-w-[288px] flex-col items-end justify-center gap-2">
          {progressBar}
        </div>
      ) : null}
    </div>
  );
});

export const ProductUsageItem = ProductUsageItemRoot;
