import { Dialog } from '@mui/material';
import { JiraIntegration } from 'components/JiraIntegration';
import { DialogHeader } from './DialogHeader';

import useDialog from 'hooks/useDialog';

interface JiraIntegrationDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const JiraIntegrationDialog = ({
  open,
  handleClose,
}: JiraIntegrationDialogProps) => {
  const { setDialogType } = useDialog();
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        setDialogType('');
      }}
    >
      <DialogHeader header="Integrate with Jira" handleClose={() => {}} />
      <JiraIntegration submittedCb={handleClose} />
    </Dialog>
  );
};
