import { generatePath, Params } from 'react-router';
import useUserAccountState from './useUserAccountState';

const useAccountIdRoute = (path?: string, params: Params<string> = {}) => {
  const { account, currentOrganization } = useUserAccountState();

  if (account.accountId && path && currentOrganization.id) {
    return generatePath(path, {
      orgId: currentOrganization.id,
      accountId: account.accountId,
      ...params,
    });
  } else {
    return '';
  }
};

export default useAccountIdRoute;
