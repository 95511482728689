// @subframe/sync-disable
// Custom props and logic is added
'use client';
/*
 * Documentation:
 * Reply Message - Base — https://app.subframe.com/cd4f5307efe3/library?component=Reply+Message+-+Base_a35432e7-806e-4495-8897-0867ff505337
 * Text Field — https://app.subframe.com/cd4f5307efe3/library?component=Text+Field_be48ca43-f8e7-4c0e-8870-d219ea11abfe
 * Tooltip — https://app.subframe.com/cd4f5307efe3/library?component=Tooltip_ccebd1e9-f6ac-4737-8376-0dfacd90c9f3
 * Icon Button — https://app.subframe.com/cd4f5307efe3/library?component=Icon+Button_af9405b1-8c54-4e01-9786-5aad308224f6
 * Button — https://app.subframe.com/cd4f5307efe3/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 */

import React, { useState } from 'react';
import * as SubframeCore from '@subframe/core';
import { TextArea } from './TextArea';
import { Tooltip } from './Tooltip';
import { IconButton } from './IconButton';
import { Button } from './Button';
import { AtomicTooltip, WrapWithBadge } from 'components/design-system';

interface ReplyMessageBaseRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  prompts?: React.ReactNode;
  uploadIcon?: SubframeCore.IconName;
  uploadBadge?: React.ReactNode;
  disableActionsString?: string;
  disableUpload?: boolean;
  onSend?: (message: string) => void;
  onUploadClick?: () => void;
  isSending?: boolean;
}

const ReplyMessageBaseRoot = React.forwardRef<
  HTMLElement,
  ReplyMessageBaseRootProps
>(function ReplyMessageBaseRoot(
  {
    className,
    prompts,
    uploadIcon = 'FeatherUpload',
    uploadBadge,
    onSend,
    onUploadClick,
    disableActionsString,
    disableUpload,
    isSending,
    ...otherProps
  }: ReplyMessageBaseRootProps,
  ref,
) {
  const [message, setMessage] = useState('');

  const disableSend = () => {
    if (message || uploadBadge) return false;
    else return true;
  };
  return (
    <div
      className={SubframeCore.twClassNames(
        'flex w-full flex-col items-start gap-2',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex w-full items-end gap-2">
        <TextArea className="h-auto grow shrink-0 basis-0" label="" helpText="">
          <TextArea.Input
            className="h-auto min-h-[64px] w-full flex-none"
            placeholder=""
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                if (onSend === undefined) {
                  return;
                }

                onSend(message);
                setMessage('');
              }
            }}
            disabled={Boolean(disableActionsString) || isSending}
          />
        </TextArea>
        {(disableUpload === undefined || !disableUpload) && (
          <>
            {uploadBadge && (
              <AtomicTooltip
                tooltipContent={
                  disableActionsString
                    ? `Action not allowed. ${disableActionsString}`
                    : 'Upload a file or image'
                }
              >
                <WrapWithBadge badge={uploadBadge}>
                  <IconButton
                    icon={uploadIcon}
                    size="medium"
                    variant="brand-tertiary"
                    onClick={disableActionsString ? () => {} : onUploadClick}
                    disabled={Boolean(disableActionsString) || isSending}
                  />
                </WrapWithBadge>
              </AtomicTooltip>
            )}
            {!uploadBadge && (
              <AtomicTooltip
                tooltipContent={
                  disableActionsString
                    ? `Action not allowed. ${disableActionsString}`
                    : 'Upload a file or image'
                }
              >
                <IconButton
                  icon={uploadIcon}
                  size="medium"
                  variant="brand-tertiary"
                  onClick={disableActionsString ? () => {} : onUploadClick}
                  disabled={Boolean(disableActionsString) || isSending}
                />
              </AtomicTooltip>
            )}
          </>
        )}
        {disableActionsString ? (
          <AtomicTooltip
            tooltipContent={`Action not allowed. ${disableActionsString}`}
          >
            <Button
              variant="brand-tertiary"
              size="medium"
              icon="FeatherSend"
              onClick={() => {
                if (onSend === undefined) {
                  return;
                }

                onSend(message);
                setMessage('');
              }}
              disabled={Boolean(disableActionsString)}
            >
              Send
            </Button>
          </AtomicTooltip>
        ) : (
          <Button
            variant="brand-tertiary"
            size="medium"
            icon="FeatherSend"
            onClick={() => {
              if (onSend === undefined) {
                return;
              }

              onSend(message);
              setMessage('');
            }}
            disabled={disableSend() || isSending}
            loading={isSending}
          >
            Send
          </Button>
        )}
      </div>
      <div className="flex gap-2 items-center">
        {prompts !== undefined ? (
          prompts
        ) : (
          <>
            <Button
              variant="brand-tertiary"
              size="medium"
              icon={null}
              disabled={isSending}
            >
              Yes
            </Button>
            <Button
              variant="brand-tertiary"
              size="medium"
              icon={null}
              disabled={isSending}
            >
              No,later
            </Button>
            <Button
              variant="brand-tertiary"
              size="medium"
              icon={null}
              disabled={isSending}
            >
              Not sure
            </Button>
          </>
        )}
      </div>
    </div>
  );
});

export const ReplyMessageBase = ReplyMessageBaseRoot;
