import { useState } from 'react';
import { Button } from 'subframe/components/Button';
import { submitFeedback } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import { toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import * as SubframeCore from '@subframe/core';
import { ResolvedSubscription } from 'api/models';

interface Props {
  CTAType?: 'button' | 'link';
  variant?: 'brand-primary' | 'brand-secondary';
  iconName?: SubframeCore.IconName;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  description: string;
  clickHandler?: () => void;
  subscription: ResolvedSubscription;
}

// TODO: Refactor RequestSubscriptionPlanExtensionCTA, GuidedPocContactUsButton, ProductPlanContactUsButton to use a common component
export const RequestSubscriptionPlanExtensionCTA = ({
  CTAType = 'button',
  variant,
  size,
  iconName,
  className,
  description,
  clickHandler,
  subscription,
}: Props) => {
  const { account, user, currentOrganization } = useUserAccountState();

  const [isSubmitting, setisSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const submitRequest = async () => {
    try {
      await submitFeedback(
        {
          title: `${subscription.plan}ExtensionRequest`,
          details: JSON.stringify({
            request: `🔔 Heads up, a customer 🙋‍♂️🙋‍♀️ just requested ${subscription.name} extension! `,
            accountId: account?.accountId,
            orgId: currentOrganization?.id,
            view: 'Upgrades',
            planRequest: `${subscription.plan} Extension`,
          }),
          user_email: user.email || user.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );

      enqueueSnackbar(
        `We have received your request to extend the ${subscription.name}. We will be in touch with you shortly`,
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        `Failed to request ${subscription.name} extension. Please try again or escalate to Chkk`,
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } finally {
      setisSubmitting(false);
    }
  };
  return CTAType === 'button' ? (
    <Button
      variant={variant || 'brand-primary'}
      icon={iconName}
      size={size || 'small'}
      loading={isSubmitting}
      disabled={isSubmitting}
      onClick={async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setisSubmitting(true);
        clickHandler && clickHandler();
        await submitRequest();
      }}
      className={classNames(className)}
    >
      {description}
    </Button>
  ) : (
    <span
      className="hoverable-link text-label font-label cursor-pointer"
      onClick={async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setisSubmitting(true);
        clickHandler && clickHandler();
        await submitRequest();
      }}
    >
      {description}
    </span>
  );
};
