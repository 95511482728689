import { styled, Typography, Link, Stack } from '@mui/material';

export const NormalText = styled(Typography)(() => ({
  fontSize: '15px',
  lineHeight: 1.8,
  textAlign: 'left',
}));

export const DocumentSectionLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'block',
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  fontSize: 13,
  marginTop: 10,
  lineHeight: 1.4,
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none',
  },
}));

export const DocumentSection = styled(Stack)(({ theme }) => ({
  paddingTop: '50px',
}));
export const DocumentSubSection = styled(Stack)(({ theme }) => ({
  paddingTop: '50px',
  '@media (min-width: 1200px)': {
    marginTop: '-25px !important',
  },
  '@media (max-width:1200px)': {
    paddingTop: '60px',
    marginTop: '-25px !important',
  },
}));
export const SubSectionHead = styled(Typography)(() => ({
  fontSize: '0.875rem !important',
}));

DocumentSection.defaultProps = {
  spacing: 0.8,
};
