import { StackProps, Stack } from '@mui/material';

const Centered = (props: StackProps) => {
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      {props.children}
    </Stack>
  );
};

export default Centered;
