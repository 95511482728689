import { useTheme } from '@mui/material/styles';
import { useState, SyntheticEvent, useEffect, useMemo } from 'react';

import { Container, Typography, Grid, Stack } from '@mui/material';
import ClusterDetailsSearchHeader from 'layouts/dashboard/ClusterDetailsSearchHeader';

import Iconify from 'components/Iconify';

import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useDebounce } from 'react-use';

import { ClusterDetailsTable } from './ClusterDetailsTable';
import { clearSensitivity, ClusterDetailsFilterView } from 'utils/filters';
import useSettings from 'hooks/useSettings';

import { clusterDetailsTableView } from 'utils/filters';
import { Spacer } from './Spacer';
import { IconWithBackground } from 'subframe/index';
import { Text } from '@subframe/core';
import {
  Cluster,
  ClusterDetectedLar,
  ClusterScan,
  ClusterScannedSignature,
  LARStatus,
} from 'api/models';

interface Props {
  scan: ClusterScan;
  cluster: Cluster;
  ARSigs: ClusterScannedSignature[] | undefined;
  unignoredLARs: ClusterDetectedLar[] | undefined;
  ignoredLARs: ClusterDetectedLar[] | undefined;
  title: string;
  hideSeverity?: boolean;
  larStatus: LARStatus[];
}

export default function ClusterScannedSignatures({
  scan,
  cluster,
  ARSigs,
  unignoredLARs,
  ignoredLARs,
  title,
  hideSeverity,
  larStatus,
}: Props) {
  const { themeStretch } = useSettings();
  const [filterClustersType, setFilterClustersType] = useState({
    severity: '',
  });
  const [filterSelected, setFilterSelected] = useState(false);
  const [searchSigName, setSearchSigName] = useState('');

  const getBestPracticesInitialFilter = (title: string) => {
    return clearSensitivity(title) === 'guardrails'
      ? ClusterDetailsFilterView.Detected
      : ClusterDetailsFilterView.NeedAttention;
  };

  const [tableView, setTableView] = useState<clusterDetailsTableView>(
    getBestPracticesInitialFilter(title),
  );

  const [showTableView, setShowTableView] = useState<clusterDetailsTableView>(
    getBestPracticesInitialFilter(title),
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const tabCount = {
    NeedsAttention: unignoredLARs?.filter((sig) => sig.need_attention).length,
    Detected: unignoredLARs?.length,
    UnDetected: ARSigs?.filter((sig) => !sig.detected).length,
    Ignored: ignoredLARs?.length,
  };

  const displayTableData = useMemo(() => {
    switch (tableView) {
      case ClusterDetailsFilterView.NeedAttention:
        return {
          data: unignoredLARs
            ?.filter((sig) => sig.need_attention)
            .map((sig) => ({
              ...sig,
              detected: true,
            })) as ClusterScannedSignature[],
          filteredView: ClusterDetailsFilterView.NeedAttention,
        };

      case ClusterDetailsFilterView.Detected:
        return {
          data: unignoredLARs?.map((sig) => ({
            ...sig,
            detected: true,
          })) as ClusterScannedSignature[],
          filteredView: ClusterDetailsFilterView.Detected,
        };

      case ClusterDetailsFilterView.UnDetected:
        return {
          data: ARSigs?.filter((sig) => !sig.detected),
          filteredView: ClusterDetailsFilterView.UnDetected,
        };

      case ClusterDetailsFilterView.Ignored:
        return {
          data: ignoredLARs?.map((sig) => ({
            ...sig,
            detected: true,
          })) as ClusterScannedSignature[],
          filteredView: ClusterDetailsFilterView.Ignored,
        };

      default:
        return {
          data: unignoredLARs
            ?.filter((sig) => sig.need_attention)
            .map((sig) => ({
              ...sig,
              detected: true,
            })) as ClusterScannedSignature[],
          filteredView: ClusterDetailsFilterView.NeedAttention,
        };
    }
  }, [tableView, unignoredLARs, ignoredLARs, ARSigs]);
  useEffect(() => {
    if (
      tableView === showTableView &&
      showTableView === displayTableData.filteredView
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }

    if (tableView !== showTableView) {
      setShowTableView(tableView);
    }
  }, [displayTableData, showTableView]);

  const { logEvent } = AnalyticsEventLogger();

  const theme = useTheme();

  const handleOnChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    setSearchSigName(newValue);
  };

  useDebounce(
    () => {
      if (searchSigName) {
        logEvent('search-sig', {
          clusterId: cluster.id,
        });
      }
    },
    1000,
    [searchSigName],
  );

  const handleFilterSubmit = (formValues: { severity: string }) => {
    setFilterClustersType(formValues);
    if (formValues.severity === '') {
      setFilterSelected(false);
    } else {
      setFilterSelected(true);
    }
  };
  let headerTitle = '';

  const noIssuesMessage = () => {
    switch (tableView) {
      case 'NeedsAttention':
        return 'No Issues Need Attention';

      case 'Ignored':
        return 'No Issues Ignored';

      case 'Detected':
        return 'No Issues Detected';

      default:
        return 'No Issues Found';
    }
  };
  const emptyTableMesg = () => {
    let totalDetectedCount = unignoredLARs?.length;

    headerTitle.split(' ')[headerTitle.split(' ').length - 1];

    switch (tableView) {
      case 'NeedsAttention':
        if (title !== 'System Requirements')
          return `Chkk did not find any ${title} that Need Attention in this cluster.`;
        else return `This cluster meets all ${title} scanned by Chkk.`;

      case 'Ignored':
        if (title !== 'System Requirements')
          return `No ${title} have been Ignored in this cluster.`;
        else return `No unmet ${title} have been Ignored in this cluster.`;

      case 'Detected':
        if (title !== 'System Requirements')
          return `Chkk did not find any ${title} in this cluster.`;
        else return `This cluster meets all ${title} scanned by Chkk.`;

      case 'UnDetected':
        if (title === 'System Requirements')
          return `Chkk detected ${totalDetectedCount} ${title} that are not met in this cluster. Once you meet these ${title}, they will automatically appear in this table.`;
        else
          return `Chkk detected ${totalDetectedCount} ${title} in this cluster. Once you fix these ${title}, they will automatically appear in this table.`;
    }
  };

  return (
    <Container
      maxWidth={themeStretch ? false : '100%'}
      sx={{ paddingRight: '0px !important' }}
    >
      {ARSigs?.length ? (
        <>
          <ClusterDetailsSearchHeader
            title={title}
            onChange={handleOnChange}
            items={displayTableData.data}
            handleFilterSubmit={handleFilterSubmit}
            filterSelected={filterSelected}
            filterClustersType={filterClustersType}
            type={title.toLocaleLowerCase()}
            clusterId={cluster?.id}
            tableView={showTableView}
            setTableView={setTableView}
            tabCount={tabCount}
            hideSeverity={hideSeverity || false}
            setIsLoading={setIsLoading}
          />
          {tabCount[tableView] && displayTableData.data?.length ? (
            <ClusterDetailsTable
              cluster={cluster}
              scan={scan}
              tableData={displayTableData.data}
              filterClustersType={filterClustersType}
              searchSigName={searchSigName}
              hideSeverity={hideSeverity || false}
              title={title}
              isLoading={isLoading}
              larStatus={larStatus}
              tableView={tableView}
            />
          ) : (
            <>
              <Stack
                id="chkk-empty-message"
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                  minWidth: 720,
                  backgroundColor: theme.palette.grey[900],
                  borderRadius: 'var(--rounded)',
                  height: '27rem',
                }}
              >
                {
                  <Stack
                    alignItems={'center'}
                    justifyContent="center"
                    width="fill"
                    height="fill"
                    spacing={2}
                  >
                    <IconWithBackground
                      icon="FeatherCheck"
                      variant="success"
                      size="medium"
                    />
                    <Stack alignItems="center">
                      <Text variant="subheader">{noIssuesMessage()}</Text>
                      <Text
                        variant="body"
                        color="subtext"
                        id="arsigs-category-table-empty-message"
                      >
                        {emptyTableMesg()}
                      </Text>
                    </Stack>
                  </Stack>
                }
              </Stack>
              <Spacer value={128} />
            </>
          )}
        </>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          height="38.75rem"
        >
          <Grid item>
            <Iconify
              sx={{ color: theme.palette.common.white }}
              icon={'ep:folder-delete'}
              width={40}
              height={32}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 400 }}>
              Chkk did not find any {title} relevant to this cluster.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
