'use client';
/*
 * Documentation:
 * Card - Integration — https://app.subframe.com/library?component=Card+-+Integration_3d97e230-6f19-453f-a715-543b0e892987
 * Badge — https://app.subframe.com/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface CardIntegrationRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  cardTitle?: string;
  text?: string;
  badge?: React.ReactNode;
  logo?: React.ReactNode;
  inactive?: boolean;
  loading?: boolean;
  selected?: boolean;
  nonClickable?: boolean;
  className?: string;
}

const CardIntegrationRoot = React.forwardRef<
  HTMLElement,
  CardIntegrationRootProps
>(function CardIntegrationRoot(
  {
    cardTitle,
    text,
    badge,
    logo,
    inactive = false,
    loading = false,
    selected = false,
    nonClickable = false,
    className,
    ...otherProps
  }: CardIntegrationRootProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/3d97e230 flex h-36 w-96 cursor-pointer flex-col items-start gap-3.5 rounded bg-neutral-50 pt-6 pr-6 pl-6 hover:bg-neutral-100',
        {
          'border border-solid border-brand-primary bg-neutral-100': selected,
          'bg-neutral-100': inactive,
        },
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <div className="flex w-full items-center gap-2">
        {logo ? <div className="flex items-center gap-2">{logo}</div> : null}
        <div className="flex w-full grow shrink-0 basis-0 items-center">
          {cardTitle ? (
            <span
              className={SubframeCore.twClassNames(
                'line-clamp-1 break-words text-subheader font-subheader text-default-font',
                { 'text-subtext-color': inactive },
              )}
            >
              {cardTitle}
            </span>
          ) : null}
          {badge ? (
            <div
              className={SubframeCore.twClassNames(
                'flex items-center gap-4 pr-3 pl-1',
                { 'flex-row gap-4': inactive },
              )}
            >
              {badge}
            </div>
          ) : null}
          <SubframeCore.Loader
            className={SubframeCore.twClassNames(
              'hidden text-body font-body text-brand-600',
              { 'inline-block': loading },
            )}
          />
        </div>
        <SubframeCore.Icon
          className={SubframeCore.twClassNames(
            'text-subheader font-subheader text-subtext-color group-hover/3d97e230:text-default-font',
            { hidden: nonClickable, 'text-neutral-border': inactive },
          )}
          name="FeatherChevronRight"
        />
      </div>
      <div className="flex w-full flex-col items-center gap-2">
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
        {text ? (
          <span
            className={SubframeCore.twClassNames(
              'w-full text-body font-body text-subtext-color',
              { 'text-subtext-color': inactive },
            )}
          >
            {text}
          </span>
        ) : null}
      </div>
    </div>
  );
});

export const CardIntegration = CardIntegrationRoot;
