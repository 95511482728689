/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * Chkk SSO Provider
 */
export type SSOProvider = (typeof SSOProvider)[keyof typeof SSOProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SSOProvider = {
  'okta-oidc': 'okta-oidc',
  'okta-saml': 'okta-saml',
  'ping-one-saml': 'ping-one-saml',
} as const;
