/* Release: 40bc7ee5 (Latest – unreleased) */

import classNames from 'classnames';
import * as SubframeCore from '@subframe/core';
import React from 'react';
import styles from './RiskCard.module.scss';

interface RiskCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  icon?: SubframeCore.IconName;
  needAttentionTooltip?: React.ReactNode;
  numRisks?: string;
  inactive?: boolean;
  className?: string;
}

const RiskCardRoot = React.forwardRef<HTMLElement, RiskCardRootProps>(
  function RiskCardRoot(
    {
      title = 'Unsupported Versions',
      icon = 'FeatherXSquare',
      needAttentionTooltip,
      numRisks = '9',
      inactive = false,
      className,
      ...otherProps
    }: RiskCardRootProps,
    ref,
  ) {
    return (
      <div
        className={classNames(styles['stack'], className, {
          [styles['inactive-true']]: inactive,
          [styles['inactive-false']]: !inactive,
        })}
        ref={ref as any}
        {...otherProps}
      >
        <div className={styles['stack-6b08babe']}>
          <SubframeCore.Icon
            className={classNames(styles['subheaderText'], styles['icon'])}
            name={icon}
          />
          {title ? (
            <span
              className={classNames(styles['subheaderText'], styles['text'])}
            >
              {title}
            </span>
          ) : null}
        </div>
        <div className={styles['stack-4f9d4f40']}>
          <div className={styles['stack-3302106a']}>
            <span className={styles['text-32b6d05f']}>Need Attention</span>
            <SubframeCore.HoverCard.Root>
              <SubframeCore.HoverCard.Trigger asChild={true}>
                <SubframeCore.Icon
                  className={styles['infoIcon']}
                  name="FeatherInfo"
                />
              </SubframeCore.HoverCard.Trigger>
              <SubframeCore.HoverCard.Portal>
                <SubframeCore.HoverCard.Content
                  side="bottom"
                  align="center"
                  sideOffset={8}
                  asChild={true}
                >
                  <div className={styles['stack-43605b7e']}>
                    {needAttentionTooltip}
                  </div>
                </SubframeCore.HoverCard.Content>
              </SubframeCore.HoverCard.Portal>
            </SubframeCore.HoverCard.Root>
          </div>
          {numRisks ? (
            <span
              className={classNames(
                styles['sectionHeaderText'],
                styles['text-3f5d29e5'],
              )}
            >
              {numRisks}
            </span>
          ) : null}
        </div>
      </div>
    );
  },
);

export const RiskCard = RiskCardRoot;
