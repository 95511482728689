import { useState } from 'react';
import { SubscriptionPlan } from 'api/models';
import { Button } from 'subframe/index';
import { submitFeedback } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';
import { toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { isSubscriptionPlan } from 'utils/subscription_plan';

interface Props {
  variant?: 'brand-primary' | 'brand-secondary';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  view?: 'Upgrades' | 'Subscription Plan';
  plan?:
    | SubscriptionPlan
    | 'AdditionalQuota'
    | 'AdditionalPlanQuota' //Temporary, will be removed once we update Upgrade Plan -> Template Everywhere. (Currently used for ProductPlans20240214)
    | 'MonthlyOrQuarterlyPaymentRequest';
  handleClose?: () => void;
  text?: string;
  showIcon?: boolean;
  link?: boolean;
}
// TODO: Refactor RequestFreeTrialExtensionCTA, GuidedPocContactUsButton, ProductPlanContactUsButton to use a common component

export const ProductPlanContactUsButton = ({
  variant,
  size,
  className,
  view,
  plan,
  handleClose,
  text,
  showIcon,
  link = false,
}: Props) => {
  const { account, user, currentOrganization } = useUserAccountState();

  const [isSubmitting, setisSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();

  const getSuccessfulToastMessage = () => {
    if (plan === 'AdditionalQuota') {
      if (view === 'Upgrades') {
        return `Thank you for requesting additional Upgrade Plans. We will be in touch with you shortly`;
      } else {
        return `Thank you for requesting additional Nodes and Upgrade Templates. We will be in touch with you shortly`;
      }
    } else if (plan === 'AdditionalPlanQuota') {
      return `Thank you for requesting additional Nodes and Upgrade Plans. We will be in touch with you shortly`;
    } else if (isSubscriptionPlan(plan)) {
      return `Thank you for requesting the ${plan} Plan. We will be in touch with you shortly`;
    } else {
      return `Thank you for contacting us. We will be in touch with you shortly`;
    }
  };

  const submitPricingRequest = async () => {
    logEvent('contact-us-pricing-request', {
      view: view || '',
      planRequest: plan || '',
    });

    try {
      await submitFeedback(
        {
          title: 'ContactUsPricingRequest',
          details: JSON.stringify({
            request:
              '🔔 Heads up, a customer 🙋‍♂️🙋‍♀️ just requested pricing assistance! ',
            accountId: account?.accountId,
            orgId: currentOrganization?.id,
            view: view || '',
            planRequest: plan || '',
          }),
          user_email: user.email || user.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );

      enqueueSnackbar(getSuccessfulToastMessage(), {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        `Failed to submit Subscription Request. Please try again or escalate to Chkk`,
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } finally {
      setisSubmitting(false);
    }
  };
  return (
    <>
      {link ? (
        <span
          className="hoverable-link text-body cursor-pointer"
          onClick={async (event) => {
            event.stopPropagation();
            event.preventDefault();
            logEvent('contact-us-for-pricing');
            setisSubmitting(true);
            await submitPricingRequest();
            handleClose && handleClose();
          }}
        >
          Contact us
        </span>
      ) : (
        <Button
          variant={variant || 'brand-primary'}
          icon={
            showIcon === undefined || showIcon ? 'FeatherContact' : undefined
          }
          size={size || 'small'}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={async (event) => {
            event.stopPropagation();
            event.preventDefault();
            setisSubmitting(true);
            await submitPricingRequest();
            handleClose && handleClose();
          }}
          className={classNames(className)}
        >
          {text || 'Contact Us'}
        </Button>
      )}
    </>
  );
};
