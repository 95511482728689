import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import React from 'react';

interface TrackerProps {
  children?: React.ReactElement | React.ReactElement[];
  event: string;
  metadata?: {};
}

export const Tracker = ({ children, event, metadata }: TrackerProps) => {
  const { logEvent } = AnalyticsEventLogger();

  let clickable;
  if (children) {
    let h: (e?: any) => void;
    if (!Array.isArray(children)) {
      h = children.props['onClick'];
      clickable = React.cloneElement(children, {
        onClick: (e: React.MouseEvent<any, MouseEvent>) => {
          logEvent(event, metadata || {});
          if (h) {
            h(e);
          }
        },
      });
    } else {
      clickable = (
        <>
          {children.map((c, i) => {
            h = c.props['onClick'];
            return React.cloneElement(c, {
              key: i,
              onClick: (e: React.MouseEvent<any, MouseEvent>) => {
                logEvent(event, metadata || {});
                if (h) {
                  h(e);
                }
              },
            });
          })}
        </>
      );
    }
  }

  return <>{clickable}</>;
};
