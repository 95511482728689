import { ResolvedEntitlementFeaturesItem } from 'api/models';
import { LockingWrapper } from 'components/LockingWrapper';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip } from 'components/design-system';
import { getPaginatedChunk } from 'components/design-system/Table/TablePagination';
import { toastAutoHideDuration } from 'constants/toasts';
import useLocalStorage from 'hooks/useLocalStorage';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { Badge, Button, DropdownMenu, Pagination, Table } from 'subframe/index';
import { Select } from 'subframe/components/Select';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';

interface ColumnDefinitionBase {
  id: string;
  title: JSX.Element | string;
  titleStyle?: React.CSSProperties;
  cellStyle?: React.CSSProperties;
  hideCapture?: boolean;
}
interface ColumnDefinitionCell<T> extends ColumnDefinitionBase {
  cellType: 'cell';
  render: (item: T) => JSX.Element;
}
interface ColumnDefinitionBadge<T> extends ColumnDefinitionBase {
  cellType?: 'badge-cell';
  cellProperties?: {
    variant?: (
      item: T,
    ) => 'brand' | 'neutral' | 'error' | 'warning' | 'success';
  };
  render: (item: T) => string;
}
interface ColumnDefinitionText<T> extends ColumnDefinitionBase {
  cellType?: 'text-cell';
  cellProperties?: {
    primary?: boolean;
  };
  render: (item: T) => string;
}

interface BaseTableProps<T> {
  data: T[];
  columns: (
    | ColumnDefinitionCell<T>
    | ColumnDefinitionText<T>
    | ColumnDefinitionBadge<T>
  )[];
  actions: {
    id: string;
    render: (item: T) => JSX.Element;
  }[];
  rowLink?: (item: T) => string;
  onRowClick?: (item: T) => void;
  settings?: {
    defaultRowsPerPage?: number;
    pageSizeOptions?: number[];
    localStorageKey?: string;
    dataCyPrefix?: string;
  };
  noMatchingData?: boolean;
  noMatchingDataMessage?: string;
  emtpyState?: JSX.Element;
  rowSettings?: {
    preventClick?: (item: T) => boolean;
    preventClickToastMessage?: string;
    rowTooltip?: (item: T) => string;
  };
  rowLock?: {
    feature: ResolvedEntitlementFeaturesItem;
    showLock: boolean;
    showExampleData?: boolean;
    tooltipText?: string;
    defaultAction?: (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => void;
  };
}

interface BaseTableUserSettings {
  pageSize: number;
}

export default function BaseTable<T>(props: BaseTableProps<T>): JSX.Element {
  const [currentPage, setCurrentPage] = useState(0);
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();

  const defaultUserSettings: BaseTableUserSettings = {
    pageSize: props.settings?.defaultRowsPerPage || 25,
  };
  const [userSettings, setUserSettings] = props.settings?.localStorageKey
    ? useLocalStorage<BaseTableUserSettings>(
        props.settings?.localStorageKey,
        defaultUserSettings,
      )
    : useState<BaseTableUserSettings>(defaultUserSettings);

  const rowsPerPageOptions = props.settings?.pageSizeOptions || [
    5, 10, 15, 20, 25,
  ];

  if (currentPage * userSettings.pageSize > props.data.length) {
    setCurrentPage(0);
  }

  const displayStart = currentPage * userSettings.pageSize + 1;
  const displayEnd = Math.min(
    (currentPage + 1) * userSettings.pageSize,
    props.data.length,
  );

  const displayResourcesPerPage = useMemo(() => {
    return (
      getPaginatedChunk(currentPage, props.data, userSettings.pageSize) || []
    );
  }, [userSettings, currentPage, props.data]);

  useEffect(() => {
    setCurrentPage(0);
  }, [props.data]);

  return (
    <>
      <Table
        data-cy={`${props.settings?.dataCyPrefix || ''}-table`}
        header={
          <Table.HeaderRow
            data-cy={`${props.settings?.dataCyPrefix || ''}-table-header`}
          >
            {props.columns.map((column) => {
              return (
                <Table.HeaderCell
                  key={column.id}
                  data-cy={`${props.settings?.dataCyPrefix || ''}-${column.id}`}
                  style={column.titleStyle}
                >
                  {column.title}
                </Table.HeaderCell>
              );
            })}
            {props.actions && props.actions.length > 0 && (
              <Table.HeaderCell>{''}</Table.HeaderCell>
            )}
          </Table.HeaderRow>
        }
      >
        {displayResourcesPerPage.map((item, idx) => {
          const cells = props.columns.map((column) => {
            if (column.cellType === 'cell' || column.cellType === undefined) {
              return (
                <Table.Cell
                  key={column.id}
                  style={column.cellStyle}
                  data-cy={`${props.settings?.dataCyPrefix || ''}-${column.id}`}
                  className={column.hideCapture ? 'ph-no-capture' : ''}
                >
                  {column.render(item)}
                </Table.Cell>
              );
            } else if (column.cellType === 'badge-cell') {
              const { variant, ...restProperties } =
                column.cellProperties || {};
              return (
                <Table.Cell
                  key={column.id}
                  style={column.cellStyle}
                  className={column.hideCapture ? 'ph-no-capture' : ''}
                  {...restProperties}
                >
                  <Badge
                    variant={variant ? variant(item) : 'brand'}
                    data-cy={`${props.settings?.dataCyPrefix || ''}-${
                      column.id
                    }-${column.render(item)}`}
                  >
                    {column.render(item)}
                  </Badge>
                </Table.Cell>
              );
            } else if (column.cellType === 'text-cell') {
              return (
                <Table.Cell
                  key={column.id}
                  data-cy={`${props.settings?.dataCyPrefix || ''}-${column.id}`}
                  {...column.cellProperties}
                  style={column.cellStyle}
                >
                  <span
                    className={`${
                      column.cellProperties?.primary
                        ? 'text-body [font:var(--body-bold)] text-neutral-700'
                        : 'text-body font-body text-neutral-500'
                    } ${column.hideCapture ? 'ph-no-capture' : ''}`}
                  >
                    {column.render(item)}
                  </span>
                </Table.Cell>
              );
            }
          });

          if (props.actions && props.actions.length > 0) {
            cells.push(
              <Table.Cell key="actions" className="justify-end">
                {props.actions.map((action) => {
                  return action.render(item);
                })}
              </Table.Cell>,
            );
          }

          const row = (
            <Table.Row
              data-cy={`${props.settings?.dataCyPrefix || ''}-table-row`}
              clickable={true}
              key={idx}
              onClick={(event) => {
                if (
                  props.rowSettings?.preventClick &&
                  props.rowSettings?.preventClick(item)
                ) {
                  event.stopPropagation();
                  event.preventDefault();
                  enqueueSnackbar(props.rowSettings?.preventClickToastMessage, {
                    variant: 'warning',
                    autoHideDuration: toastAutoHideDuration,
                  });
                } else {
                  props.onRowClick && props.onRowClick(item);
                }
              }}
              onContextMenu={(event) => {
                if (
                  props.rowSettings?.preventClick &&
                  props.rowSettings?.preventClick(item)
                ) {
                  event.stopPropagation();
                  event.preventDefault();
                  enqueueSnackbar(props.rowSettings?.preventClickToastMessage, {
                    variant: 'warning',
                    autoHideDuration: toastAutoHideDuration,
                  });
                }
              }}
            >
              {cells}
            </Table.Row>
          );

          // handle row tooltip
          const rowWithTooltip =
            props.rowSettings?.rowTooltip &&
            props.rowSettings?.rowTooltip(item) ? (
              <AtomicTooltip
                tooltipContent={props.rowSettings?.rowTooltip(item)}
              >
                {row}
              </AtomicTooltip>
            ) : (
              row
            );

          // handle subscription lock
          const rowWithLock = props?.rowLock ? (
            <LockingWrapper
              feature={props.rowLock.feature}
              showExampleData={props.rowLock.showExampleData}
              showLock={props.rowLock.showLock}
              tooltipText={props.rowLock.tooltipText || ''}
              style={{
                display: 'contents',
              }}
              defaultAction={props.rowLock.defaultAction}
            >
              {rowWithTooltip}
            </LockingWrapper>
          ) : (
            rowWithTooltip
          );

          // handle row link
          if (props.rowLink) {
            return (
              <RouterLink
                to={props.rowLink(item)}
                style={{ display: 'contents' }}
                key={idx}
                onClick={() => {
                  logEvent(`${props.settings?.dataCyPrefix || ''}-row-click`, {
                    Key: rowWithTooltip.key,
                  });
                }}
              >
                {rowWithLock}
              </RouterLink>
            );
          } else {
            return rowWithLock;
          }
        })}
      </Table>
      {/* table has data initially but no search results found */}
      {props.noMatchingData && (
        <div
          className="w-full flex-col gap-[8px] items-center justify-center flex [font:var(--body)]"
          data-cy="no-matching-data"
        >
          {props.noMatchingDataMessage || 'No matching results found'}
        </div>
      )}
      {!props.data.length && props.emtpyState}
      {!props.noMatchingData && (!props.emtpyState || !!props.data.length) && (
        <Pagination
          data-cy={`${props.settings?.dataCyPrefix || ''}-table-pagination`}
          text={`Rows per Page`}
          rightText={`Showing ${displayStart} to ${displayEnd} of ${props.data.length} results`}
          leftActions={
            <Select
              icon={null}
              placeholder={`${userSettings.pageSize}`}
              onValueChange={(value) =>
                setUserSettings((old) => {
                  return { ...old, pageSize: Number(value) };
                })
              }
            >
              {rowsPerPageOptions.map((option, idx) => (
                <Select.Item key={idx} value={`${option}`}>
                  {`${option}`}
                </Select.Item>
              ))}
            </Select>
          }
          nextButton={
            <Button
              variant="neutral-secondary"
              iconRight="FeatherChevronRight"
              size="medium"
              disabled={displayEnd === props.data.length}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </Button>
          }
          previousButton={
            <Button
              variant="neutral-secondary"
              icon="FeatherChevronLeft"
              size="medium"
              disabled={displayStart === 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </Button>
          }
        />
      )}
    </>
  );
}
