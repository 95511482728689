'use client';
/*
 * Documentation:
 * Pagination — https://app.subframe.com/library?component=Pagination_390719b9-e878-4c15-b78f-cb17ede1128f
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface PaginationRootProps extends React.HTMLAttributes<HTMLDivElement> {
  previousButton?: React.ReactNode;
  nextButton?: React.ReactNode;
  text?: string;
  leftActions?: React.ReactNode;
  rightText?: string;
  className?: string;
}

const PaginationRoot = React.forwardRef<HTMLElement, PaginationRootProps>(
  function PaginationRoot(
    {
      previousButton,
      nextButton,
      text,
      leftActions,
      rightText,
      className,
      ...otherProps
    }: PaginationRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex w-full items-center gap-2',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {leftActions ? (
          <div className="flex items-center gap-2">{leftActions}</div>
        ) : null}
        <div className="flex grow shrink-0 basis-0 items-center gap-2">
          {text ? (
            <span className="grow shrink-0 basis-0 text-body font-body text-default-font">
              {text}
            </span>
          ) : null}
          <div className="flex items-center gap-2">
            {rightText ? (
              <span className="text-body font-body text-default-font">
                {rightText}
              </span>
            ) : null}
            {previousButton ? (
              <div className="flex items-center gap-2">{previousButton}</div>
            ) : null}
            {nextButton ? (
              <div className="flex items-center gap-2">{nextButton}</div>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

export const Pagination = PaginationRoot;
