'use client';

import * as SubframeCore from '@subframe/core';
import React from 'react';

interface CheckboxGroupRootProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  helpText?: string;
  error?: boolean;
  horizontal?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const CheckboxGroupRoot = React.forwardRef<HTMLElement, CheckboxGroupRootProps>(
  function CheckboxGroupRoot(
    {
      label,
      helpText,
      error = false,
      horizontal = false,
      children,
      className,
      ...otherProps
    }: CheckboxGroupRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/f9f1b596 flex flex-col items-start gap-2',
          { 'flex-col gap-2': horizontal },
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {label ? (
          <span
            className={SubframeCore.twClassNames(
              'text-body-bold font-body-bold text-default-font',
              { 'text-default-font': error },
            )}
          >
            {label}
          </span>
        ) : null}
        {children ? (
          <div
            className={SubframeCore.twClassNames(
              'flex flex-col items-start gap-2',
              { 'flex-row gap-6': horizontal },
            )}
          >
            {children}
          </div>
        ) : null}
        {helpText ? (
          <span
            className={SubframeCore.twClassNames(
              'text-label font-label text-subtext-color',
              { 'text-error-700': error },
            )}
          >
            {helpText}
          </span>
        ) : null}
      </div>
    );
  },
);

export const CheckboxGroup = CheckboxGroupRoot;
