import React from 'react';

import styles from './InactiveClusterDialog.module.scss';
import { Button, Dialog, IconButton } from 'subframe/index';
import { TextArea } from 'subframe/components/TextArea';
import { SetStateFunction } from 'models/react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider from 'components/FormProvider';

function InactiveClusterDialog({
  open,
  loading,
  onSubmit,
  setOpen,
}: {
  open: boolean;
  loading: boolean;
  onSubmit: (message: string) => void;
  setOpen: SetStateFunction<boolean>;
}) {
  interface FormFields {
    message: string;
  }
  const FormFieldsSchema = Yup.object().shape({
    message: Yup.string()
      .required('Infrastructure change details are required')
      .max(1000),
  });

  const defaultValues: FormFields = {
    message: '',
  };

  const methods = useForm<FormFields>({
    resolver: yupResolver(FormFieldsSchema),
    defaultValues: defaultValues,
  });

  // Reset this form upon closing
  React.useEffect(() => {
    if (!open) {
      methods.reset(defaultValues);
      methods.clearErrors();
    }
  }, [open]);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  return (
    <FormProvider methods={methods}>
      <Dialog open={open} onOpenChange={() => setOpen(!open)}>
        <Dialog.Content className="h-auto w-144 flex-none">
          <div
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            className={styles['stack-50352971']}
          >
            <div className={styles['stack']}>
              <span className={styles['text']}>
                Your cluster has become Inactive
              </span>
              <IconButton
                size="medium"
                icon="FeatherX"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <div className={styles['stack-9897f13d']}>
              <TextArea
                className="h-auto w-full flex-none"
                label="Did anything change in your infrastructure?"
                helpText={errors.message?.message}
                error={methods.getFieldState('message').invalid}
              >
                <TextArea.Input
                  placeholder="Add details about any changes in infrastructure here"
                  {...methods.register('message')}
                />
              </TextArea>
              <div className={styles['stack-cf394868']}>
                <Button
                  size="large"
                  variant="brand-primary"
                  icon={null}
                  loading={loading || isSubmitting}
                  disabled={loading || isSubmitting}
                  type={'submit'}
                  onClick={handleSubmit((isValid) => {
                    if (isValid) {
                      onSubmit(methods.getValues('message'));
                      setOpen(false);
                    }
                  })}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </FormProvider>
  );
}

export default InactiveClusterDialog;
