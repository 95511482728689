import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material';
import { Spacer } from 'components/Spacer';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { integrationsApi } from 'src/api/constants';
import RHFTextField from '../RHFTextField';
import FormProvider from '../FormProvider';
import useDialog from 'hooks/useDialog';
import { FieldLabel, FieldStack } from './elements';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { Integration } from 'models/integrations';
import Iconify from 'components/Iconify';
import { useTheme } from '@mui/material/styles';
import { Button, Checkbox } from 'subframe/index';
import { Icon, Text } from '@subframe/core';
import styles from './JiraIntegration.module.scss';
import { AtomicTooltip } from 'components/design-system';
import useUserAccountState from 'hooks/useUserAccountState';
import { toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import { axiosInstance } from 'api/axios-instance';
import { createIntegration, deleteIntegration } from 'api/frontend';
import {
  CreateIntegrationRequest,
  IntegrationJiraConfiguration,
} from 'api/models';

interface JiraIntegrationFields {
  jiraUser: string;
  jiraToken: string;
  jiraUrl: string;
  jiraProjectKey: string;
  jiraBugTrackingCheck: boolean;
}

interface JiraIntegrationProps {
  submittedCb: () => void;
}

export const JiraIntegration = ({ submittedCb }: JiraIntegrationProps) => {
  const { setDialogType } = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();
  const { account } = useUserAccountState();
  const [loading, setLoading] = useState(false);
  const [currentJiraIntegrationId, setCurrentJiraIntegrationId] = useState('');
  const [hideToken, setHideToken] = useState(true);
  const [bugTrackingCheck, setBugTrackingCheck] = useState(false);
  const SubmissionError =
    'Something went wrong while integrating Jira. Please try again or escalate to Chkk';
  const JiraTicketSchema = Yup.object().shape({
    jiraUser: Yup.string().required('Jira User is required'),
    jiraToken: Yup.string().required('Jira Token is required'),
    jiraUrl: Yup.string()
      .url('Jira URL must be a valid URL')
      .required('Jira Url is required'),
    jiraProjectKey: Yup.string().required('Jira Project Key is required'),
    jiraBugTrackingCheck: Yup.boolean().test(
      'isChecked',
      'This check is required, please check the box confirming that the provided Jira project key corresponds to a Bug tracking project.',
      (value) => value === true,
    ),
  });

  const defaultValues: JiraIntegrationFields = {
    jiraUser: '',
    jiraToken: '',
    jiraUrl: '',
    jiraProjectKey: '',
    jiraBugTrackingCheck: false,
  };

  const methods = useForm<JiraIntegrationFields>({
    resolver: yupResolver(JiraTicketSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { setValue } = methods;

  const onSubmit = async (data: JiraIntegrationFields) => {
    const { jiraUser, jiraToken, jiraUrl, jiraProjectKey } = data;
    const headers = {
      Authorization: `Bearer ${account?.token}`,
    };
    try {
      if (currentJiraIntegrationId) {
        await deleteIntegration(currentJiraIntegrationId, { headers });
      }
      logEvent('jira-integration-submit');

      const jiraConfiguration: IntegrationJiraConfiguration = {
        jira_user: jiraUser,
        jira_token: jiraToken,
        jira_url: jiraUrl + (jiraUrl[jiraUrl.length - 1] === '/' ? '' : '/'),
        jira_project_key: jiraProjectKey,
      };
      const body: CreateIntegrationRequest = {
        integration_name: 'jira',
        integration_type: 'jira',
        status: 'complete',
        jira_configuration: jiraConfiguration,
      };

      await createIntegration(body, {
        headers,
      });
      enqueueSnackbar('Jira integrated successfully', {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      setDialogType('');
      submittedCb();
    } catch ({ response }) {
      enqueueSnackbar(
        response?.data?.message || response?.data || SubmissionError,
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      Sentry.captureException(
        response?.data?.message || response?.data || SubmissionError,
        { level: 'log' },
      );
    }
  };
  useEffect(() => {
    setValue('jiraBugTrackingCheck', bugTrackingCheck);
  }, [bugTrackingCheck]);

  useEffect(() => {
    const fetchIntegrationDetails = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${account?.token}`,
          'Content-Type': 'application/json',
        };
        setLoading(true);
        const {
          data: { data },
        } = await axiosInstance.get(integrationsApi, { headers });
        if (data.length) {
          const index = data.findIndex(
            ({ integration_name }: Integration) => integration_name === 'jira',
          );
          if (index !== -1) {
            const {
              integration_id,
              jira_configuration: {
                jira_user,
                jira_url,
                jira_token,
                jira_project_key,
              },
            } = data[index];

            setValue('jiraUser', jira_user);
            setValue('jiraToken', jira_token);
            setValue('jiraUrl', jira_url);
            setValue('jiraProjectKey', jira_project_key);
            setBugTrackingCheck(jira_project_key ? true : false);
            setLoading(false);
            setCurrentJiraIntegrationId(integration_id);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch {
        setLoading(false);
      }
    };
    fetchIntegrationDetails();
  }, [account?.token, setValue]);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const toggleTokenHideShowState = () =>
    setHideToken((previousState) => !previousState);
  const theme = useTheme();
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack alignItems={'center'} sx={{ p: 3, pt: 0 }}>
        {!loading ? (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Spacer value={25} />

              <FieldStack>
                <FieldLabel>Jira User</FieldLabel>
                <RHFTextField
                  id="jira-user"
                  placeholder="Enter Jira User"
                  name="jiraUser"
                />
              </FieldStack>
              <Spacer value={20} />

              <FieldStack>
                <FieldLabel>Jira Token</FieldLabel>
                <RHFTextField
                  id="jira-token"
                  type={hideToken ? 'password' : 'text'}
                  placeholder="Enter Jira Token"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ marginRight: '0.5px' }}
                          aria-label="toggle token visibility"
                          onClick={toggleTokenHideShowState}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {
                            <Iconify
                              icon={
                                hideToken
                                  ? 'akar-icons:eye-open'
                                  : 'ant-design:eye-invisible-outlined'
                              }
                              width={20}
                              height={20}
                            />
                          }
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name="jiraToken"
                />
              </FieldStack>
              <Spacer value={20} />

              <FieldStack>
                <FieldLabel>Jira URL</FieldLabel>
                <RHFTextField
                  id="jira-url"
                  placeholder="Enter Jira URL"
                  name="jiraUrl"
                />
              </FieldStack>
              <Spacer value={20} />

              <FieldStack>
                <Stack direction="row" alignItems={'center'} spacing={1}>
                  <FieldLabel>Jira Project Key </FieldLabel>
                  <AtomicTooltip
                    tooltipContent={
                      <Text variant="label">
                        We currently support Jira Software project of type{' '}
                        <b>Bug tracking</b> only.
                      </Text>
                    }
                  >
                    <Icon className={styles['icon']} name="FeatherInfo" />
                  </AtomicTooltip>
                </Stack>{' '}
                <RHFTextField
                  id="jira-key"
                  placeholder="Enter Jira Project Key"
                  name="jiraProjectKey"
                />
              </FieldStack>
              <Spacer value={20} />

              <FieldStack sx={{ alignItems: 'flex-start' }}>
                <Checkbox
                  checked={bugTrackingCheck}
                  label={
                    <Text
                      style={{ display: 'inline-block', marginTop: '-3px' }}
                    >
                      By checking this box, you acknowledge that the project key
                      provided belongs to a <b>Bug tracking</b> project.
                    </Text>
                  }
                  onClick={() => setBugTrackingCheck(!bugTrackingCheck)}
                  name={'jiraBugTrackingCheck'}
                  style={{
                    alignSelf: 'flex-start',
                  }}
                  data-cy={'bug-tracking-check'}
                  className={styles['checkbox-icon']}
                />
              </FieldStack>
            </Box>
            <Button
              size="large"
              type="submit"
              id="jira-submit"
              loading={isSubmitting}
              disabled={isSubmitting || !bugTrackingCheck}
            >
              Submit
            </Button>
          </>
        ) : (
          <Box
            sx={{
              height: '160px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="inherit" size={40} />
          </Box>
        )}
      </Stack>
    </FormProvider>
  );
};
