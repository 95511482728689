/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */
import useSwr from 'swr';
import type { SWRConfiguration, Key } from 'swr';
import type {
  ListCloudConnectionsResponse,
  ListCloudConnectionsParams,
  CloudConnection,
  CreateCloudConnectionRequest,
  UpdateCloudConnectionRequest,
  CloudConnectionAction,
  CreateCloudConnectionActionRequest,
  TeamInvitation,
  AccountStats,
  GetAccountStatsParams,
  ListAccountTeamsResponse,
  ListAccountTeamsParams,
  Team,
  ListTeamInvitationsResponse,
  ListTeamInvitationsParams,
  AddTeamMemberRequest,
  SSOConfiguration,
  CreateSSOConfigurationRequest,
  UpdateSSOConfigurationRequest,
  ListAccountAddonsResponse,
  ListAccountAddonsParams,
  ProxyAmplitudeEvent200,
  AmplitudeAnalyticsReport,
  CompoundListClustersResponse,
  ListClustersParams,
  Cluster,
  CreateClusterRequest,
  UpdateClusterRequest,
  ListClusterScansResponse,
  ListLARsStatusResponse,
  LARStatus,
  UpdateLARStatusRequest,
  IgnoreLARRequest,
  AcknowledgeLARRequest,
  ListClusterScansResponseV2,
  ListClusterScansParams,
  ClusterScan,
  ListClusterScanSignaturesResponse,
  ListClusterScanSignaturesParams,
  ListClusterScanLARsResponse,
  ListClusterScanLARsParams,
  ClusterDetectedLar,
  ListClusterScanLARResourcesResponse,
  ListClusterScanLARResourcesParams,
  RescanClusterRequest,
  CreateClusterScanRequest,
  SubmitFeedbackResponse,
  SubmitFeedbackRequest,
  ListIntegrationsResponse,
  Integration,
  CreateIntegrationRequest,
  GetPrometheusStatusParams,
  UpdateIntegrationRequest,
  GetTicketDetailsResponse,
  LarsOverviewResponse,
  LoginResponse,
  LoginParams,
  Organization,
  CreateOrganizationRequest,
  UpdateOrganizationRequest,
  ListSubscriptionsResponse,
  ListSubscriptionsParams,
  CreateSubscriptionResponse,
  CreateSubscriptionRequest,
  ResolvedSubscription,
  UpdateSubscriptionRequest,
  ListAccessTokensResponse,
  GetIngestionTokenResponse,
  ListIngestionTokensResponse,
  IngestionToken,
  CreateIngestionTokenRequest,
  UpdateIngestionTokenRequest,
  QuickStartResponse,
  CreateScanResponse,
  CreateScanRequest,
  CreateTicketRequest,
  SubmitAgentStatusNotificationRequest,
  GetUsersResponse,
  ListDirectoryAddonsResponse,
  ListDirectoryAddonsParams,
  ListAddonInstancesResponse,
  ListAddonInstancesParams,
  AddonInstance,
  ListAddonInstanceResourcesResponse,
  ListAvailabilityRisksResponse,
  ListAvailabilityRisksParams,
  AvailabilityRisksSummary,
  GetAvailabilityRisksSummaryParams,
  ListAffectedResourcesResponse,
  GetAvailabilityRiskAffectedResourceParams,
  ListConversationsResponse,
  ListConversationsParams,
  Conversation,
  CreateConversationRequest,
  ListConversationMessagesResponse,
  ListConversationMessagesParams,
  GetConversationConnectionDetailsResponse,
  ListUpgradePlansResponse,
  ListUpgradePlansParams,
  UpgradePlan,
  CreateUpgradePlanRequest,
  UpdateUpgradePlanRequest,
  ListUpgradePlanHistoryResponse,
  ListUpgradePlanHistoryParams,
  ListUpgradePlanStagesResponse,
  ListUpgradePlanStagesParams,
  UpgradeStage,
  ListUpgradePlanStageActionsResponse,
  ListUpgradePlanStageActionsParams,
  CreateUpgradePlanStageActionRequest,
  UpgradeStageAction,
  ListUpgradePlanStageStepsResponse,
  ListUpgradePlanStageStepsParams,
  UpgradeStageStep,
  CreateUpgradePlanStageStepRequest,
  UpdateUpgradePlanStageStepRequest,
  ListUpgradePlanStageStepCommentsResponse,
  ListUpgradePlanStageStepCommentsParams,
  CreateUpgradePlanStageStepCommentRequest,
  ListUpgradePlanStageStepActionsResponse,
  ListUpgradePlanStageStepActionsParams,
  CreateUpgradePlanStageStepActionRequest,
  UpgradeStageStepAction,
  ListUpgradeTemplatesResponse,
  ListUpgradeTemplatesParams,
  UpgradeTemplate,
  CreateUpgradeTemplateRequest,
  UpdateUpgradeTemplateRequest,
  ListUpgradeTemplateHistoryResponse,
  ListUpgradeTemplateHistoryParams,
  ListUpgradeTemplateStagesResponse,
  ListUpgradeTemplateStagesParams,
  ListUpgradeTemplateStageActionsResponse,
  ListUpgradeTemplateStageActionsParams,
  CreateUpgradeTemplateStageActionRequest,
  ListUpgradeTemplateStageStepsResponse,
  ListUpgradeTemplateStageStepsParams,
  CreateUpgradeTemplateStageStepRequest,
  UpdateUpgradeTemplateStageStepRequest,
  ListUpgradeTemplateStageStepCommentsResponse,
  ListUpgradeTemplateStageStepCommentsParams,
  CreateUpgradeTemplateStageStepCommentRequest,
  ListUpgradeTemplateStageStepActionsResponse,
  ListUpgradeTemplateStageStepActionsParams,
  CreateUpgradeTemplateStageStepActionRequest,
} from './models';
import { customInstance } from './axios-instance';
import type { ErrorType, BodyType } from './axios-instance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * Retrieve all the Cloud Connections associated with a Chkk account
 */
export const listCloudConnections = (
  params?: ListCloudConnectionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListCloudConnectionsResponse>(
    { url: `/connection/clouds`, method: 'get', params },
    options,
  );
};

export const getListCloudConnectionsKey = (
  params?: ListCloudConnectionsParams,
) => [`/connection/clouds`, ...(params ? [params] : [])];

export type ListCloudConnectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCloudConnections>>
>;
export type ListCloudConnectionsQueryError = ErrorType<unknown>;

export const useListCloudConnections = <TError = ErrorType<unknown>>(
  params?: ListCloudConnectionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listCloudConnections>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListCloudConnectionsKey(params) : null));
  const swrFn = () => listCloudConnections(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Establish a connection between Chkk and your Cloud
 * @summary Create a cloud connection
 */
export const createCloudConnection = (
  createCloudConnectionRequest: BodyType<CreateCloudConnectionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CloudConnection>(
    {
      url: `/connection/clouds`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createCloudConnectionRequest,
    },
    options,
  );
};

/**
 * Deletes the specified Cloud Connection.
 * @summary Delete a Cloud Connection
 */
export const deleteCloudConnection = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/connection/clouds/${id}`, method: 'delete' },
    options,
  );
};

/**
 * Returns information about the specified Cloud Connection.
 * @summary Get information about a Cloud Connection
 */
export const getCloudConnection = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CloudConnection>(
    { url: `/connection/clouds/${id}`, method: 'get' },
    options,
  );
};

export const getGetCloudConnectionKey = (id: string) => [
  `/connection/clouds/${id}`,
];

export type GetCloudConnectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCloudConnection>>
>;
export type GetCloudConnectionQueryError = ErrorType<void>;

export const useGetCloudConnection = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getCloudConnection>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetCloudConnectionKey(id) : null));
  const swrFn = () => getCloudConnection(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Updates the specified Cloud Connection.
 * @summary Update a Cloud Connection
 */
export const updateCloudConnection = (
  id: string,
  updateCloudConnectionRequest: BodyType<UpdateCloudConnectionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CloudConnection>(
    {
      url: `/connection/clouds/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateCloudConnectionRequest,
    },
    options,
  );
};

/**
 * Trigger an action on the cloud connection object. Action could be to verify the connection
 * @summary Trigger an action on the object
 */
export const createCloudConnectionAction = (
  id: string,
  createCloudConnectionActionRequest: BodyType<CreateCloudConnectionActionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CloudConnectionAction>(
    {
      url: `/connection/clouds/${id}/actions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createCloudConnectionActionRequest,
    },
    options,
  );
};

/**
 * Accept an invitation into a Chkk team. This does not require further authentication, as it only executes prior-created invitations.
 */
export const acceptInvitation = (
  inviteKey: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/accept/${inviteKey}`, method: 'post' },
    options,
  );
};

/**
 * Get Invitation By Key
 */
export const getInvitationByKey = (
  inviteKey: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TeamInvitation>(
    { url: `/invitations/${inviteKey}`, method: 'get' },
    options,
  );
};

export const getGetInvitationByKeyKey = (inviteKey: string) => [
  `/invitations/${inviteKey}`,
];

export type GetInvitationByKeyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvitationByKey>>
>;
export type GetInvitationByKeyQueryError = ErrorType<unknown>;

export const useGetInvitationByKey = <TError = ErrorType<unknown>>(
  inviteKey: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getInvitationByKey>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!inviteKey;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetInvitationByKeyKey(inviteKey) : null));
  const swrFn = () => getInvitationByKey(inviteKey, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const getAccountStats = (
  accountSlug: string,
  params?: GetAccountStatsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AccountStats>(
    { url: `/accounts/${accountSlug}/stats`, method: 'get', params },
    options,
  );
};

export const getGetAccountStatsKey = (
  accountSlug: string,
  params?: GetAccountStatsParams,
) => [`/accounts/${accountSlug}/stats`, ...(params ? [params] : [])];

export type GetAccountStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountStats>>
>;
export type GetAccountStatsQueryError = ErrorType<unknown>;

export const useGetAccountStats = <TError = ErrorType<unknown>>(
  accountSlug: string,
  params?: GetAccountStatsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAccountStats>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountSlug;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAccountStatsKey(accountSlug, params) : null));
  const swrFn = () => getAccountStats(accountSlug, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List all teams in the given Chkk account
 */
export const listAccountTeams = (
  accountSlug: string,
  params?: ListAccountTeamsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListAccountTeamsResponse>(
    { url: `/accounts/${accountSlug}/teams`, method: 'get', params },
    options,
  );
};

export const getListAccountTeamsKey = (
  accountSlug: string,
  params?: ListAccountTeamsParams,
) => [`/accounts/${accountSlug}/teams`, ...(params ? [params] : [])];

export type ListAccountTeamsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAccountTeams>>
>;
export type ListAccountTeamsQueryError = ErrorType<unknown>;

export const useListAccountTeams = <TError = ErrorType<unknown>>(
  accountSlug: string,
  params?: ListAccountTeamsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAccountTeams>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountSlug;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListAccountTeamsKey(accountSlug, params) : null));
  const swrFn = () => listAccountTeams(accountSlug, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get the details for a specific Chkk team in the Chkk account
 */
export const getAccountTeam = (
  accountSlug: string,
  teamSlug: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Team>(
    { url: `/accounts/${accountSlug}/teams/${teamSlug}`, method: 'get' },
    options,
  );
};

export const getGetAccountTeamKey = (accountSlug: string, teamSlug: string) => [
  `/accounts/${accountSlug}/teams/${teamSlug}`,
];

export type GetAccountTeamQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountTeam>>
>;
export type GetAccountTeamQueryError = ErrorType<unknown>;

export const useGetAccountTeam = <TError = ErrorType<unknown>>(
  accountSlug: string,
  teamSlug: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAccountTeam>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(accountSlug && teamSlug);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAccountTeamKey(accountSlug, teamSlug) : null));
  const swrFn = () => getAccountTeam(accountSlug, teamSlug, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List the pending invitations to the Chkk team.
 */
export const listTeamInvitations = (
  accountSlug: string,
  teamSlug: string,
  params?: ListTeamInvitationsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListTeamInvitationsResponse>(
    {
      url: `/accounts/${accountSlug}/teams/${teamSlug}/invitations`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListTeamInvitationsKey = (
  accountSlug: string,
  teamSlug: string,
  params?: ListTeamInvitationsParams,
) => [
  `/accounts/${accountSlug}/teams/${teamSlug}/invitations`,
  ...(params ? [params] : []),
];

export type ListTeamInvitationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listTeamInvitations>>
>;
export type ListTeamInvitationsQueryError = ErrorType<unknown>;

export const useListTeamInvitations = <TError = ErrorType<unknown>>(
  accountSlug: string,
  teamSlug: string,
  params?: ListTeamInvitationsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listTeamInvitations>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(accountSlug && teamSlug);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListTeamInvitationsKey(accountSlug, teamSlug, params)
        : null);
  const swrFn = () =>
    listTeamInvitations(accountSlug, teamSlug, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Add a new member to an existing Chkk Team. This creates an invitation as well as an email notification to the person added to accept the invitation (beforehand they will not be a member)
 */
export const addTeamMember = (
  accountSlug: string,
  teamSlug: string,
  addTeamMemberRequest: BodyType<AddTeamMemberRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/accounts/${accountSlug}/teams/${teamSlug}/membership`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addTeamMemberRequest,
    },
    options,
  );
};

/**
 * Remove a user from a Chkk team. The user won't be notified about this change.
 */
export const deleteTeamMember = (
  accountSlug: string,
  teamSlug: string,
  userId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/accounts/${accountSlug}/teams/${teamSlug}/membership/${userId}`,
      method: 'delete',
    },
    options,
  );
};

/**
 * Delete SSO Configuration for an Account
 * @summary Delete SSO Configuration for an Account
 */
export const deleteAccountSSOConfiguration = (
  accountSlug: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/accounts/${accountSlug}/sso/configuration`, method: 'delete' },
    options,
  );
};

/**
 * Returns SSO Configuration for an Account
 * @summary Get SSO Configuration for the Account
 */
export const getAccountSSOConfiguration = (
  accountSlug: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SSOConfiguration>(
    { url: `/accounts/${accountSlug}/sso/configuration`, method: 'get' },
    options,
  );
};

export const getGetAccountSSOConfigurationKey = (accountSlug: string) => [
  `/accounts/${accountSlug}/sso/configuration`,
];

export type GetAccountSSOConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountSSOConfiguration>>
>;
export type GetAccountSSOConfigurationQueryError = ErrorType<void>;

export const useGetAccountSSOConfiguration = <TError = ErrorType<void>>(
  accountSlug: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAccountSSOConfiguration>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!accountSlug;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAccountSSOConfigurationKey(accountSlug) : null));
  const swrFn = () => getAccountSSOConfiguration(accountSlug, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create SSO Configuration for an Account
 * @summary Create SSO Configuration for an Account
 */
export const createAccountSSOConfiguration = (
  accountSlug: string,
  createSSOConfigurationRequest: BodyType<CreateSSOConfigurationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SSOConfiguration>(
    {
      url: `/accounts/${accountSlug}/sso/configuration`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createSSOConfigurationRequest,
    },
    options,
  );
};

/**
 * Update SSO Configuration for an Account
 * @summary Update SSO Configuration for an Account
 */
export const updateAccountSSOConfiguration = (
  accountSlug: string,
  updateSSOConfigurationRequest: BodyType<UpdateSSOConfigurationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SSOConfiguration>(
    {
      url: `/accounts/${accountSlug}/sso/configuration`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateSSOConfigurationRequest,
    },
    options,
  );
};

/**
 * List all addons in the Chkk account
 */
export const listAccountAddons = (
  params?: ListAccountAddonsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListAccountAddonsResponse>(
    { url: `/addons`, method: 'get', params },
    options,
  );
};

export const getListAccountAddonsKey = (params?: ListAccountAddonsParams) => [
  `/addons`,
  ...(params ? [params] : []),
];

export type ListAccountAddonsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAccountAddons>>
>;
export type ListAccountAddonsQueryError = ErrorType<unknown>;

export const useListAccountAddons = <TError = ErrorType<unknown>>(
  params?: ListAccountAddonsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAccountAddons>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListAccountAddonsKey(params) : null));
  const swrFn = () => listAccountAddons(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Endpoint for the Amplitude tunnel used by the web apps to report analytics.
 */
export const proxyAmplitudeEvent = (
  amplitudeAnalyticsReport: BodyType<AmplitudeAnalyticsReport>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ProxyAmplitudeEvent200>(
    {
      url: `/chkk-analytics`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: amplitudeAnalyticsReport,
    },
    options,
  );
};

/**
 * ???
 */
export const submitClusterContext = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    { url: `/cluster-contexts`, method: 'post' },
    options,
  );
};

/**
 * Returns a list of k8s clusters in the account
 */
export const listClusters = (
  params?: ListClustersParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CompoundListClustersResponse>(
    { url: `/clusters`, method: 'get', params },
    options,
  );
};

export const getListClustersKey = (params?: ListClustersParams) => [
  `/clusters`,
  ...(params ? [params] : []),
];

export type ListClustersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClusters>>
>;
export type ListClustersQueryError = ErrorType<unknown>;

export const useListClusters = <TError = ErrorType<unknown>>(
  params?: ListClustersParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listClusters>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListClustersKey(params) : null));
  const swrFn = () => listClusters(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const createCluster = (
  createClusterRequest: BodyType<CreateClusterRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cluster>(
    {
      url: `/clusters`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createClusterRequest,
    },
    options,
  );
};

/**
 * ???
 */
export const deleteCluster = (
  clusterId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/clusters/${clusterId}`, method: 'delete' },
    options,
  );
};

/**
 * ???
 */
export const getCluster = (
  clusterId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cluster>(
    { url: `/clusters/${clusterId}`, method: 'get' },
    options,
  );
};

export const getGetClusterKey = (clusterId: string) => [
  `/clusters/${clusterId}`,
];

export type GetClusterQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCluster>>
>;
export type GetClusterQueryError = ErrorType<unknown>;

export const useGetCluster = <TError = ErrorType<unknown>>(
  clusterId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getCluster>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!clusterId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetClusterKey(clusterId) : null));
  const swrFn = () => getCluster(clusterId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const updateCluster = (
  clusterId: string,
  updateClusterRequest: BodyType<UpdateClusterRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cluster>(
    {
      url: `/clusters/${clusterId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateClusterRequest,
    },
    options,
  );
};

/**
 * ???
 */
export const listClusterScansWithArsigs = (
  clusterId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListClusterScansResponse>(
    { url: `/clusters/${clusterId}/arsigs`, method: 'get' },
    options,
  );
};

export const getListClusterScansWithArsigsKey = (clusterId: string) => [
  `/clusters/${clusterId}/arsigs`,
];

export type ListClusterScansWithArsigsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClusterScansWithArsigs>>
>;
export type ListClusterScansWithArsigsQueryError = ErrorType<unknown>;

export const useListClusterScansWithArsigs = <TError = ErrorType<unknown>>(
  clusterId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScansWithArsigs>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!clusterId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListClusterScansWithArsigsKey(clusterId) : null));
  const swrFn = () => listClusterScansWithArsigs(clusterId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const listClusterScansWithLars = (
  clusterId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListClusterScansResponse>(
    { url: `/clusters/${clusterId}/lars`, method: 'get' },
    options,
  );
};

export const getListClusterScansWithLarsKey = (clusterId: string) => [
  `/clusters/${clusterId}/lars`,
];

export type ListClusterScansWithLarsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClusterScansWithLars>>
>;
export type ListClusterScansWithLarsQueryError = ErrorType<unknown>;

export const useListClusterScansWithLars = <TError = ErrorType<unknown>>(
  clusterId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScansWithLars>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!clusterId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListClusterScansWithLarsKey(clusterId) : null));
  const swrFn = () => listClusterScansWithLars(clusterId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List the status of all LARs for a cluster
 */
export const listLARsStatus = (
  clusterId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListLARsStatusResponse>(
    { url: `/clusters/${clusterId}/lars/status`, method: 'get' },
    options,
  );
};

export const getListLARsStatusKey = (clusterId: string) => [
  `/clusters/${clusterId}/lars/status`,
];

export type ListLARsStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof listLARsStatus>>
>;
export type ListLARsStatusQueryError = ErrorType<unknown>;

export const useListLARsStatus = <TError = ErrorType<unknown>>(
  clusterId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listLARsStatus>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!clusterId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListLARsStatusKey(clusterId) : null));
  const swrFn = () => listLARsStatus(clusterId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get the status of a specific LAR
 */
export const getLARStatus = (
  clusterId: string,
  larId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LARStatus>(
    { url: `/clusters/${clusterId}/lars/status/${larId}`, method: 'get' },
    options,
  );
};

export const getGetLARStatusKey = (clusterId: string, larId: string) => [
  `/clusters/${clusterId}/lars/status/${larId}`,
];

export type GetLARStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLARStatus>>
>;
export type GetLARStatusQueryError = ErrorType<unknown>;

export const useGetLARStatus = <TError = ErrorType<unknown>>(
  clusterId: string,
  larId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getLARStatus>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(clusterId && larId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetLARStatusKey(clusterId, larId) : null));
  const swrFn = () => getLARStatus(clusterId, larId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update/Create LAR status
 */
export const updateLARStatus = (
  clusterId: string,
  larId: string,
  updateLARStatusRequest: BodyType<UpdateLARStatusRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LARStatus>(
    {
      url: `/clusters/${clusterId}/lars/status/${larId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateLARStatusRequest,
    },
    options,
  );
};

/**
 * Ignore a specific LAR
 */
export const ignoreLAR = (
  clusterId: string,
  larId: string,
  ignoreLARRequest: BodyType<IgnoreLARRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LARStatus>(
    {
      url: `/clusters/${clusterId}/lars/status/${larId}/ignore`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: ignoreLARRequest,
    },
    options,
  );
};

/**
 * Acknowledge a specific LAR
 */
export const acknowledgeLAR = (
  clusterId: string,
  larId: string,
  acknowledgeLARRequest: BodyType<AcknowledgeLARRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LARStatus>(
    {
      url: `/clusters/${clusterId}/lars/status/${larId}/acknowledge`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: acknowledgeLARRequest,
    },
    options,
  );
};

/**
 * List the scans of this cluster
 */
export const listClusterScans = (
  clusterId: string,
  params?: ListClusterScansParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListClusterScansResponseV2>(
    { url: `/clusters/${clusterId}/scans`, method: 'get', params },
    options,
  );
};

export const getListClusterScansKey = (
  clusterId: string,
  params?: ListClusterScansParams,
) => [`/clusters/${clusterId}/scans`, ...(params ? [params] : [])];

export type ListClusterScansQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClusterScans>>
>;
export type ListClusterScansQueryError = ErrorType<unknown>;

export const useListClusterScans = <TError = ErrorType<unknown>>(
  clusterId: string,
  params?: ListClusterScansParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScans>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!clusterId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListClusterScansKey(clusterId, params) : null));
  const swrFn = () => listClusterScans(clusterId, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Return details about a specific scan of this cluster
 */
export const getClusterScan = (
  clusterId: string,
  scanId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ClusterScan>(
    { url: `/clusters/${clusterId}/scans/${scanId}`, method: 'get' },
    options,
  );
};

export const getGetClusterScanKey = (clusterId: string, scanId: string) => [
  `/clusters/${clusterId}/scans/${scanId}`,
];

export type GetClusterScanQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClusterScan>>
>;
export type GetClusterScanQueryError = ErrorType<unknown>;

export const useGetClusterScan = <TError = ErrorType<unknown>>(
  clusterId: string,
  scanId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getClusterScan>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(clusterId && scanId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetClusterScanKey(clusterId, scanId) : null));
  const swrFn = () => getClusterScan(clusterId, scanId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List the signatures of this scan
 */
export const listClusterScanSignatures = (
  clusterId: string,
  scanId: string,
  params?: ListClusterScanSignaturesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListClusterScanSignaturesResponse>(
    {
      url: `/clusters/${clusterId}/scans/${scanId}/signatures`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListClusterScanSignaturesKey = (
  clusterId: string,
  scanId: string,
  params?: ListClusterScanSignaturesParams,
) => [
  `/clusters/${clusterId}/scans/${scanId}/signatures`,
  ...(params ? [params] : []),
];

export type ListClusterScanSignaturesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClusterScanSignatures>>
>;
export type ListClusterScanSignaturesQueryError = ErrorType<unknown>;

export const useListClusterScanSignatures = <TError = ErrorType<unknown>>(
  clusterId: string,
  scanId: string,
  params?: ListClusterScanSignaturesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScanSignatures>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(clusterId && scanId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListClusterScanSignaturesKey(clusterId, scanId, params)
        : null);
  const swrFn = () =>
    listClusterScanSignatures(clusterId, scanId, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List the LARs of this scan
 */
export const listClusterScanLARs = (
  clusterId: string,
  scanId: string,
  params?: ListClusterScanLARsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListClusterScanLARsResponse>(
    {
      url: `/clusters/${clusterId}/scans/${scanId}/lars`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListClusterScanLARsKey = (
  clusterId: string,
  scanId: string,
  params?: ListClusterScanLARsParams,
) => [
  `/clusters/${clusterId}/scans/${scanId}/lars`,
  ...(params ? [params] : []),
];

export type ListClusterScanLARsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClusterScanLARs>>
>;
export type ListClusterScanLARsQueryError = ErrorType<unknown>;

export const useListClusterScanLARs = <TError = ErrorType<unknown>>(
  clusterId: string,
  scanId: string,
  params?: ListClusterScanLARsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScanLARs>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(clusterId && scanId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getListClusterScanLARsKey(clusterId, scanId, params) : null);
  const swrFn = () =>
    listClusterScanLARs(clusterId, scanId, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Return details about a specific LAR of this scan
 */
export const getClusterScanLAR = (
  clusterId: string,
  scanId: string,
  larId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ClusterDetectedLar>(
    {
      url: `/clusters/${clusterId}/scans/${scanId}/lars/${larId}`,
      method: 'get',
    },
    options,
  );
};

export const getGetClusterScanLARKey = (
  clusterId: string,
  scanId: string,
  larId: string,
) => [`/clusters/${clusterId}/scans/${scanId}/lars/${larId}`];

export type GetClusterScanLARQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClusterScanLAR>>
>;
export type GetClusterScanLARQueryError = ErrorType<unknown>;

export const useGetClusterScanLAR = <TError = ErrorType<unknown>>(
  clusterId: string,
  scanId: string,
  larId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getClusterScanLAR>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(clusterId && scanId && larId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getGetClusterScanLARKey(clusterId, scanId, larId) : null);
  const swrFn = () =>
    getClusterScanLAR(clusterId, scanId, larId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List the resources of a specific LAR of this scan
 */
export const listClusterScanLARResources = (
  clusterId: string,
  scanId: string,
  larId: string,
  params?: ListClusterScanLARResourcesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListClusterScanLARResourcesResponse>(
    {
      url: `/clusters/${clusterId}/scans/${scanId}/lars/${larId}/resources`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListClusterScanLARResourcesKey = (
  clusterId: string,
  scanId: string,
  larId: string,
  params?: ListClusterScanLARResourcesParams,
) => [
  `/clusters/${clusterId}/scans/${scanId}/lars/${larId}/resources`,
  ...(params ? [params] : []),
];

export type ListClusterScanLARResourcesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClusterScanLARResources>>
>;
export type ListClusterScanLARResourcesQueryError = ErrorType<unknown>;

export const useListClusterScanLARResources = <TError = ErrorType<unknown>>(
  clusterId: string,
  scanId: string,
  larId: string,
  params?: ListClusterScanLARResourcesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listClusterScanLARResources>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(clusterId && scanId && larId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListClusterScanLARResourcesKey(clusterId, scanId, larId, params)
        : null);
  const swrFn = () =>
    listClusterScanLARResources(
      clusterId,
      scanId,
      larId,
      params,
      requestOptions,
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const getClusterRescanState = (
  clusterId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cluster>(
    { url: `/clusters/${clusterId}/rescan`, method: 'get' },
    options,
  );
};

export const getGetClusterRescanStateKey = (clusterId: string) => [
  `/clusters/${clusterId}/rescan`,
];

export type GetClusterRescanStateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClusterRescanState>>
>;
export type GetClusterRescanStateQueryError = ErrorType<unknown>;

export const useGetClusterRescanState = <TError = ErrorType<unknown>>(
  clusterId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getClusterRescanState>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!clusterId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetClusterRescanStateKey(clusterId) : null));
  const swrFn = () => getClusterRescanState(clusterId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const rescanCluster = (
  clusterId: string,
  rescanClusterRequest: BodyType<RescanClusterRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cluster>(
    {
      url: `/clusters/${clusterId}/rescan`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: rescanClusterRequest,
    },
    options,
  );
};

/**
 * ???
 */
export const createClusterScan = (
  clusterId: string,
  createClusterScanRequest: BodyType<CreateClusterScanRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Cluster>(
    {
      url: `/clusters/${clusterId}/scan`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createClusterScanRequest,
    },
    options,
  );
};

/**
 * Endpoint for reporting feedback about Chkk of different nature - user sign-up requests, k8s questions or general feedback
 */
export const submitFeedback = (
  submitFeedbackRequest: BodyType<SubmitFeedbackRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SubmitFeedbackResponse>(
    {
      url: `/feedbacks`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: submitFeedbackRequest,
    },
    options,
  );
};

/**
 * List the integrations configured in the Chkk account
 */
export const listIntegrations = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListIntegrationsResponse>(
    { url: `/integrations`, method: 'get' },
    options,
  );
};

export const getListIntegrationsKey = () => [`/integrations`];

export type ListIntegrationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listIntegrations>>
>;
export type ListIntegrationsQueryError = ErrorType<unknown>;

export const useListIntegrations = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof listIntegrations>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListIntegrationsKey() : null));
  const swrFn = () => listIntegrations(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create a new integration in the Chkk account
 */
export const createIntegration = (
  createIntegrationRequest: BodyType<CreateIntegrationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Integration>(
    {
      url: `/integrations`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createIntegrationRequest,
    },
    options,
  );
};

/**
 * ???
 */
export const getPrometheusMetricsScrape = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    { url: `/integrations/prometheus/metrics`, method: 'get' },
    options,
  );
};

export const getGetPrometheusMetricsScrapeKey = () => [
  `/integrations/prometheus/metrics`,
];

export type GetPrometheusMetricsScrapeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPrometheusMetricsScrape>>
>;
export type GetPrometheusMetricsScrapeQueryError = ErrorType<unknown>;

export const useGetPrometheusMetricsScrape = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getPrometheusMetricsScrape>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPrometheusMetricsScrapeKey() : null));
  const swrFn = () => getPrometheusMetricsScrape(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const getPrometheusStatus = (
  params: GetPrometheusStatusParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    { url: `/integrations/prometheus/status`, method: 'get', params },
    options,
  );
};

export const getGetPrometheusStatusKey = (
  params: GetPrometheusStatusParams,
) => [`/integrations/prometheus/status`, ...(params ? [params] : [])];

export type GetPrometheusStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPrometheusStatus>>
>;
export type GetPrometheusStatusQueryError = ErrorType<unknown>;

export const useGetPrometheusStatus = <TError = ErrorType<unknown>>(
  params: GetPrometheusStatusParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getPrometheusStatus>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPrometheusStatusKey(params) : null));
  const swrFn = () => getPrometheusStatus(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Delete an existing integration from the Chkk account
 */
export const deleteIntegration = (
  integrationId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/integrations/${integrationId}`, method: 'delete' },
    options,
  );
};

/**
 * Get the details of an existing integration
 */
export const getIntegration = (
  integrationId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Integration>(
    { url: `/integrations/${integrationId}`, method: 'get' },
    options,
  );
};

export const getGetIntegrationKey = (integrationId: string) => [
  `/integrations/${integrationId}`,
];

export type GetIntegrationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIntegration>>
>;
export type GetIntegrationQueryError = ErrorType<unknown>;

export const useGetIntegration = <TError = ErrorType<unknown>>(
  integrationId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getIntegration>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!integrationId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetIntegrationKey(integrationId) : null));
  const swrFn = () => getIntegration(integrationId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update an existing integration (e.g. with new status)
 */
export const updateIntegration = (
  integrationId: string,
  updateIntegrationRequest: BodyType<UpdateIntegrationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Integration>(
    {
      url: `/integrations/${integrationId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateIntegrationRequest,
    },
    options,
  );
};

/**
 * Retrieve the alert manager configuration snippet for the referenced integration
 */
export const getIntegrationAlertManagerConfig = (
  integrationId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/integrations/${integrationId}/alert_manager_config`,
      method: 'get',
    },
    options,
  );
};

export const getGetIntegrationAlertManagerConfigKey = (
  integrationId: string,
) => [`/integrations/${integrationId}/alert_manager_config`];

export type GetIntegrationAlertManagerConfigQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIntegrationAlertManagerConfig>>
>;
export type GetIntegrationAlertManagerConfigQueryError = ErrorType<unknown>;

export const useGetIntegrationAlertManagerConfig = <
  TError = ErrorType<unknown>,
>(
  integrationId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getIntegrationAlertManagerConfig>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!integrationId;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getGetIntegrationAlertManagerConfigKey(integrationId) : null);
  const swrFn = () =>
    getIntegrationAlertManagerConfig(integrationId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get ticket details
 */
export const getTicketDetails = (
  integrationId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetTicketDetailsResponse>(
    { url: `/integrations/${integrationId}/ticket_details`, method: 'get' },
    options,
  );
};

export const getGetTicketDetailsKey = (integrationId: string) => [
  `/integrations/${integrationId}/ticket_details`,
];

export type GetTicketDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTicketDetails>>
>;
export type GetTicketDetailsQueryError = ErrorType<unknown>;

export const useGetTicketDetails = <TError = ErrorType<unknown>>(
  integrationId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTicketDetails>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!integrationId;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTicketDetailsKey(integrationId) : null));
  const swrFn = () => getTicketDetails(integrationId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const getKba = (
  arsigId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    { url: `/kbas/${arsigId}`, method: 'get' },
    options,
  );
};

export const getGetKbaKey = (arsigId: string) => [`/kbas/${arsigId}`];

export type GetKbaQueryResult = NonNullable<Awaited<ReturnType<typeof getKba>>>;
export type GetKbaQueryError = ErrorType<unknown>;

export const useGetKba = <TError = ErrorType<unknown>>(
  arsigId: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getKba>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!arsigId;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetKbaKey(arsigId) : null));
  const swrFn = () => getKba(arsigId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 */
export const getLarOverview = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LarsOverviewResponse>(
    { url: `/larsoverview`, method: 'get' },
    options,
  );
};

export const getGetLarOverviewKey = () => [`/larsoverview`];

export type GetLarOverviewQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLarOverview>>
>;
export type GetLarOverviewQueryError = ErrorType<unknown>;

export const useGetLarOverview = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof getLarOverview>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetLarOverviewKey() : null));
  const swrFn = () => getLarOverview(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Login the provided user, and make sure their core data structures are ready for use (e.g. that they have an account setup for them)
 */
export const login = (
  params?: LoginParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LoginResponse>(
    { url: `/login`, method: 'post', params },
    options,
  );
};

/**
 * Create a new organization with the calling user as sole member
 */
export const createOrganization = (
  createOrganizationRequest: BodyType<CreateOrganizationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Organization>(
    {
      url: `/orgs`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createOrganizationRequest,
    },
    options,
  );
};

/**
 * Get the details for a specific organization
 */
export const getOrganization = (
  orgSlug: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Organization>(
    { url: `/orgs/${orgSlug}`, method: 'get' },
    options,
  );
};

export const getGetOrganizationKey = (orgSlug: string) => [`/orgs/${orgSlug}`];

export type GetOrganizationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrganization>>
>;
export type GetOrganizationQueryError = ErrorType<unknown>;

export const useGetOrganization = <TError = ErrorType<unknown>>(
  orgSlug: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getOrganization>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!orgSlug;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetOrganizationKey(orgSlug) : null));
  const swrFn = () => getOrganization(orgSlug, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update the details of a specific organization (e.g. the displayed name)
 */
export const updateOrganization = (
  orgSlug: string,
  updateOrganizationRequest: BodyType<UpdateOrganizationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Organization>(
    {
      url: `/orgs/${orgSlug}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateOrganizationRequest,
    },
    options,
  );
};

/**
 * Retrieve all the subscriptions associated with a Chkk organization
 */
export const listSubscriptions = (
  orgSlug: string,
  params?: ListSubscriptionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListSubscriptionsResponse>(
    { url: `/orgs/${orgSlug}/subscriptions`, method: 'get', params },
    options,
  );
};

export const getListSubscriptionsKey = (
  orgSlug: string,
  params?: ListSubscriptionsParams,
) => [`/orgs/${orgSlug}/subscriptions`, ...(params ? [params] : [])];

export type ListSubscriptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listSubscriptions>>
>;
export type ListSubscriptionsQueryError = ErrorType<unknown>;

export const useListSubscriptions = <TError = ErrorType<unknown>>(
  orgSlug: string,
  params?: ListSubscriptionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listSubscriptions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!orgSlug;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListSubscriptionsKey(orgSlug, params) : null));
  const swrFn = () => listSubscriptions(orgSlug, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Add a new subscription to the account
 */
export const createSubscription = (
  orgSlug: string,
  createSubscriptionRequest: BodyType<CreateSubscriptionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CreateSubscriptionResponse>(
    {
      url: `/orgs/${orgSlug}/subscriptions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createSubscriptionRequest,
    },
    options,
  );
};

/**
 * Retrieve the details of a specific subscription
 */
export const getSubscription = (
  orgSlug: string,
  subscriptionId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ResolvedSubscription>(
    { url: `/orgs/${orgSlug}/subscriptions/${subscriptionId}`, method: 'get' },
    options,
  );
};

export const getGetSubscriptionKey = (
  orgSlug: string,
  subscriptionId: string,
) => [`/orgs/${orgSlug}/subscriptions/${subscriptionId}`];

export type GetSubscriptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscription>>
>;
export type GetSubscriptionQueryError = ErrorType<unknown>;

export const useGetSubscription = <TError = ErrorType<unknown>>(
  orgSlug: string,
  subscriptionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getSubscription>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(orgSlug && subscriptionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetSubscriptionKey(orgSlug, subscriptionId) : null));
  const swrFn = () => getSubscription(orgSlug, subscriptionId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update the properties of a specific subscription
 */
export const updateSubscription = (
  orgSlug: string,
  subscriptionId: string,
  updateSubscriptionRequest: BodyType<UpdateSubscriptionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/orgs/${orgSlug}/subscriptions/${subscriptionId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateSubscriptionRequest,
    },
    options,
  );
};

/**
 * List the access tokens for the different accounts in the Chkk organization (for the calling user)
 */
export const listAccessTokens = (
  orgSlug: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListAccessTokensResponse>(
    { url: `/orgs/${orgSlug}/tokens`, method: 'get' },
    options,
  );
};

export const getListAccessTokensKey = (orgSlug: string) => [
  `/orgs/${orgSlug}/tokens`,
];

export type ListAccessTokensQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAccessTokens>>
>;
export type ListAccessTokensQueryError = ErrorType<unknown>;

export const useListAccessTokens = <TError = ErrorType<unknown>>(
  orgSlug: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAccessTokens>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!orgSlug;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListAccessTokensKey(orgSlug) : null));
  const swrFn = () => listAccessTokens(orgSlug, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Get the ingestion token for the account
 * @deprecated
 */
export const getIngestionToken = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetIngestionTokenResponse>(
    { url: `/ingestion_scopes/${id}/token`, method: 'get' },
    options,
  );
};

export const getGetIngestionTokenKey = (id: string) => [
  `/ingestion_scopes/${id}/token`,
];

export type GetIngestionTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIngestionToken>>
>;
export type GetIngestionTokenQueryError = ErrorType<unknown>;

export const useGetIngestionToken = <TError = ErrorType<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getIngestionToken>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetIngestionTokenKey(id) : null));
  const swrFn = () => getIngestionToken(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List the ingestion token for the account
 */
export const listIngestionTokens = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListIngestionTokensResponse>(
    { url: `/ingestion_scopes/${id}/tokens`, method: 'get' },
    options,
  );
};

export const getListIngestionTokensKey = (id: string) => [
  `/ingestion_scopes/${id}/tokens`,
];

export type ListIngestionTokensQueryResult = NonNullable<
  Awaited<ReturnType<typeof listIngestionTokens>>
>;
export type ListIngestionTokensQueryError = ErrorType<unknown>;

export const useListIngestionTokens = <TError = ErrorType<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listIngestionTokens>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListIngestionTokensKey(id) : null));
  const swrFn = () => listIngestionTokens(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create an ingestion token for the account
 */
export const createIngestionToken = (
  id: string,
  createIngestionTokenRequest: BodyType<CreateIngestionTokenRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<IngestionToken>(
    {
      url: `/ingestion_scopes/${id}/tokens`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createIngestionTokenRequest,
    },
    options,
  );
};

/**
 * Delete the ingestion token
 */
export const deleteIngestionToken = (
  id: string,
  tokenId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/ingestion_scopes/${id}/tokens/${tokenId}`, method: 'delete' },
    options,
  );
};

/**
 * Update the details of an Ingestion Token (e.g. change name, revoke token, etc.)
 */
export const updateIngestionToken = (
  id: string,
  tokenId: string,
  updateIngestionTokenRequest: BodyType<UpdateIngestionTokenRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<IngestionToken>(
    {
      url: `/ingestion_scopes/${id}/tokens/${tokenId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateIngestionTokenRequest,
    },
    options,
  );
};

/**
 * ???
 */
export const getQuickStartState = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<QuickStartResponse>(
    { url: `/quick-start`, method: 'get' },
    options,
  );
};

export const getGetQuickStartStateKey = () => [`/quick-start`];

export type GetQuickStartStateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getQuickStartState>>
>;
export type GetQuickStartStateQueryError = ErrorType<unknown>;

export const useGetQuickStartState = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getQuickStartState>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetQuickStartStateKey() : null));
  const swrFn = () => getQuickStartState(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 * @deprecated
 */
export const listScans = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<ListClusterScansResponse>(
    { url: `/scans`, method: 'get' },
    options,
  );
};

export const getListScansKey = () => [`/scans`];

export type ListScansQueryResult = NonNullable<
  Awaited<ReturnType<typeof listScans>>
>;
export type ListScansQueryError = ErrorType<unknown>;

export const useListScans = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof listScans>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListScansKey() : null));
  const swrFn = () => listScans(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * ???
 * @deprecated
 */
export const createScan = (
  createScanRequest: BodyType<CreateScanRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CreateScanResponse>(
    {
      url: `/scans`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createScanRequest,
    },
    options,
  );
};

/**
 * Endpoint for the Sentry tunnel used by the web apps to report issues.

See https://docs.sentry.io/platforms/javascript/troubleshooting/#using-the-tunnel-option for more details.

The requests are in POST form, and consist of 3 lines with the first being a form of header containing the DSN of the report. The other lines report metadata as well as the stacktrace/breadcrumbs.

 */
export const proxySentryReport = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    { url: `/sentry-proxy`, method: 'post' },
    options,
  );
};

/**
 * ???
 */
export const createTicket = (
  createTicketRequest: BodyType<CreateTicketRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<unknown>(
    {
      url: `/tickets`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createTicketRequest,
    },
    options,
  );
};

/**
 * Submit status notification for an agent
 */
export const submitAgentStatusNotification = (
  submitAgentStatusNotificationRequest: BodyType<SubmitAgentStatusNotificationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/notifications/agent-status`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: submitAgentStatusNotificationRequest,
    },
    options,
  );
};

/**
 * Submit an install notification for an agent
 */
export const submitAgentInstallNotification = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/notifications/agent-install`, method: 'post' },
    options,
  );
};

/**
 * Get details of the calling user
 */
export const getUsers = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetUsersResponse>(
    { url: `/users`, method: 'get' },
    options,
  );
};

export const getGetUsersKey = () => [`/users`];

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersQueryError = ErrorType<unknown>;

export const useGetUsers = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof getUsers>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetUsersKey() : null));
  const swrFn = () => getUsers(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List addons known to Chkk
 */
export const listDirectoryAddons = (
  params?: ListDirectoryAddonsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListDirectoryAddonsResponse>(
    { url: `/directory/addons`, method: 'get', params },
    options,
  );
};

export const getListDirectoryAddonsKey = (
  params?: ListDirectoryAddonsParams,
) => [`/directory/addons`, ...(params ? [params] : [])];

export type ListDirectoryAddonsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listDirectoryAddons>>
>;
export type ListDirectoryAddonsQueryError = ErrorType<unknown>;

export const useListDirectoryAddons = <TError = ErrorType<unknown>>(
  params?: ListDirectoryAddonsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listDirectoryAddons>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListDirectoryAddonsKey(params) : null));
  const swrFn = () => listDirectoryAddons(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List instances of addons found in clusters in the account
 */
export const listAddonInstances = (
  params?: ListAddonInstancesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListAddonInstancesResponse>(
    { url: `/addon_instances`, method: 'get', params },
    options,
  );
};

export const getListAddonInstancesKey = (params?: ListAddonInstancesParams) => [
  `/addon_instances`,
  ...(params ? [params] : []),
];

export type ListAddonInstancesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAddonInstances>>
>;
export type ListAddonInstancesQueryError = ErrorType<unknown>;

export const useListAddonInstances = <TError = ErrorType<unknown>>(
  params?: ListAddonInstancesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAddonInstances>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListAddonInstancesKey(params) : null));
  const swrFn = () => listAddonInstances(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve details about a specific addon instance
 */
export const getAddonInstance = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AddonInstance>(
    { url: `/addon_instances/${id}`, method: 'get' },
    options,
  );
};

export const getGetAddonInstanceKey = (id: string) => [
  `/addon_instances/${id}`,
];

export type GetAddonInstanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAddonInstance>>
>;
export type GetAddonInstanceQueryError = ErrorType<unknown>;

export const useGetAddonInstance = <TError = ErrorType<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAddonInstance>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAddonInstanceKey(id) : null));
  const swrFn = () => getAddonInstance(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve the resources of a specific addon instance
 */
export const listAddonInstanceResources = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListAddonInstanceResourcesResponse>(
    { url: `/addon_instances/${id}/resources`, method: 'get' },
    options,
  );
};

export const getListAddonInstanceResourcesKey = (id: string) => [
  `/addon_instances/${id}/resources`,
];

export type ListAddonInstanceResourcesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAddonInstanceResources>>
>;
export type ListAddonInstanceResourcesQueryError = ErrorType<unknown>;

export const useListAddonInstanceResources = <TError = ErrorType<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAddonInstanceResources>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListAddonInstanceResourcesKey(id) : null));
  const swrFn = () => listAddonInstanceResources(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List the AvailabilityRisks in the account, filtered by the query parameters
 */
export const listAvailabilityRisks = (
  params?: ListAvailabilityRisksParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListAvailabilityRisksResponse>(
    { url: `/availability_risks`, method: 'get', params },
    options,
  );
};

export const getListAvailabilityRisksKey = (
  params?: ListAvailabilityRisksParams,
) => [`/availability_risks`, ...(params ? [params] : [])];

export type ListAvailabilityRisksQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAvailabilityRisks>>
>;
export type ListAvailabilityRisksQueryError = ErrorType<unknown>;

export const useListAvailabilityRisks = <TError = ErrorType<unknown>>(
  params?: ListAvailabilityRisksParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listAvailabilityRisks>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListAvailabilityRisksKey(params) : null));
  const swrFn = () => listAvailabilityRisks(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Return aggregate counts for the AvailabilityRisks in the account, filtered and grouped by the query parameters
 */
export const getAvailabilityRisksSummary = (
  params: GetAvailabilityRisksSummaryParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<AvailabilityRisksSummary>(
    { url: `/availability_risks/summary`, method: 'get', params },
    options,
  );
};

export const getGetAvailabilityRisksSummaryKey = (
  params: GetAvailabilityRisksSummaryParams,
) => [`/availability_risks/summary`, ...(params ? [params] : [])];

export type GetAvailabilityRisksSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailabilityRisksSummary>>
>;
export type GetAvailabilityRisksSummaryQueryError = ErrorType<unknown>;

export const useGetAvailabilityRisksSummary = <TError = ErrorType<unknown>>(
  params: GetAvailabilityRisksSummaryParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAvailabilityRisksSummary>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAvailabilityRisksSummaryKey(params) : null));
  const swrFn = () => getAvailabilityRisksSummary(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * List the resourcs in this account affected by the AvailabilityRisk
 */
export const getAvailabilityRiskAffectedResource = (
  availabilityRiskId: string,
  params?: GetAvailabilityRiskAffectedResourceParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListAffectedResourcesResponse>(
    {
      url: `/availability_risks/${availabilityRiskId}/affected_resources`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getGetAvailabilityRiskAffectedResourceKey = (
  availabilityRiskId: string,
  params?: GetAvailabilityRiskAffectedResourceParams,
) => [
  `/availability_risks/${availabilityRiskId}/affected_resources`,
  ...(params ? [params] : []),
];

export type GetAvailabilityRiskAffectedResourceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailabilityRiskAffectedResource>>
>;
export type GetAvailabilityRiskAffectedResourceQueryError = ErrorType<unknown>;

export const useGetAvailabilityRiskAffectedResource = <
  TError = ErrorType<unknown>,
>(
  availabilityRiskId: string,
  params?: GetAvailabilityRiskAffectedResourceParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAvailabilityRiskAffectedResource>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!availabilityRiskId;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetAvailabilityRiskAffectedResourceKey(availabilityRiskId, params)
        : null);
  const swrFn = () =>
    getAvailabilityRiskAffectedResource(
      availabilityRiskId,
      params,
      requestOptions,
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all conversations for the account
 */
export const listConversations = (
  params?: ListConversationsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListConversationsResponse>(
    { url: `/conversations`, method: 'get', params },
    options,
  );
};

export const getListConversationsKey = (params?: ListConversationsParams) => [
  `/conversations`,
  ...(params ? [params] : []),
];

export type ListConversationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listConversations>>
>;
export type ListConversationsQueryError = ErrorType<unknown>;

export const useListConversations = <TError = ErrorType<unknown>>(
  params?: ListConversationsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listConversations>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListConversationsKey(params) : null));
  const swrFn = () => listConversations(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create a new conversation
 */
export const createConversation = (
  createConversationRequest: BodyType<CreateConversationRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Conversation>(
    {
      url: `/conversations`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createConversationRequest,
    },
    options,
  );
};

/**
 * Retrieve the details for a specific conversation
 */
export const getConversation = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<Conversation>(
    { url: `/conversations/${id}`, method: 'get' },
    options,
  );
};

export const getGetConversationKey = (id: string) => [`/conversations/${id}`];

export type GetConversationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConversation>>
>;
export type GetConversationQueryError = ErrorType<unknown>;

export const useGetConversation = <TError = ErrorType<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getConversation>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetConversationKey(id) : null));
  const swrFn = () => getConversation(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve the message of a conversation
 */
export const listConversationMessages = (
  id: string,
  params?: ListConversationMessagesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListConversationMessagesResponse>(
    { url: `/conversations/${id}/messages`, method: 'get', params },
    options,
  );
};

export const getListConversationMessagesKey = (
  id: string,
  params?: ListConversationMessagesParams,
) => [`/conversations/${id}/messages`, ...(params ? [params] : [])];

export type ListConversationMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listConversationMessages>>
>;
export type ListConversationMessagesQueryError = ErrorType<unknown>;

export const useListConversationMessages = <TError = ErrorType<unknown>>(
  id: string,
  params?: ListConversationMessagesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listConversationMessages>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListConversationMessagesKey(id, params) : null));
  const swrFn = () => listConversationMessages(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve the details for connecting to the specific conversation
 */
export const getConversationConnectionDetails = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetConversationConnectionDetailsResponse>(
    { url: `/conversations/${id}/connection_details`, method: 'get' },
    options,
  );
};

export const getGetConversationConnectionDetailsKey = (id: string) => [
  `/conversations/${id}/connection_details`,
];

export type GetConversationConnectionDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConversationConnectionDetails>>
>;
export type GetConversationConnectionDetailsQueryError = ErrorType<unknown>;

export const useGetConversationConnectionDetails = <
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getConversationConnectionDetails>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetConversationConnectionDetailsKey(id) : null));
  const swrFn = () => getConversationConnectionDetails(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all the Upgrade Plans associated with a Chkk account
 */
export const listUpgradePlans = (
  params?: ListUpgradePlansParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradePlansResponse>(
    { url: `/upgrades`, method: 'get', params },
    options,
  );
};

export const getListUpgradePlansKey = (params?: ListUpgradePlansParams) => [
  `/upgrades`,
  ...(params ? [params] : []),
];

export type ListUpgradePlansQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradePlans>>
>;
export type ListUpgradePlansQueryError = ErrorType<unknown>;

export const useListUpgradePlans = <TError = ErrorType<unknown>>(
  params?: ListUpgradePlansParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradePlans>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradePlansKey(params) : null));
  const swrFn = () => listUpgradePlans(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create an Upgrade Plan for your resources (Clusters, Add-Ons, Applications etc)
 * @summary Create an Upgrade Plan
 */
export const createUpgradePlan = (
  createUpgradePlanRequest: BodyType<CreateUpgradePlanRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradePlan>(
    {
      url: `/upgrades`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradePlanRequest,
    },
    options,
  );
};

/**
 * Deletes the specified Upgrade Plan.
 * @summary Delete an Upgrade Plan
 */
export const deleteUpgradePlan = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/upgrades/${id}`, method: 'delete' },
    options,
  );
};

/**
 * Returns information about the specified Upgrade Plan.
 * @summary Get information about an Upgrade Plan
 */
export const getUpgradePlan = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradePlan>(
    { url: `/upgrades/${id}`, method: 'get' },
    options,
  );
};

export const getGetUpgradePlanKey = (id: string) => [`/upgrades/${id}`];

export type GetUpgradePlanQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradePlan>>
>;
export type GetUpgradePlanQueryError = ErrorType<void>;

export const useGetUpgradePlan = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradePlan>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetUpgradePlanKey(id) : null));
  const swrFn = () => getUpgradePlan(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Updates the base properties of an Upgrade Plan
 * @summary Update an Upgrade Plan
 */
export const updateUpgradePlan = (
  id: string,
  updateUpgradePlanRequest: BodyType<UpdateUpgradePlanRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradePlan>(
    {
      url: `/upgrades/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateUpgradePlanRequest,
    },
    options,
  );
};

/**
 * Returns information about the history of the specified Upgrade Plan.
 * @summary List history entries of an Upgrade Plan
 */
export const listUpgradePlanHistory = (
  id: string,
  params?: ListUpgradePlanHistoryParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradePlanHistoryResponse>(
    { url: `/upgrades/${id}/history`, method: 'get', params },
    options,
  );
};

export const getListUpgradePlanHistoryKey = (
  id: string,
  params?: ListUpgradePlanHistoryParams,
) => [`/upgrades/${id}/history`, ...(params ? [params] : [])];

export type ListUpgradePlanHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradePlanHistory>>
>;
export type ListUpgradePlanHistoryQueryError = ErrorType<void>;

export const useListUpgradePlanHistory = <TError = ErrorType<void>>(
  id: string,
  params?: ListUpgradePlanHistoryParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradePlanHistory>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradePlanHistoryKey(id, params) : null));
  const swrFn = () => listUpgradePlanHistory(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all the Stages for an Upgrade Plan
 */
export const listUpgradePlanStages = (
  id: string,
  params?: ListUpgradePlanStagesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradePlanStagesResponse>(
    { url: `/upgrades/${id}/stages`, method: 'get', params },
    options,
  );
};

export const getListUpgradePlanStagesKey = (
  id: string,
  params?: ListUpgradePlanStagesParams,
) => [`/upgrades/${id}/stages`, ...(params ? [params] : [])];

export type ListUpgradePlanStagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradePlanStages>>
>;
export type ListUpgradePlanStagesQueryError = ErrorType<unknown>;

export const useListUpgradePlanStages = <TError = ErrorType<unknown>>(
  id: string,
  params?: ListUpgradePlanStagesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradePlanStages>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradePlanStagesKey(id, params) : null));
  const swrFn = () => listUpgradePlanStages(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Returns information about the specified Stage of an Upgrade Plan
 * @summary Get information about an Stage of an Upgrade Plan
 */
export const getUpgradePlanStage = (
  id: string,
  stageNumber: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStage>(
    { url: `/upgrades/${id}/stages/${stageNumber}`, method: 'get' },
    options,
  );
};

export const getGetUpgradePlanStageKey = (id: string, stageNumber: string) => [
  `/upgrades/${id}/stages/${stageNumber}`,
];

export type GetUpgradePlanStageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradePlanStage>>
>;
export type GetUpgradePlanStageQueryError = ErrorType<void>;

export const useGetUpgradePlanStage = <TError = ErrorType<void>>(
  id: string,
  stageNumber: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradePlanStage>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(id && stageNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetUpgradePlanStageKey(id, stageNumber) : null));
  const swrFn = () => getUpgradePlanStage(id, stageNumber, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all actions performed on the stage
 * @deprecated
 */
export const listUpgradePlanStageActions = (
  id: string,
  stageNumber: string,
  params?: ListUpgradePlanStageActionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradePlanStageActionsResponse>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/actions`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListUpgradePlanStageActionsKey = (
  id: string,
  stageNumber: string,
  params?: ListUpgradePlanStageActionsParams,
) => [
  `/upgrades/${id}/stages/${stageNumber}/actions`,
  ...(params ? [params] : []),
];

export type ListUpgradePlanStageActionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradePlanStageActions>>
>;
export type ListUpgradePlanStageActionsQueryError = ErrorType<unknown>;

export const useListUpgradePlanStageActions = <TError = ErrorType<unknown>>(
  id: string,
  stageNumber: string,
  params?: ListUpgradePlanStageActionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradePlanStageActions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(id && stageNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradePlanStageActionsKey(id, stageNumber, params)
        : null);
  const swrFn = () =>
    listUpgradePlanStageActions(id, stageNumber, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Trigger an action on the object.
 * @deprecated
 * @summary Trigger an action on the object
 */
export const createUpgradePlanStageAction = (
  id: string,
  stageNumber: string,
  createUpgradePlanStageActionRequest: BodyType<CreateUpgradePlanStageActionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStage>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/actions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradePlanStageActionRequest,
    },
    options,
  );
};

/**
 * Get information of an action
 * @deprecated
 * @summary Get information of an action
 */
export const getUpgradePlanStageAction = (
  id: string,
  stageNumber: string,
  actionId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageAction>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/action/${actionId}`,
      method: 'get',
    },
    options,
  );
};

export const getGetUpgradePlanStageActionKey = (
  id: string,
  stageNumber: string,
  actionId: string,
) => [`/upgrades/${id}/stages/${stageNumber}/action/${actionId}`];

export type GetUpgradePlanStageActionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradePlanStageAction>>
>;
export type GetUpgradePlanStageActionQueryError = ErrorType<void>;

export const useGetUpgradePlanStageAction = <TError = ErrorType<void>>(
  id: string,
  stageNumber: string,
  actionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradePlanStageAction>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(id && stageNumber && actionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetUpgradePlanStageActionKey(id, stageNumber, actionId)
        : null);
  const swrFn = () =>
    getUpgradePlanStageAction(id, stageNumber, actionId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all the Steps for an Upgrade Plan Stage
 */
export const listUpgradePlanStageSteps = (
  id: string,
  stageNumber: string,
  params?: ListUpgradePlanStageStepsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradePlanStageStepsResponse>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListUpgradePlanStageStepsKey = (
  id: string,
  stageNumber: string,
  params?: ListUpgradePlanStageStepsParams,
) => [
  `/upgrades/${id}/stages/${stageNumber}/steps`,
  ...(params ? [params] : []),
];

export type ListUpgradePlanStageStepsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradePlanStageSteps>>
>;
export type ListUpgradePlanStageStepsQueryError = ErrorType<unknown>;

export const useListUpgradePlanStageSteps = <TError = ErrorType<unknown>>(
  id: string,
  stageNumber: string,
  params?: ListUpgradePlanStageStepsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradePlanStageSteps>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(id && stageNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradePlanStageStepsKey(id, stageNumber, params)
        : null);
  const swrFn = () =>
    listUpgradePlanStageSteps(id, stageNumber, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create a new Step
 * @summary Create a new Step
 */
export const createUpgradePlanStageStep = (
  id: string,
  stageNumber: string,
  createUpgradePlanStageStepRequest: BodyType<CreateUpgradePlanStageStepRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStep>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradePlanStageStepRequest,
    },
    options,
  );
};

/**
 * Delete the specified Step of a Stage
 * @summary Delete an Step of a Stage
 */
export const deleteUpgradePlanStageStep = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}`,
      method: 'delete',
    },
    options,
  );
};

/**
 * Returns information about the specified Step of a Stage
 * @summary Get information about an Step of a Stage
 */
export const getUpgradePlanStageStep = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStep>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}`,
      method: 'get',
    },
    options,
  );
};

export const getGetUpgradePlanStageStepKey = (
  id: string,
  stageNumber: string,
  stepNumber: string,
) => [`/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}`];

export type GetUpgradePlanStageStepQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradePlanStageStep>>
>;
export type GetUpgradePlanStageStepQueryError = ErrorType<void>;

export const useGetUpgradePlanStageStep = <TError = ErrorType<void>>(
  id: string,
  stageNumber: string,
  stepNumber: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradePlanStageStep>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(id && stageNumber && stepNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetUpgradePlanStageStepKey(id, stageNumber, stepNumber)
        : null);
  const swrFn = () =>
    getUpgradePlanStageStep(id, stageNumber, stepNumber, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update information about the specified Step of a Stage
 * @summary Update information about an Step of a Stage
 */
export const updateUpgradePlanStageStep = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  updateUpgradePlanStageStepRequest: BodyType<UpdateUpgradePlanStageStepRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStep>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateUpgradePlanStageStepRequest,
    },
    options,
  );
};

/**
 * Retrieve all comments for the specified step.
 */
export const listUpgradePlanStageStepComments = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradePlanStageStepCommentsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradePlanStageStepCommentsResponse>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}/comments`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListUpgradePlanStageStepCommentsKey = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradePlanStageStepCommentsParams,
) => [
  `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}/comments`,
  ...(params ? [params] : []),
];

export type ListUpgradePlanStageStepCommentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradePlanStageStepComments>>
>;
export type ListUpgradePlanStageStepCommentsQueryError = ErrorType<unknown>;

export const useListUpgradePlanStageStepComments = <
  TError = ErrorType<unknown>,
>(
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradePlanStageStepCommentsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradePlanStageStepComments>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(id && stageNumber && stepNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradePlanStageStepCommentsKey(
            id,
            stageNumber,
            stepNumber,
            params,
          )
        : null);
  const swrFn = () =>
    listUpgradePlanStageStepComments(
      id,
      stageNumber,
      stepNumber,
      params,
      requestOptions,
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Post a new comment on the specific step
 * @summary Post a comment on the specific step
 */
export const createUpgradePlanStageStepComment = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  createUpgradePlanStageStepCommentRequest: BodyType<CreateUpgradePlanStageStepCommentRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}/comments`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradePlanStageStepCommentRequest,
    },
    options,
  );
};

/**
 * Retrieve all actions performed on the step
 * @deprecated
 */
export const listUpgradePlanStageStepActions = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradePlanStageStepActionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradePlanStageStepActionsResponse>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}/actions`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListUpgradePlanStageStepActionsKey = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradePlanStageStepActionsParams,
) => [
  `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}/actions`,
  ...(params ? [params] : []),
];

export type ListUpgradePlanStageStepActionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradePlanStageStepActions>>
>;
export type ListUpgradePlanStageStepActionsQueryError = ErrorType<unknown>;

export const useListUpgradePlanStageStepActions = <TError = ErrorType<unknown>>(
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradePlanStageStepActionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradePlanStageStepActions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(id && stageNumber && stepNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradePlanStageStepActionsKey(
            id,
            stageNumber,
            stepNumber,
            params,
          )
        : null);
  const swrFn = () =>
    listUpgradePlanStageStepActions(
      id,
      stageNumber,
      stepNumber,
      params,
      requestOptions,
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Trigger an action on the object.
 * @deprecated
 * @summary Trigger an action on the object
 */
export const createUpgradePlanStageStepAction = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  createUpgradePlanStageStepActionRequest: BodyType<CreateUpgradePlanStageStepActionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStep>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}/actions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradePlanStageStepActionRequest,
    },
    options,
  );
};

/**
 * Get information of an action
 * @deprecated
 * @summary Get information of an action
 */
export const getUpgradePlanStageStepAction = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  actionId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStepAction>(
    {
      url: `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}/action/${actionId}`,
      method: 'get',
    },
    options,
  );
};

export const getGetUpgradePlanStageStepActionKey = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  actionId: string,
) => [
  `/upgrades/${id}/stages/${stageNumber}/steps/${stepNumber}/action/${actionId}`,
];

export type GetUpgradePlanStageStepActionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradePlanStageStepAction>>
>;
export type GetUpgradePlanStageStepActionQueryError = ErrorType<void>;

export const useGetUpgradePlanStageStepAction = <TError = ErrorType<void>>(
  id: string,
  stageNumber: string,
  stepNumber: string,
  actionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradePlanStageStepAction>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(id && stageNumber && stepNumber && actionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetUpgradePlanStageStepActionKey(
            id,
            stageNumber,
            stepNumber,
            actionId,
          )
        : null);
  const swrFn = () =>
    getUpgradePlanStageStepAction(
      id,
      stageNumber,
      stepNumber,
      actionId,
      requestOptions,
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all the Upgrade Templates associated with a Chkk account
 */
export const listUpgradeTemplates = (
  params?: ListUpgradeTemplatesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradeTemplatesResponse>(
    { url: `/upgrade_templates`, method: 'get', params },
    options,
  );
};

export const getListUpgradeTemplatesKey = (
  params?: ListUpgradeTemplatesParams,
) => [`/upgrade_templates`, ...(params ? [params] : [])];

export type ListUpgradeTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplates>>
>;
export type ListUpgradeTemplatesQueryError = ErrorType<unknown>;

export const useListUpgradeTemplates = <TError = ErrorType<unknown>>(
  params?: ListUpgradeTemplatesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplates>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradeTemplatesKey(params) : null));
  const swrFn = () => listUpgradeTemplates(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create an Upgrade Template for your resources (Clusters, Add-Ons, Applications etc)
 * @summary Create an Upgrade Template
 */
export const createUpgradeTemplate = (
  createUpgradeTemplateRequest: BodyType<CreateUpgradeTemplateRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeTemplate>(
    {
      url: `/upgrade_templates`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradeTemplateRequest,
    },
    options,
  );
};

/**
 * Deletes the specified Upgrade Template.
 * @summary Delete an Upgrade Template
 */
export const deleteUpgradeTemplate = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/upgrade_templates/${id}`, method: 'delete' },
    options,
  );
};

/**
 * Returns information about the specified Upgrade Template.
 * @summary Get information about an Upgrade Template
 */
export const getUpgradeTemplate = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeTemplate>(
    { url: `/upgrade_templates/${id}`, method: 'get' },
    options,
  );
};

export const getGetUpgradeTemplateKey = (id: string) => [
  `/upgrade_templates/${id}`,
];

export type GetUpgradeTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradeTemplate>>
>;
export type GetUpgradeTemplateQueryError = ErrorType<void>;

export const useGetUpgradeTemplate = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradeTemplate>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetUpgradeTemplateKey(id) : null));
  const swrFn = () => getUpgradeTemplate(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Updates the base properties of an Upgrade Template
 * @summary Update an Upgrade Template
 */
export const updateUpgradeTemplate = (
  id: string,
  updateUpgradeTemplateRequest: BodyType<UpdateUpgradeTemplateRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeTemplate>(
    {
      url: `/upgrade_templates/${id}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateUpgradeTemplateRequest,
    },
    options,
  );
};

/**
 * Returns information about the history of the specified Upgrade Template.
 * @summary List history entries of an Upgrade Template
 */
export const listUpgradeTemplateHistory = (
  id: string,
  params?: ListUpgradeTemplateHistoryParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradeTemplateHistoryResponse>(
    { url: `/upgrade_templates/${id}/history`, method: 'get', params },
    options,
  );
};

export const getListUpgradeTemplateHistoryKey = (
  id: string,
  params?: ListUpgradeTemplateHistoryParams,
) => [`/upgrade_templates/${id}/history`, ...(params ? [params] : [])];

export type ListUpgradeTemplateHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplateHistory>>
>;
export type ListUpgradeTemplateHistoryQueryError = ErrorType<void>;

export const useListUpgradeTemplateHistory = <TError = ErrorType<void>>(
  id: string,
  params?: ListUpgradeTemplateHistoryParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplateHistory>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradeTemplateHistoryKey(id, params) : null));
  const swrFn = () => listUpgradeTemplateHistory(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all the Stages for an Upgrade Template
 */
export const listUpgradeTemplateStages = (
  id: string,
  params?: ListUpgradeTemplateStagesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradeTemplateStagesResponse>(
    { url: `/upgrade_templates/${id}/stages`, method: 'get', params },
    options,
  );
};

export const getListUpgradeTemplateStagesKey = (
  id: string,
  params?: ListUpgradeTemplateStagesParams,
) => [`/upgrade_templates/${id}/stages`, ...(params ? [params] : [])];

export type ListUpgradeTemplateStagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplateStages>>
>;
export type ListUpgradeTemplateStagesQueryError = ErrorType<unknown>;

export const useListUpgradeTemplateStages = <TError = ErrorType<unknown>>(
  id: string,
  params?: ListUpgradeTemplateStagesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplateStages>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUpgradeTemplateStagesKey(id, params) : null));
  const swrFn = () => listUpgradeTemplateStages(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Returns information about the specified Stage of an Upgrade Template
 * @summary Get information about an Stage of an Upgrade Template
 */
export const getUpgradeTemplateStage = (
  id: string,
  stageNumber: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStage>(
    { url: `/upgrade_templates/${id}/stages/${stageNumber}`, method: 'get' },
    options,
  );
};

export const getGetUpgradeTemplateStageKey = (
  id: string,
  stageNumber: string,
) => [`/upgrade_templates/${id}/stages/${stageNumber}`];

export type GetUpgradeTemplateStageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradeTemplateStage>>
>;
export type GetUpgradeTemplateStageQueryError = ErrorType<void>;

export const useGetUpgradeTemplateStage = <TError = ErrorType<void>>(
  id: string,
  stageNumber: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradeTemplateStage>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(id && stageNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetUpgradeTemplateStageKey(id, stageNumber) : null));
  const swrFn = () => getUpgradeTemplateStage(id, stageNumber, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all actions performed on the stage
 * @deprecated
 */
export const listUpgradeTemplateStageActions = (
  id: string,
  stageNumber: string,
  params?: ListUpgradeTemplateStageActionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradeTemplateStageActionsResponse>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/actions`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListUpgradeTemplateStageActionsKey = (
  id: string,
  stageNumber: string,
  params?: ListUpgradeTemplateStageActionsParams,
) => [
  `/upgrade_templates/${id}/stages/${stageNumber}/actions`,
  ...(params ? [params] : []),
];

export type ListUpgradeTemplateStageActionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplateStageActions>>
>;
export type ListUpgradeTemplateStageActionsQueryError = ErrorType<unknown>;

export const useListUpgradeTemplateStageActions = <TError = ErrorType<unknown>>(
  id: string,
  stageNumber: string,
  params?: ListUpgradeTemplateStageActionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplateStageActions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(id && stageNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradeTemplateStageActionsKey(id, stageNumber, params)
        : null);
  const swrFn = () =>
    listUpgradeTemplateStageActions(id, stageNumber, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Trigger an action on the object.
 * @deprecated
 * @summary Trigger an action on the object
 */
export const createUpgradeTemplateStageAction = (
  id: string,
  stageNumber: string,
  createUpgradeTemplateStageActionRequest: BodyType<CreateUpgradeTemplateStageActionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStage>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/actions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradeTemplateStageActionRequest,
    },
    options,
  );
};

/**
 * Get information of an action
 * @deprecated
 * @summary Get information of an action
 */
export const getUpgradeTemplateStageAction = (
  id: string,
  stageNumber: string,
  actionId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageAction>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/action/${actionId}`,
      method: 'get',
    },
    options,
  );
};

export const getGetUpgradeTemplateStageActionKey = (
  id: string,
  stageNumber: string,
  actionId: string,
) => [`/upgrade_templates/${id}/stages/${stageNumber}/action/${actionId}`];

export type GetUpgradeTemplateStageActionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradeTemplateStageAction>>
>;
export type GetUpgradeTemplateStageActionQueryError = ErrorType<void>;

export const useGetUpgradeTemplateStageAction = <TError = ErrorType<void>>(
  id: string,
  stageNumber: string,
  actionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradeTemplateStageAction>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(id && stageNumber && actionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetUpgradeTemplateStageActionKey(id, stageNumber, actionId)
        : null);
  const swrFn = () =>
    getUpgradeTemplateStageAction(id, stageNumber, actionId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Retrieve all the Steps for an Upgrade Template Stage
 */
export const listUpgradeTemplateStageSteps = (
  id: string,
  stageNumber: string,
  params?: ListUpgradeTemplateStageStepsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradeTemplateStageStepsResponse>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListUpgradeTemplateStageStepsKey = (
  id: string,
  stageNumber: string,
  params?: ListUpgradeTemplateStageStepsParams,
) => [
  `/upgrade_templates/${id}/stages/${stageNumber}/steps`,
  ...(params ? [params] : []),
];

export type ListUpgradeTemplateStageStepsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplateStageSteps>>
>;
export type ListUpgradeTemplateStageStepsQueryError = ErrorType<unknown>;

export const useListUpgradeTemplateStageSteps = <TError = ErrorType<unknown>>(
  id: string,
  stageNumber: string,
  params?: ListUpgradeTemplateStageStepsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplateStageSteps>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(id && stageNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradeTemplateStageStepsKey(id, stageNumber, params)
        : null);
  const swrFn = () =>
    listUpgradeTemplateStageSteps(id, stageNumber, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Create a new Step
 * @summary Create a new Step
 */
export const createUpgradeTemplateStageStep = (
  id: string,
  stageNumber: string,
  createUpgradeTemplateStageStepRequest: BodyType<CreateUpgradeTemplateStageStepRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStep>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradeTemplateStageStepRequest,
    },
    options,
  );
};

/**
 * Delete the specified Step of a Stage
 * @summary Delete an Step of a Stage
 */
export const deleteUpgradeTemplateStageStep = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}`,
      method: 'delete',
    },
    options,
  );
};

/**
 * Returns information about the specified Step of a Stage
 * @summary Get information about an Step of a Stage
 */
export const getUpgradeTemplateStageStep = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStep>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}`,
      method: 'get',
    },
    options,
  );
};

export const getGetUpgradeTemplateStageStepKey = (
  id: string,
  stageNumber: string,
  stepNumber: string,
) => [`/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}`];

export type GetUpgradeTemplateStageStepQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradeTemplateStageStep>>
>;
export type GetUpgradeTemplateStageStepQueryError = ErrorType<void>;

export const useGetUpgradeTemplateStageStep = <TError = ErrorType<void>>(
  id: string,
  stageNumber: string,
  stepNumber: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradeTemplateStageStep>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(id && stageNumber && stepNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetUpgradeTemplateStageStepKey(id, stageNumber, stepNumber)
        : null);
  const swrFn = () =>
    getUpgradeTemplateStageStep(id, stageNumber, stepNumber, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Update information about the specified Step of a Stage
 * @summary Update information about an Step of a Stage
 */
export const updateUpgradeTemplateStageStep = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  updateUpgradeTemplateStageStepRequest: BodyType<UpdateUpgradeTemplateStageStepRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStep>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateUpgradeTemplateStageStepRequest,
    },
    options,
  );
};

/**
 * Retrieve all comments for the specified step.
 */
export const listUpgradeTemplateStageStepComments = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradeTemplateStageStepCommentsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradeTemplateStageStepCommentsResponse>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}/comments`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListUpgradeTemplateStageStepCommentsKey = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradeTemplateStageStepCommentsParams,
) => [
  `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}/comments`,
  ...(params ? [params] : []),
];

export type ListUpgradeTemplateStageStepCommentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplateStageStepComments>>
>;
export type ListUpgradeTemplateStageStepCommentsQueryError = ErrorType<unknown>;

export const useListUpgradeTemplateStageStepComments = <
  TError = ErrorType<unknown>,
>(
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradeTemplateStageStepCommentsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplateStageStepComments>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(id && stageNumber && stepNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradeTemplateStageStepCommentsKey(
            id,
            stageNumber,
            stepNumber,
            params,
          )
        : null);
  const swrFn = () =>
    listUpgradeTemplateStageStepComments(
      id,
      stageNumber,
      stepNumber,
      params,
      requestOptions,
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Post a new comment on the specific step
 * @summary Post a comment on the specific step
 */
export const createUpgradeTemplateStageStepComment = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  createUpgradeTemplateStageStepCommentRequest: BodyType<CreateUpgradeTemplateStageStepCommentRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}/comments`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradeTemplateStageStepCommentRequest,
    },
    options,
  );
};

/**
 * Retrieve all actions performed on the step
 * @deprecated
 */
export const listUpgradeTemplateStageStepActions = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradeTemplateStageStepActionsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ListUpgradeTemplateStageStepActionsResponse>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}/actions`,
      method: 'get',
      params,
    },
    options,
  );
};

export const getListUpgradeTemplateStageStepActionsKey = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradeTemplateStageStepActionsParams,
) => [
  `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}/actions`,
  ...(params ? [params] : []),
];

export type ListUpgradeTemplateStageStepActionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpgradeTemplateStageStepActions>>
>;
export type ListUpgradeTemplateStageStepActionsQueryError = ErrorType<unknown>;

export const useListUpgradeTemplateStageStepActions = <
  TError = ErrorType<unknown>,
>(
  id: string,
  stageNumber: string,
  stepNumber: string,
  params?: ListUpgradeTemplateStageStepActionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUpgradeTemplateStageStepActions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(id && stageNumber && stepNumber);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getListUpgradeTemplateStageStepActionsKey(
            id,
            stageNumber,
            stepNumber,
            params,
          )
        : null);
  const swrFn = () =>
    listUpgradeTemplateStageStepActions(
      id,
      stageNumber,
      stepNumber,
      params,
      requestOptions,
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * Trigger an action on the object.
 * @deprecated
 * @summary Trigger an action on the object
 */
export const createUpgradeTemplateStageStepAction = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  createUpgradeTemplateStageStepActionRequest: BodyType<CreateUpgradeTemplateStageStepActionRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStep>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}/actions`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createUpgradeTemplateStageStepActionRequest,
    },
    options,
  );
};

/**
 * Get information of an action
 * @deprecated
 * @summary Get information of an action
 */
export const getUpgradeTemplateStageStepAction = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  actionId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpgradeStageStepAction>(
    {
      url: `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}/action/${actionId}`,
      method: 'get',
    },
    options,
  );
};

export const getGetUpgradeTemplateStageStepActionKey = (
  id: string,
  stageNumber: string,
  stepNumber: string,
  actionId: string,
) => [
  `/upgrade_templates/${id}/stages/${stageNumber}/steps/${stepNumber}/action/${actionId}`,
];

export type GetUpgradeTemplateStageStepActionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUpgradeTemplateStageStepAction>>
>;
export type GetUpgradeTemplateStageStepActionQueryError = ErrorType<void>;

export const useGetUpgradeTemplateStageStepAction = <TError = ErrorType<void>>(
  id: string,
  stageNumber: string,
  stepNumber: string,
  actionId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUpgradeTemplateStageStepAction>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false &&
    !!(id && stageNumber && stepNumber && actionId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled
        ? getGetUpgradeTemplateStageStepActionKey(
            id,
            stageNumber,
            stepNumber,
            actionId,
          )
        : null);
  const swrFn = () =>
    getUpgradeTemplateStageStepAction(
      id,
      stageNumber,
      stepNumber,
      actionId,
      requestOptions,
    );

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  );

  return {
    swrKey,
    ...query,
  };
};
