import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
import { Stack } from './utils/Stack';
// @mui

// ----------------------------------------------------------------------

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  isIndexable?: boolean;
}

const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = '', meta, isIndexable = true, ...other }, ref) => (
    <>
      <Helmet>
        <title>{`${title} | Chkk`}</title>
        {meta}
        {!isIndexable && <meta name="robots" content="noindex" />}
      </Helmet>

      <Stack height="fill" ref={ref} {...other}>
        {children}
      </Stack>
    </>
  ),
);
Page.displayName = 'Page';

export default Page;
