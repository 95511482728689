'use client';
/*
 * Documentation:
 * Cluster Card — https://app.subframe.com/cd4f5307efe3/library?component=Cluster+Card_27d3cea0-3009-4de2-b183-3acf2e351da8
 * Tooltip — https://app.subframe.com/cd4f5307efe3/library?component=Tooltip_ccebd1e9-f6ac-4737-8376-0dfacd90c9f3
 * Badge — https://app.subframe.com/cd4f5307efe3/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { Tooltip } from './Tooltip';
import { IconButton } from './IconButton';
import { Badge } from './Badge';
import { useGate } from 'statsig-react';

interface UnsupportedButtonProps
  extends React.ComponentProps<typeof IconButton> {
  tooltipText?: string;
  className?: string;
}

const UnsupportedButton = React.forwardRef<HTMLElement, UnsupportedButtonProps>(
  function UnsupportedButton(
    { tooltipText, className, ...otherProps }: UnsupportedButtonProps,
    ref,
  ) {
    return (
      <SubframeCore.Tooltip.Provider>
        <SubframeCore.Tooltip.Root>
          <SubframeCore.Tooltip.Trigger asChild={true}>
            <IconButton
              className={className}
              variant="brand-secondary"
              size="medium"
              icon="FeatherClock"
              loading={false}
              ref={ref as any}
              {...otherProps}
            />
          </SubframeCore.Tooltip.Trigger>
          <SubframeCore.Tooltip.Portal>
            <SubframeCore.Tooltip.Content
              side="bottom"
              align="center"
              sideOffset={8}
              asChild={true}
            >
              <Tooltip>{tooltipText}</Tooltip>
            </SubframeCore.Tooltip.Content>
          </SubframeCore.Tooltip.Portal>
        </SubframeCore.Tooltip.Root>
      </SubframeCore.Tooltip.Provider>
    );
  },
);

interface RescanClusterButtonProps
  extends React.ComponentProps<typeof IconButton> {
  tooltipText?: string;
  className?: string;
}

const RescanClusterButton = React.forwardRef<
  HTMLElement,
  RescanClusterButtonProps
>(function RescanClusterButton(
  { tooltipText, className, ...otherProps }: RescanClusterButtonProps,
  ref,
) {
  return (
    <SubframeCore.Tooltip.Provider>
      <SubframeCore.Tooltip.Root>
        <SubframeCore.Tooltip.Trigger asChild={true}>
          <IconButton
            className={className}
            size="medium"
            icon="FeatherRotateCw"
            ref={ref as any}
            {...otherProps}
          />
        </SubframeCore.Tooltip.Trigger>
        <SubframeCore.Tooltip.Portal>
          <SubframeCore.Tooltip.Content
            side="bottom"
            align="center"
            sideOffset={8}
            asChild={true}
          >
            <Tooltip>{tooltipText}</Tooltip>
          </SubframeCore.Tooltip.Content>
        </SubframeCore.Tooltip.Portal>
      </SubframeCore.Tooltip.Root>
    </SubframeCore.Tooltip.Provider>
  );
});

interface RiskPopoverDataProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  unsupportedVersions?: string;
  defects?: string;
  deprecations?: string;
  versionIncompatibilities?: string;
  misconfigurations?: string;
  systemRequirements?: string;
  total?: string;
  className?: string;
}

const RiskPopoverData = React.forwardRef<HTMLElement, RiskPopoverDataProps>(
  function RiskPopoverData(
    {
      title,
      unsupportedVersions,
      defects,
      deprecations,
      versionIncompatibilities,
      misconfigurations,
      systemRequirements,
      total,
      className,
      ...otherProps
    }: RiskPopoverDataProps,
    ref,
  ) {
    const showSystemRequirements = useGate('show_system_requirements');

    return (
      <div
        className={SubframeCore.twClassNames(
          'flex w-52 flex-col items-start gap-2 bg-default-font',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {title ? (
          <span className="text-label font-label text-black">{title}</span>
        ) : null}
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
        <div className="flex w-full flex-col items-start gap-1">
          <div className="flex w-full items-center gap-2">
            <span className="grow shrink-0 basis-0 text-label font-label text-black">
              Unsupported versions
            </span>
            {unsupportedVersions ? (
              <span className="text-label-bold font-label-bold text-black">
                {unsupportedVersions}
              </span>
            ) : null}
          </div>
          <div className="flex w-full items-center gap-2">
            <span className="grow shrink-0 basis-0 text-label font-label text-black">
              Defects
            </span>
            {defects ? (
              <span className="text-label-bold font-label-bold text-black">
                {defects}
              </span>
            ) : null}
          </div>
          <div className="flex w-full items-center gap-2">
            <span className="grow shrink-0 basis-0 text-label font-label text-black">
              Deprecations
            </span>
            {deprecations ? (
              <span className="text-label-bold font-label-bold text-black">
                {deprecations}
              </span>
            ) : null}
          </div>
          <div className="flex w-full items-center gap-2">
            <span className="grow shrink-0 basis-0 text-label font-label text-black">
              Version incompatibilities
            </span>
            {versionIncompatibilities ? (
              <span className="text-label-bold font-label-bold text-black">
                {versionIncompatibilities}
              </span>
            ) : null}
          </div>
          <div className="flex w-full items-center gap-2">
            <span className="grow shrink-0 basis-0 text-label font-label text-black">
              Misconfigurations
            </span>
            {misconfigurations ? (
              <span className="text-label-bold font-label-bold text-black">
                {misconfigurations}
              </span>
            ) : null}
          </div>
          {showSystemRequirements?.value && (
            <div className="flex w-full items-center gap-2">
              <span className="grow shrink-0 basis-0 text-label font-label text-black">
                System requirements
              </span>
              {systemRequirements ? (
                <span className="text-label-bold font-label-bold text-black">
                  {systemRequirements}
                </span>
              ) : null}
            </div>
          )}
        </div>
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
        <div className="flex w-full items-center gap-2">
          <span className="grow shrink-0 basis-0 text-label-bold font-label-bold text-black">
            Total
          </span>
          {total ? (
            <span className="text-label-bold font-label-bold text-black">
              {total}
            </span>
          ) : null}
        </div>
      </div>
    );
  },
);

interface InactiveClusterButtonProps
  extends React.ComponentProps<typeof IconButton> {
  tooltipContents?: React.ReactNode;
  className?: string;
}

const InactiveClusterButton = React.forwardRef<
  HTMLElement,
  InactiveClusterButtonProps
>(function InactiveClusterButton(
  { tooltipContents, className, ...otherProps }: InactiveClusterButtonProps,
  ref,
) {
  return (
    <SubframeCore.Tooltip.Provider>
      <SubframeCore.Tooltip.Root>
        <SubframeCore.Tooltip.Trigger asChild={true}>
          <IconButton
            className={className}
            variant="destructive-secondary"
            size="medium"
            icon="FeatherAlertTriangle"
            ref={ref as any}
            {...otherProps}
          />
        </SubframeCore.Tooltip.Trigger>
        <SubframeCore.Tooltip.Portal>
          <SubframeCore.Tooltip.Content
            side="bottom"
            align="center"
            sideOffset={8}
            asChild={true}
          >
            {tooltipContents ? (
              <div className="flex items-start justify-between">
                {tooltipContents}
              </div>
            ) : null}
          </SubframeCore.Tooltip.Content>
        </SubframeCore.Tooltip.Portal>
      </SubframeCore.Tooltip.Root>
    </SubframeCore.Tooltip.Provider>
  );
});

interface ClusterCardBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  needsAttentionAmount?: string;
  okAmount?: string;
  needsAttentionPopover?: React.ReactNode;
  remediatedPopover?: React.ReactNode;
  className?: string;
}

const ClusterCardBody = React.forwardRef<HTMLElement, ClusterCardBodyProps>(
  function ClusterCardBody(
    {
      needsAttentionAmount,
      okAmount,
      needsAttentionPopover,
      remediatedPopover,
      className,
      ...otherProps
    }: ClusterCardBodyProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex flex-col items-start gap-1',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex items-center gap-2">
          <SubframeCore.HoverCard.Root>
            <SubframeCore.HoverCard.Trigger asChild={true}>
              <SubframeCore.Icon
                className="hidden text-body font-body text-warning-600"
                name="FeatherAlertCircle"
              />
            </SubframeCore.HoverCard.Trigger>
            <SubframeCore.HoverCard.Portal>
              <SubframeCore.HoverCard.Content
                side="bottom"
                align="center"
                sideOffset={8}
                asChild={true}
              >
                <div className="flex flex-col items-start gap-1 rounded border border-solid border-neutral-border bg-neutral-100 pt-3 pr-3 pb-3 pl-3 shadow-large">
                  <span className="text-body font-body text-default-font">
                    New Availability Risk detected in this cluster
                  </span>
                </div>
              </SubframeCore.HoverCard.Content>
            </SubframeCore.HoverCard.Portal>
          </SubframeCore.HoverCard.Root>
          <span className="text-body font-body text-subtext-color">
            Need attention
          </span>
          <SubframeCore.HoverCard.Root>
            <SubframeCore.HoverCard.Trigger asChild={true}>
              <SubframeCore.Icon
                className="text-body font-body text-subtext-color need-attention-info-icon"
                name="FeatherInfo"
              />
            </SubframeCore.HoverCard.Trigger>
            <SubframeCore.HoverCard.Portal>
              <SubframeCore.HoverCard.Content
                side="bottom"
                align="center"
                sideOffset={8}
                asChild={true}
              >
                {needsAttentionPopover ? (
                  <div className="flex flex-col items-start gap-1 rounded border border-solid border-default-font bg-default-font pt-3 pr-3 pb-3 pl-3 shadow-large">
                    {needsAttentionPopover}
                  </div>
                ) : null}
              </SubframeCore.HoverCard.Content>
            </SubframeCore.HoverCard.Portal>
          </SubframeCore.HoverCard.Root>
        </div>
        <div className="flex items-center gap-2">
          {needsAttentionAmount ? (
            <span className="text-section-header font-section-header text-default-font">
              {needsAttentionAmount}
            </span>
          ) : null}
          <SubframeCore.HoverCard.Root>
            <SubframeCore.HoverCard.Trigger asChild={true}>
              <Badge className="ok-amount" variant="neutral">
                {okAmount}
              </Badge>
            </SubframeCore.HoverCard.Trigger>
            <SubframeCore.HoverCard.Portal>
              <SubframeCore.HoverCard.Content
                side="bottom"
                align="center"
                sideOffset={8}
                asChild={true}
              >
                {remediatedPopover ? (
                  <div className="flex flex-col items-start gap-1 rounded border border-solid border-brand-primary bg-default-font pt-3 pr-3 pb-3 pl-3 shadow-large">
                    {remediatedPopover}
                  </div>
                ) : null}
              </SubframeCore.HoverCard.Content>
            </SubframeCore.HoverCard.Portal>
          </SubframeCore.HoverCard.Root>
        </div>
      </div>
    );
  },
);

interface MoreOptionsButtonProps
  extends React.ComponentProps<typeof IconButton> {
  moreOptions?: React.ReactNode;
  className?: string;
}

const MoreOptionsButton = React.forwardRef<HTMLElement, MoreOptionsButtonProps>(
  function MoreOptionsButton(
    { moreOptions, className, ...otherProps }: MoreOptionsButtonProps,
    ref,
  ) {
    return (
      <SubframeCore.DropdownMenu.Root>
        <SubframeCore.DropdownMenu.Trigger asChild={true}>
          <IconButton
            className={className}
            variant="neutral-tertiary"
            size="medium"
            icon="FeatherMoreVertical"
            ref={ref as any}
            {...otherProps}
          />
        </SubframeCore.DropdownMenu.Trigger>
        <SubframeCore.DropdownMenu.Portal>
          <SubframeCore.DropdownMenu.Content
            side="bottom"
            align="start"
            sideOffset={8}
            asChild={true}
          >
            {moreOptions ? (
              <div className="flex flex-col items-start justify-between">
                {moreOptions}
              </div>
            ) : null}
          </SubframeCore.DropdownMenu.Content>
        </SubframeCore.DropdownMenu.Portal>
      </SubframeCore.DropdownMenu.Root>
    );
  },
);

interface ClusterCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: string;
  buttons?: React.ReactNode;
  body?: React.ReactNode;
  badges?: React.ReactNode;
  className?: string;
}

const ClusterCardRoot = React.forwardRef<HTMLElement, ClusterCardRootProps>(
  function ClusterCardRoot(
    {
      title,
      description,
      buttons,
      body,
      badges,
      className,
      ...otherProps
    }: ClusterCardRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/27d3cea0 flex h-full w-full cursor-pointer flex-col items-start gap-6 rounded border border-solid border-neutral-border bg-neutral-50 pt-6 pr-6 pb-6 pl-6 hover:bg-neutral-200',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full flex-col items-start gap-1">
          <div className="flex w-full items-center justify-between">
            {title ? (
              <SubframeCore.Tooltip.Provider>
                <SubframeCore.Tooltip.Root>
                  <SubframeCore.Tooltip.Trigger asChild={true}>
                    <span className="line-clamp-1 text-subheader font-subheader text-default-font">
                      {title}
                    </span>
                  </SubframeCore.Tooltip.Trigger>
                  <SubframeCore.Tooltip.Portal>
                    <SubframeCore.Tooltip.Content
                      side="bottom"
                      align="center"
                      sideOffset={8}
                      asChild={true}
                    >
                      <Tooltip>{title}</Tooltip>
                    </SubframeCore.Tooltip.Content>
                  </SubframeCore.Tooltip.Portal>
                </SubframeCore.Tooltip.Root>
              </SubframeCore.Tooltip.Provider>
            ) : null}
            {buttons ? (
              <div className="flex items-center gap-1">{buttons}</div>
            ) : null}
          </div>
          <div className="flex w-full items-start gap-1">
            {badges ? (
              <div className="flex items-center gap-2">{badges}</div>
            ) : null}
            {description ? (
              <span className="text-label font-label text-subtext-color">
                {description}
              </span>
            ) : null}
          </div>
        </div>
        {body ? (
          <div className="flex flex-col items-start gap-6">{body}</div>
        ) : null}
      </div>
    );
  },
);

export const ClusterCard = Object.assign(ClusterCardRoot, {
  UnsupportedButton,
  RescanClusterButton,
  RiskPopoverData,
  InactiveClusterButton,
  ClusterCardBody,
  MoreOptionsButton,
});
