'use client';
/*
 * Documentation:
 * Card - Base — https://app.subframe.com/library?component=Card+-+Base_429444f5-176c-420a-a4ce-0f7e800efab4
 * Icon Button — https://app.subframe.com/library?component=Icon+Button_af9405b1-8c54-4e01-9786-5aad308224f6
 * Badge — https://app.subframe.com/library?component=Badge_97bdb082-1124-4dd7-a335-b14b822d0157
 * Info tooltip — https://app.subframe.com/library?component=Info+tooltip_58466bc5-c62e-47fb-9394-3b607f233dfa
 */

import * as SubframeCore from '@subframe/core';
import React from 'react';

interface MetricProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  amount?: string;
  color?: 'neutral' | 'red' | 'yellow' | 'green';
  className?: string;
}

const Metric = React.forwardRef<HTMLElement, MetricProps>(function Metric(
  { label, amount, color = 'neutral', className, ...otherProps }: MetricProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/8f86ce53 flex flex-col items-start gap-1',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      {label ? (
        <span className="text-body font-body text-subtext-color">{label}</span>
      ) : null}
      {amount ? (
        <span
          className={SubframeCore.twClassNames(
            'text-section-header font-section-header text-default-font',
            {
              'text-success-600': color === 'green',
              'text-warning-600': color === 'yellow',
              'text-error-600': color === 'red',
            },
          )}
        >
          {amount}
        </span>
      ) : null}
    </div>
  );
});

interface CardBaseRootProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  subtitle?: string;
  metric?: string;
  metricTitle?: string;
  actions?: React.ReactNode;
  selected?: boolean;
  metric2Title?: string;
  metric2Children?: React.ReactNode;
  metric1Children?: React.ReactNode;
  icon?: SubframeCore.IconName;
  cardLabel?: React.ReactNode;
  metric1Label?: React.ReactNode;
  metric2Label?: React.ReactNode;
  metric1Tooltip?: string;
  metric2Tooltip?: string;
  className?: string;
}

const CardBaseRoot = React.forwardRef<HTMLElement, CardBaseRootProps>(
  function CardBaseRoot(
    {
      title,
      subtitle,
      metric,
      metricTitle,
      actions,
      selected = false,
      metric2Title,
      metric2Children,
      metric1Children,
      icon = null,
      cardLabel,
      metric1Label,
      metric2Label,
      metric1Tooltip,
      metric2Tooltip,
      className,
      ...otherProps
    }: CardBaseRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/429444f5 flex h-44 w-96 cursor-pointer flex-col items-start gap-4 rounded border border-solid border-neutral-border bg-neutral-50 pt-6 pr-6 pb-6 pl-6 hover:bg-neutral-100 active:bg-neutral-100 active:pt-6 active:pr-6 active:pb-6 active:pl-6',
          {
            'border border-solid border-brand-primary bg-neutral-100': selected,
          },
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full flex-col items-start">
          <div className="flex w-full items-center gap-2">
            <SubframeCore.Icon
              className="text-subheader font-subheader text-default-font"
              name={icon}
            />
            {title ? (
              <span className="line-clamp-1 text-subheader font-subheader text-default-font">
                {title}
              </span>
            ) : null}
            {actions ? (
              <div className="flex grow shrink-0 basis-0 items-center justify-end gap-0.5 self-stretch">
                {actions}
              </div>
            ) : null}
          </div>
          <div className="flex items-center gap-2">
            {cardLabel ? (
              <div className="flex items-center gap-2">{cardLabel}</div>
            ) : null}
            {subtitle ? (
              <span className="text-label font-label text-subtext-color">
                {subtitle}
              </span>
            ) : null}
          </div>
        </div>
        <div className="flex w-full items-start gap-2 pr-3">
          {metric1Children ? (
            <div className="flex flex-col items-start gap-2">
              {metric1Children}
            </div>
          ) : null}
          {metric2Children ? (
            <div className="flex grow shrink-0 basis-0 flex-col items-end gap-2 self-stretch">
              {metric2Children}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
);

export const CardBase = Object.assign(CardBaseRoot, {
  Metric,
});
