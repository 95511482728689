import { createContext, ReactNode, useState } from 'react';
import { AccessToken, Organization } from 'api/models';
// ----------------------------------------------------------------------

export interface AccountContext {
  token: string;
  accountId: string;
}

interface UserContext {
  userId: string;
  name: string;
  email?: string;
  profilePicture?: string;
  userToken?: string;
}

export type UserAccountContextProps = {
  loginType: 'normal' | 'federated' | 'magiclink';

  user: UserContext;

  account: AccountContext;
  isAccountLoading: boolean;

  tokens: { [orgId: string]: { [accountSlug: string]: AccessToken } };

  currentOrganization: Organization;
  isCurrentOrgLoading: boolean;
  organizations: Organization[];
  isOrganizationsLoading: boolean;
  updateUserAccountState: (newState: Partial<UserAccountContextProps>) => void;
  lastLoginTime: number;
};

const userAccountInitialState: UserAccountContextProps = {
  loginType: 'normal',
  account: {} as AccountContext,
  user: {} as UserContext,
  tokens: {},
  isAccountLoading: false,
  currentOrganization: {} as Organization,
  isCurrentOrgLoading: false,
  organizations: [],
  isOrganizationsLoading: false,
  updateUserAccountState: () => {},
  lastLoginTime: 0,
};
// ----------------------------------------------------------------------

export const UserAccountContext = createContext<UserAccountContextProps>(
  userAccountInitialState,
);

type UserAccountProviderProps = {
  children: ReactNode;
};
export const UserAccountProvider = (
  userAccountProviderProps: UserAccountProviderProps,
) => {
  const [userAccountState, setUserAccountState] =
    useState<UserAccountContextProps>(userAccountInitialState);

  const updateUserAccountState = (
    newState: Partial<UserAccountContextProps>,
  ) => {
    setUserAccountState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <UserAccountContext.Provider
      value={{ ...userAccountState, updateUserAccountState }}
    >
      {userAccountProviderProps.children}
    </UserAccountContext.Provider>
  );
};
