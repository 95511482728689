import { Container, Typography, styled, Stack } from '@mui/material';
import { m } from 'framer-motion';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import { MotionContainer, varBounce } from './animate';
import Page from './Page';
import { Button } from 'subframe/index';
import { RouterLink } from './RouterLink';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export const ErrorHandler = ({ error, componentStack, resetError }: any) => {
  return (
    <Page title="Error" style={{ height: '100vh' }}>
      <LogoOnlyLayout>
        <RootStyle>
          <Container component={MotionContainer}>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}
              spacing={2}
            >
              <m.div variants={varBounce().in}>
                <Typography variant="h3" paragraph>
                  Sorry something went wrong.
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  We are working on it and we&apos;ll get it fixed as soon as we
                  can.
                </Typography>
              </m.div>
              <RouterLink to={'/orgs'}>
                <Button
                  size="large"
                  onClick={() => {
                    resetError();
                  }}
                >
                  Back to home
                </Button>
              </RouterLink>
            </Stack>
          </Container>
        </RootStyle>
      </LogoOnlyLayout>
    </Page>
  );
};
