import { useTheme } from '@mui/material/styles';
import { TeamInvitation, TeamMember } from 'api/models';
import { useState } from 'react';
import useDialog from 'src/hooks/useDialog';
import { CardIntegration } from 'subframe/components/CardIntegration';
import { Avatar, SkeletonText } from 'subframe/index';
import styles from './TeamsCard.module.scss';

type Props = {
  members?: TeamMember[];
  pendingInvites?: TeamInvitation[];
  isLoading?: boolean;
};

// --------------------------------------------

const TeamIcon = ({
  user_picture,
  initials,
}: {
  user_picture: string | null;
  initials: string;
}) => {
  if (user_picture) {
    return (
      <Avatar
        image={user_picture}
        size="small"
        data-cy="user-icon"
        className="ph-no-capture"
      />
    );
  }
  return (
    <Avatar size="x-small" variant="brand" data-cy="user-icon">
      {initials}
    </Avatar>
  );
};

const MembersIconList = ({
  members,
  onHover,
}: {
  members?: TeamMember[];
  onHover: boolean;
}) => {
  const theme = useTheme();
  if (!members) return null;

  const newTeamLength = members?.length > 5 ? 5 : members?.length;
  const membersData = members?.slice();
  membersData.length = newTeamLength;

  return (
    <div className={styles['stack-2099d55e']}>
      <div
        className={styles['stack-98718605']}
        data-cy="members-avatars-list"
        style={{ marginRight: `-${membersData.length * 6}px` }}
      >
        {membersData?.map((member, index) => {
          const { user_name, user_picture } = member;
          const calcPosition = index * 6;
          return (
            <div
              key={`${user_name} + ${index}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                right: `${calcPosition}px`,
                zIndex: index,
                border: `2px solid ${
                  onHover ? theme.palette.grey[800] : theme.palette.grey[900]
                }`,
                backgroundColor: theme.palette.grey[900],
                borderRadius: '50%',
              }}
            >
              <TeamIcon
                initials={user_name?.charAt(0)?.toUpperCase()}
                user_picture={user_picture || null}
              />
            </div>
          );
        })}
      </div>
      <span className={styles['text']} id="teams-card-count">
        {`${members?.length} member${members?.length > 1 ? 's' : ''}`}
      </span>
    </div>
  );
};

// --------------------------------------------

export default function TeamsCard({
  members,
  pendingInvites,
  isLoading = false,
}: Props) {
  useDialog();
  const [onHover, setOnHover] = useState(false);
  return (
    <CardIntegration
      id="teams-card"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      className="h-36 w-full flex-none"
      cardTitle=""
      text={
        isLoading ? (
          <SkeletonText className="w-36" />
        ) : (
          `Pending Invites: ${pendingInvites?.length}`
        )
      }
      badge={null}
      logo={
        isLoading ? (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <SkeletonText className="h-8 w-40 flex-none" />
            <SkeletonText className="h-8 w-14 flex-none" />
          </div>
        ) : (
          <MembersIconList members={members} onHover={onHover} />
        )
      }
    />
  );
}
