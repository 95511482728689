import { SettingsValueProps } from './components/settings/type';

// LAYOUT
// ----------------------------------------------------------------------

export const SPINNER = {
  SMALL: 'SMALL',
  LARGE: 'LARGE',
};

export const HEADER = {
  MOBILE_HEIGHT: 58,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 48,
  DASHBOARD_DESKTOP_SECTION_HEIGHT: 60,
  DASHBOARD_DESKTOP_LARS_OVERVIEW_HEIGHT: 48 + 70,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 80 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
  //
  DASHBOARD_LARS_OVERVIEW_WIDTH: 400,
  DASHBOARD_LARS_OVERVIEW_HEIGHT_CONTAINER: 600,
  DASHBOARD_LARS_OVERVIEW_COLLAPSE_WIDTH: 32,
  PAGINATION_BAR_HEIGHT: 40,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'dark',
  themeDirection: 'ltr',
  themeColorPresets: 'orange',
  themeLayout: 'horizontal',
  themeStretch: false,
};
