import React from 'react';

// components
import { SxProps, useTheme, Typography, Stack } from '@mui/material';

interface TagLabelProps {
  value: string;
  sx?: SxProps;
}
const TagLabel: React.FC<TagLabelProps> = ({ value, sx }) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      sx={{
        height: '27px',
        minWidth: '22px',
        borderRadius: '15px',
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        padding: '0px 8px',
        fontSize: '0.75rem',
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.grey[800],
        ...sx,
      }}
    >
      <Typography variant="subtitle2" sx={{ padding: '4px 6px' }}>
        {value}
      </Typography>
    </Stack>
  );
};

export default TagLabel;
