import { useTheme } from '@mui/material';
import { RouterLink } from './RouterLink';

// ----------------------------------------------------------------------

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  disabledLink?: boolean;
  showText?: boolean;
  showPointerCursor?: boolean;
  onClick?: () => void;
}

export default function Logo({
  disabledLink = false,
  showText = true,
  showPointerCursor,
  style,
  onClick,
}: Props) {
  const theme = useTheme();

  const logo = (
    <div style={{ width: 113, height: 43, ...style }} onClick={onClick}>
      <svg
        viewBox={`0 0 ${showText ? '113' : '43'} 43`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M48.2144 22.0496V22.0018C48.2144 17.915 51.3691 14.4854 55.7069 14.4854C57.9654 14.4854 59.5189 15.2143 60.7497 16.2778C60.9648 16.469 61.2636 16.8753 61.2636 17.425C61.2636 18.2734 60.5824 18.9187 59.734 18.9187C59.3277 18.9187 58.9692 18.7514 58.7541 18.5961C57.9057 17.8911 56.9855 17.3772 55.6711 17.3772C53.2691 17.3772 51.5006 19.4445 51.5006 21.954V22.0137C51.5006 24.571 53.2691 26.6144 55.8025 26.6144C57.105 26.6144 58.1208 26.1006 59.017 25.3358C59.2082 25.1685 59.5309 24.9773 59.9132 24.9773C60.7019 24.9773 61.3233 25.6345 61.3233 26.4232C61.3233 26.8534 61.156 27.2119 60.8573 27.4629C59.5786 28.6937 58.0252 29.5063 55.6233 29.5063C51.3691 29.4943 48.2144 26.1364 48.2144 22.0496Z"
            fill={'var(--default-font)'}
          />
          <path
            d="M65.5176 11.8676C65.5176 10.9714 66.2226 10.2305 67.1547 10.2305C68.0868 10.2305 68.8157 10.9594 68.8157 11.8676V16.9821C69.7359 15.6437 71.0743 14.4727 73.3089 14.4727C76.5473 14.4727 78.4354 16.6475 78.4354 19.9815V27.6653C78.4354 28.5854 77.7303 29.3024 76.7982 29.3024C75.8661 29.3024 75.1372 28.5974 75.1372 27.6653V20.9853C75.1372 18.7507 74.0259 17.4721 72.0541 17.4721C70.1541 17.4721 68.8157 18.8104 68.8157 21.0451V27.6653C68.8157 28.5854 68.0748 29.3024 67.1547 29.3024C66.2585 29.3024 65.5176 28.5974 65.5176 27.6653V11.8676Z"
            fill={'var(--default-font)'}
          />
          <path
            d="M83.3823 11.8676C83.3823 10.9714 84.0874 10.2305 85.0195 10.2305C85.9396 10.2305 86.6805 10.9594 86.6805 11.8676V21.4036L92.8705 15.333C93.3007 14.9029 93.7189 14.628 94.2806 14.628C95.1529 14.628 95.7265 15.333 95.7265 16.1217C95.7265 16.6714 95.4517 17.0777 94.9976 17.5079L91.1856 21.0212L95.5473 26.7212C95.846 27.1275 95.9774 27.4263 95.9774 27.8087C95.9774 28.7049 95.2724 29.3024 94.424 29.3024C93.7667 29.3024 93.3843 29.0873 92.978 28.5615L88.8673 23.0885L86.6924 25.1558V27.6653C86.6924 28.5854 85.9515 29.3024 85.0314 29.3024C84.1352 29.3024 83.3943 28.5974 83.3943 27.6653V11.8676H83.3823Z"
            fill={'var(--default-font)'}
          />
          <path
            d="M99.9453 11.8676C99.9453 10.9714 100.65 10.2305 101.582 10.2305C102.503 10.2305 103.243 10.9594 103.243 11.8676V21.4036L109.433 15.333C109.864 14.9029 110.282 14.628 110.844 14.628C111.716 14.628 112.289 15.333 112.289 16.1217C112.289 16.6714 112.015 17.0777 111.561 17.5079L107.749 21.0212L112.11 26.7212C112.409 27.1275 112.54 27.4263 112.54 27.8087C112.54 28.7049 111.835 29.3024 110.987 29.3024C110.33 29.3024 109.947 29.0873 109.541 28.5615L105.43 23.0885L103.255 25.1558V27.6653C103.255 28.5854 102.515 29.3024 101.594 29.3024C100.698 29.3024 99.9573 28.5974 99.9573 27.6653V11.8676H99.9453Z"
            fill={'var(--default-font)'}
          />
          <path
            d="M34.664 37.6552C39.2886 33.7954 42.2282 27.9997 42.2282 21.511C42.2282 9.89573 32.8237 0.491211 21.2204 0.491211C9.61715 0.491211 0.200684 9.89573 0.200684 21.511C0.200684 29.4218 4.58627 36.3168 11.0511 39.9018L34.664 37.6552Z"
            fill="black"
          />
          <path
            d="M26.7046 15.3328C21.4228 13.6718 6.60498 14.8309 6.60498 14.8309C6.60498 17.2806 8.58865 19.2643 11.0384 19.2762V20.7461V39.8778C14.0497 41.5507 17.5152 42.5067 21.2077 42.5067C26.3222 42.5067 31.0065 40.6784 34.6512 37.6431L27.5411 23.7455L29.6801 19.575C30.7914 17.424 29.0348 16.0617 26.7046 15.3328Z"
            fill="white"
          />
          <path
            d="M21.1362 19.2754C19.9054 19.2754 18.9136 20.2672 18.9136 21.4981C18.9136 22.7289 19.9054 23.7207 21.1362 23.7207C22.3671 23.7207 23.3589 22.7289 23.3589 21.4981C23.3589 20.2672 22.3671 19.2754 21.1362 19.2754Z"
            fill="white"
          />
          <path
            d="M21.2195 28.1666C24.8956 28.1666 27.8756 25.1866 27.8756 21.5105C27.8756 17.8345 24.8956 14.8545 21.2195 14.8545C17.5435 14.8545 14.5635 17.8345 14.5635 21.5105C14.5635 25.1866 17.5435 28.1666 21.2195 28.1666Z"
            fill="black"
          />
          <path
            d="M34.0065 20.1481L29.5253 19.8613L27.2906 24.223C27.2787 24.2469 27.3026 24.1991 27.2906 24.223C26.4064 26.135 24.6019 27.6048 22.4868 28.0231L33.8511 27.3658C34.4486 27.3061 35.058 27.5451 35.4046 28.035C35.6197 28.3338 35.7272 28.7162 35.7033 29.1105C35.6914 29.2897 35.6555 29.457 35.5958 29.6004L35.6077 29.6124L37.4002 26.5532C39.0254 23.8287 37.1851 20.3513 34.0065 20.1481Z"
            fill="#F7941D"
          />
          <path
            d="M21.1362 19.2754C19.9054 19.2754 18.9136 20.2672 18.9136 21.4981C18.9136 22.7289 19.9054 23.7207 21.1362 23.7207C22.3671 23.7207 23.3589 22.7289 23.3589 21.4981C23.3589 20.2672 22.3671 19.2754 21.1362 19.2754Z"
            fill="white"
          />
          <path
            d="M11.0381 28.167V39.8898C13.2369 41.1087 15.6866 41.9332 18.2677 42.2917L11.0381 28.167Z"
            fill="#E7E7E7"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="112.328"
              height="42.0276"
              fill="white"
              transform="translate(0.200684 0.491211)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );

  if (disabledLink) {
    return (
      <div style={{ cursor: showPointerCursor ? 'pointer' : '' }}>{logo}</div>
    );
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
