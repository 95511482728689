'use client';
/*
 * Documentation:
 * Learn More Accordion — https://app.subframe.com/library?component=Learn+More+Accordion_774ff586-8d27-4ab5-ae6f-ef03540368c6
 * Accordion — https://app.subframe.com/library?component=Accordion_d2e81e20-863a-4027-826a-991d8910efd9
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { Accordion } from './Accordion';

interface LearnMoreAccordionRootProps
  extends React.ComponentProps<typeof Accordion> {
  title?: string;
  children?: React.ReactNode;
  className?: string;
}

const LearnMoreAccordionRoot = React.forwardRef<
  HTMLElement,
  LearnMoreAccordionRootProps
>(function LearnMoreAccordionRoot(
  { title, children, className, ...otherProps }: LearnMoreAccordionRootProps,
  ref,
) {
  return (
    <Accordion
      className={SubframeCore.twClassNames(
        'group/774ff586 cursor-pointer',
        className,
      )}
      trigger={
        <div className="flex w-full items-center gap-2 pt-2 pr-3 pb-2 pl-3">
          <SubframeCore.Icon
            className="text-body font-body text-subtext-color group-hover/774ff586:text-default-font"
            name="FeatherLightbulb"
          />
          {title ? (
            <span className="w-full grow shrink-0 basis-0 text-body font-body text-subtext-color group-hover/774ff586:text-default-font">
              {title}
            </span>
          ) : null}
          <Accordion.Chevron />
        </div>
      }
      defaultOpen={false}
      ref={ref as any}
      {...otherProps}
    >
      {children ? (
        <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-start gap-2 pr-3 pb-2 pl-8">
          {children}
        </div>
      ) : null}
    </Accordion>
  );
});

export const LearnMoreAccordion = LearnMoreAccordionRoot;
