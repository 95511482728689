'use client';
/*
 * Documentation:
 * New Cluster Card — https://app.subframe.com/library?component=New+Cluster+Card_8fbbeb4c-80ff-41d3-aead-eab800f08cac
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface NewClusterCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const NewClusterCardRoot = React.forwardRef<
  HTMLElement,
  NewClusterCardRootProps
>(function NewClusterCardRoot(
  { className, ...otherProps }: NewClusterCardRootProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/8fbbeb4c flex h-full w-full cursor-pointer flex-col items-center justify-center gap-2 rounded border border-dashed border-neutral-border pt-6 pr-6 pb-6 pl-6 hover:border hover:border-dashed hover:border-neutral-300',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <SubframeCore.Icon
        className="text-subheader font-subheader text-neutral-500 group-hover/8fbbeb4c:text-neutral-600"
        name="FeatherPlus"
      />
      <span className="text-body-bold font-body-bold text-neutral-500 group-hover/8fbbeb4c:text-neutral-600">
        Add Cluster
      </span>
    </div>
  );
});

export const NewClusterCard = NewClusterCardRoot;
