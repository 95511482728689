import { SortDirection } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

type ARInstance = {
  affected_resource_summary: {
    clusters: number;
    namespaces: number;
  };
  availability_impact: string;
  category: string[];
  components: { addons: string[] };
  id: string;
  labels?: any[];
  need_attention: boolean;
  severity: string;
  status: string;
  title: string;
};

const severityScore: Record<string, number> = {
  critical: 4,
  high: 3,
  medium: 2,
  low: 1,
};
type SortOrder = 'asc' | 'desc';

type TableData = ARInstance[];

export default function useARTableSort(
  data: TableData,
  order: SortOrder,
  orderByColumnName: string,
) {
  const [sortOrder, setSortOrder] = useState<SortDirection>(order);
  const [orderBy, setOrderBy] = useState<string>(orderByColumnName);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function signaturesComparator(a: ARInstance, b: ARInstance) {
    const aId = parseInt(a.id.split('-')[2], 10);
    const bId = parseInt(b.id.split('-')[2], 10);
    if (bId < aId) {
      return -1;
    } else if (bId > aId) {
      return 1;
    } else {
      return 0;
    }
  }

  function titlesComparator(a: ARInstance, b: ARInstance) {
    if (b.title < a.title) {
      return -1;
    } else if (b.title > a.title) {
      return 1;
    } else {
      return 0;
    }
  }

  function clustersComparator(a: ARInstance, b: ARInstance) {
    if (
      b.affected_resource_summary.clusters <
      a.affected_resource_summary.clusters
    ) {
      return -1;
    } else if (
      b.affected_resource_summary.clusters >
      a.affected_resource_summary.clusters
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  function namespacesComparator(a: ARInstance, b: ARInstance) {
    if (
      b.affected_resource_summary.namespaces <
      a.affected_resource_summary.namespaces
    ) {
      return -1;
    } else if (
      b.affected_resource_summary.namespaces >
      a.affected_resource_summary.namespaces
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  const sortSeverityCallback = useCallback(
    (a: ARInstance, b: ARInstance): number => {
      if (
        severityScore[a.severity.toLowerCase()] >
        severityScore[b.severity.toLowerCase()]
      ) {
        return -1;
      } else if (severityScore[a.severity] === severityScore[b.severity]) {
        const aId = parseInt(a.id.split('-')[2], 10);
        const bId = parseInt(b.id.split('-')[2], 10);
        if (aId > bId) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    },
    [],
  );

  const getComparator = useCallback(
    (order: any, orderBy: any) => {
      switch (orderBy) {
        case 'severity':
          return order === 'desc'
            ? (a: ARInstance, b: ARInstance) => sortSeverityCallback(a, b)
            : (a: ARInstance, b: ARInstance) => -sortSeverityCallback(a, b);
        case 'title':
          return order === 'desc'
            ? (a: ARInstance, b: ARInstance) => titlesComparator(a, b)
            : (a: ARInstance, b: ARInstance) => -titlesComparator(a, b);

        case 'clusters':
          return order === 'desc'
            ? (a: ARInstance, b: ARInstance) => clustersComparator(a, b)
            : (a: ARInstance, b: ARInstance) => -clustersComparator(a, b);
        case 'namespaces':
          return order === 'desc'
            ? (a: ARInstance, b: ARInstance) => namespacesComparator(a, b)
            : (a: ARInstance, b: ARInstance) => -namespacesComparator(a, b);

        default:
          return order === 'desc'
            ? (a: ARInstance, b: ARInstance) => signaturesComparator(a, b)
            : (a: ARInstance, b: ARInstance) => -signaturesComparator(a, b);
      }
    },
    [sortSeverityCallback],
  );

  const stableSort = (
    array: any[],
    comparator: (sortOrder: any, orderBy: any) => number,
  ) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return 0;
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const dataToShow: ARInstance[] = useMemo(() => {
    if (data) {
      return data.sort(sortSeverityCallback);
    } else {
      return [];
    }
  }, [data, sortSeverityCallback]);

  const sortedData = useMemo(() => {
    const data = stableSort(dataToShow, getComparator(sortOrder, orderBy));
    return data;
  }, [dataToShow, getComparator, sortOrder, orderBy]);

  return {
    sortOrder,
    orderBy,
    handleRequestSort,
    sortedData,
    setSortOrder,
  };
}
