import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import useDialog from 'hooks/useDialog';
import { formatDistanceToNow, fromUnixTime, getUnixTime } from 'date-fns';
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { NAVBAR, SPINNER } from '../config';
import Scrollbar from 'components/Scrollbar';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel';
import Iconify from 'components/Iconify';
import { TASK, USER } from 'models/quickadd';
import { DialogType } from 'models/dialog';
import {
  InviteTeamMemberDialog,
  JiraIntegrationDialog,
} from 'components/dialogs';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import Centered from 'components/Centered';
import { useHoverDirty } from 'hooks/useDirtyHover';
import { useDebounce } from 'react-use';
import { useSWRConfig } from 'swr';
import { quickStartApi } from 'api/constants';
import { Button } from 'subframe/index';
import { RouterLink } from './RouterLink';
import { AtomicTooltip } from './design-system';

// TODO we should probably add dynamic paths to the API
const SAMPLE_ROUTE = '/clusters/new';
const TEAMS_INVITE_KEY = 'invite_team_member';

const NO_ROUTE_KEYS = {};

type GetCardsProps = {
  basePath: string;
  tasks: TASK[];
  setDialogType: (type: DialogType) => void;
  updateQuickStartState: (value: boolean) => void;
  onCloseSidebar: VoidFunction;
};

type QuickStartProps = {
  onCloseSidebar: VoidFunction;
  isTaskModalOpen: boolean;
  isCollapse: boolean | undefined;
  tasks: TASK[];
  tasksleft: number;
  loading: boolean;
  updateQuickStartState: (value: boolean) => void;
};

type TitleGridProps = {
  title: string;
};

type CardProps = {
  title: string;
  description: string;
  onClose?: VoidFunction;
};

type ProgressCardProps = {
  title: string;
  date?: string;
  user?: USER;
};

const getSplitHyphenText = (text: string) => {
  return text.toLowerCase().split(' ').join('-');
};

// ----------------------------------------------------------------------

const TitleSectionGrid = ({ title }: TitleGridProps) => {
  const theme = useTheme();

  return (
    <Grid
      sx={{
        pt: 3,
      }}
      container
      item
      xs={4}
    >
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontWeight: theme.typography.fontWeightBold,
        }}
        variant="h5"
      >
        {title}
      </Typography>
    </Grid>
  );
};

// ----------------------------------------------------------------------

const QuickStartTaskCard = ({ title, description, onClose }: CardProps) => {
  const theme = useTheme();
  const { logEvent } = AnalyticsEventLogger();

  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(ref);

  useDebounce(
    () => {
      if (isHovering) {
        logEvent(`quick-start-${getSplitHyphenText(title)}-hover`);
      }
    },
    1000,
    [isHovering],
  );

  return (
    <Box
      onClick={() => logEvent(`quick-start-${getSplitHyphenText(title)}-click`)}
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
        borderRadius: '4px',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.grey[600]}`,
        '&:hover': {
          boxShadow: `0 0 10px ${theme.palette.grey[400]}, 0 0 5px ${theme.palette.grey[400]}`,
        },
      }}
    >
      <Stack
        ref={ref}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          width: '100%',
        }}
      >
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography
          sx={{ color: theme.palette.grey[200] }}
          variant="body2"
          gutterBottom
        >
          {description}
        </Typography>
        <Grid item container xs={12} justifyContent="right" alignItems="center">
          <Button
            onClick={() => {
              logEvent(`quick-start-${getSplitHyphenText(title)}-click`);
              onClose && onClose();
            }}
            size="medium"
          >
            Start
          </Button>
        </Grid>
      </Stack>
    </Box>
  );
};

// ----------------------------------------------------------------------

const getInitial = (name: string) => {
  return name.charAt(0).toUpperCase();
};

const QuickStartProgressCard = ({ title, date }: ProgressCardProps) => {
  const [dateToolTipOpen, setDateToolTipOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(ref);
  const { logEvent } = AnalyticsEventLogger();

  useDebounce(
    () => {
      if (isHovering) {
        logEvent(`quick-start-${getSplitHyphenText(title)}-hover`);
      }
    },
    1000,
    [isHovering],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();

  return (
    <Box
      onClick={() => logEvent(`quick-start-${getSplitHyphenText(title)}-click`)}
      ref={ref}
      component="div"
      sx={{
        display: 'flex',
        width: '100%',
        borderRadius: '4px',
        cursor: 'pointer',
        pt: 1.5,
        pb: 1.5,
        px: 1.5,
        border: `1px solid ${theme.palette.grey[600]}`,
        '&:hover': {
          boxShadow: `0 0 10px ${theme.palette.grey[400]}, 0 0 5px ${theme.palette.grey[400]}`,
        },
      }}
    >
      <Grid item container xs={12} alignItems="center">
        <Grid item container justifyContent="center" xs={1}>
          <Iconify
            icon={'akar-icons:circle-check-fill'}
            sx={{
              flex: '0 0 auto',
              color: theme.palette.success.main,
            }}
          />
        </Grid>
        <Grid item container xs={6}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        {date ? (
          <Grid item container justifyContent="end" xs={5}>
            <AtomicTooltip tooltipContent={date}>
              <Typography
                variant="body2"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                onMouseEnter={() => setDateToolTipOpen(true)}
                onMouseLeave={() => setDateToolTipOpen(false)}
              >
                {date}
              </Typography>
            </AtomicTooltip>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

const getCards = ({
  tasks,
  basePath,
  setDialogType,
  onCloseSidebar,
}: GetCardsProps) => {
  let startCards: JSX.Element[] = [];
  let completedCards: JSX.Element[] = [];
  const nonRouteTasks = tasks.filter((item) => {
    const routeKey = item.key as keyof typeof NO_ROUTE_KEYS;
    return NO_ROUTE_KEYS[routeKey];
  });

  const routeTasks = tasks.filter((item) => {
    const routeKey = item.key as keyof typeof NO_ROUTE_KEYS;
    return !NO_ROUTE_KEYS[routeKey];
  });

  routeTasks.forEach((task) => {
    const { title, status, description, created, user } = task;
    const date = created ? created : getUnixTime(new Date());

    const dataFormated = formatDistanceToNow(fromUnixTime(date), {
      addSuffix: true,
    });
    const handleCardClickEvent = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      if (event?.stopPropagation) {
        event.stopPropagation();

        setDialogType('invite-member-to-team');
      }
    };

    const newPath = `${basePath}${SAMPLE_ROUTE}`;
    if (status) {
      // TODO can remove all these if/ thens when the routes / actions are moved to the backend.
      if (task?.key === TEAMS_INVITE_KEY) {
        completedCards.push(
          <div
            onClick={(event) => {
              handleCardClickEvent(event);
            }}
          >
            <QuickStartProgressCard
              title={title}
              date={dataFormated}
              user={user}
            />
          </div>,
        );
      } else {
        completedCards.push(
          <QuickStartProgressCard
            title={title}
            date={dataFormated}
            user={user}
          />,
        );
      }
    } else {
      if (task?.key === TEAMS_INVITE_KEY) {
        startCards.push(
          <div
            onClick={(event) => {
              handleCardClickEvent(event);
            }}
          >
            <QuickStartTaskCard description={description} title={title} />
          </div>,
        );
      } else {
        startCards.push(
          <RouterLink to={newPath}>
            <QuickStartTaskCard
              description={description}
              title={title}
              onClose={onCloseSidebar}
            />
          </RouterLink>,
        );
      }
    }
  });
  nonRouteTasks.forEach((task) => {
    const { title, user, created, status, description } = task;
    const date = created ? created : new Date().getTime();
    const dataFormated = formatDistanceToNow(fromUnixTime(date), {
      addSuffix: true,
    });
    if (status) {
      completedCards.push(
        <QuickStartProgressCard
          title={title}
          date={dataFormated}
          user={user}
        />,
      );
    } else {
      startCards.push(
        <QuickStartTaskCard description={description} title={title} />,
      );
    }
  });

  if (startCards.length > 0) {
    startCards.unshift(<TitleSectionGrid title="NEXT STEPS" />);
  }
  if (completedCards.length > 0) {
    completedCards.unshift(<TitleSectionGrid title="COMPLETED" />);
  }

  return [...startCards, ...completedCards];
};

// ----------------------------------------------------------------------

export default function QuickTaskDrawer({
  isTaskModalOpen,
  isCollapse,
  onCloseSidebar,
  tasks,
  tasksleft,
  loading,
  updateQuickStartState,
}: QuickStartProps) {
  const theme = useTheme();
  const { setDialogType, currentDialog } = useDialog();

  const basePath = useAccountIdRoute(`/orgs/:orgId/accounts/:accountId`);
  const cards = useMemo(
    () =>
      getCards({
        tasks,
        basePath,
        setDialogType,
        updateQuickStartState,
        onCloseSidebar,
      }),
    [tasks, basePath, setDialogType, updateQuickStartState],
  );

  const totalTasksNumber = tasks.length;
  const { logEvent } = AnalyticsEventLogger();
  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (isTaskModalOpen) logEvent('quick-start-drawer');
  }, [isTaskModalOpen, logEvent]);

  const fetchQuickTasks = async () => {
    mutate(quickStartApi);
  };

  return (
    <Drawer
      id="quick-start-drawer"
      open={isTaskModalOpen}
      onClose={onCloseSidebar}
      PaperProps={{
        sx: {
          width: NAVBAR.DASHBOARD_WIDTH * 1.5,
          position: 'relative',
          backgroundColor: theme.palette.grey[800],
          left: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
      }}
    >
      {!loading ? (
        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': {
              height: 1,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <Stack
            spacing={1}
            sx={{
              pt: 3,
              pb: 2,
              px: 4,
              flexShrink: 0,
            }}
          >
            <Grid
              sx={{ pt: 3 }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid alignItems="center" container item xs={4}>
                <CircularProgressWithLabel
                  value={totalTasksNumber - tasksleft}
                  total={totalTasksNumber}
                  radius={40}
                >
                  {totalTasksNumber - tasksleft}
                </CircularProgressWithLabel>
              </Grid>

              <Grid alignItems="center" item xs={8}>
                <Typography variant="h4" gutterBottom>
                  Quick Start
                </Typography>
                <Typography
                  sx={{ color: theme.palette.grey[200] }}
                  variant="body2"
                >
                  {`${tasksleft} Remaining Task${tasksleft > 1 ? 's' : ''}`}
                  <br />
                  Take full advantage of Chkk&apos;s powerful Availability Risk
                  detection features
                </Typography>
              </Grid>
            </Grid>
            <>{cards}</>
          </Stack>
        </Scrollbar>
      ) : (
        <Centered>
          <CircularProgress />
        </Centered>
      )}
      {/* Dialogs Start */}
      <InviteTeamMemberDialog
        handleOpenChange={(open) => {
          if (!open) {
            fetchQuickTasks();
            setDialogType('');
          }
        }}
        open={currentDialog === 'invite-member-to-team'}
      />
      <JiraIntegrationDialog
        handleClose={fetchQuickTasks}
        open={currentDialog === 'jira-integration'}
      />
    </Drawer>
  );
}
