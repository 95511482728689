import { Stack } from './Stack';
import React from 'react';
import classNames from 'classnames';
import styles from './Card.module.scss';

interface Props extends React.ComponentProps<typeof Stack> {
  variant?: 'default' | 'secondary';
  padding?: 'none' | 'normal' | 'large';

  // when true, this will add hover effects and cursor pointer
  interactive?: boolean;
}

export const Card = React.forwardRef<HTMLDivElement, Props>(function Card(
  props,
  ref,
) {
  const {
    className,
    interactive = false,
    variant = 'default',
    padding = 'normal',
    ...otherProps
  } = props;

  return (
    <Stack
      ref={ref}
      className={classNames(className, styles.root, {
        [styles.interactive]: interactive,
        [styles.default]: variant === 'default',
        [styles.secondary]: variant === 'secondary',
        [styles.paddingNone]: padding === 'none',
        [styles.paddingNormal]: padding === 'normal',
        [styles.paddingLarge]: padding === 'large',
      })}
      {...otherProps}
    />
  );
});
