import * as Sentry from '@sentry/browser';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export const axiosInstance = Axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'https://api.chkk.dev/v1',
}); // use your own URL here or environment variable

// Axios Error Codes - https://github.com/axios/axios#handling-errors
// Response Interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Handle successful response
    return response;
  },
  (error: AxiosError) => {
    const extra = {
      request: {
        url: error?.config?.url,
        method: error?.config?.method,
        params: JSON.stringify(error?.config?.params),
        data: error.config?.data
          ? JSON.stringify(error.config.data)
          : 'unknown',
        responseCode: error.response?.status || 'unknown',
      },
      code: error.code,
      httpStatus: error.response?.status,
      isNetworkError: false,
    };
    // Redirect to log in if a 403 Forbidden response is received,
    // SWR won't honor other mechanisms to redirect to log in
    if (error?.response?.status === 403) {
      // redirect to '/login'
      window.location.href = '/login';
      // unset auth token in localstorage
      localStorage.removeItem('chkk_user_token');
      return; // Stop the promise chain here
    }
    // Handle specific error cases
    if (
      error.code === 'ECONNABORTED' || // request timeout
      error.code === 'ETIMEDOUT' || // request timeout
      error.code === 'ECONNREFUSED' || // request to establish a connection with another service was refused and that something blocks the request
      error.code === 'ECONNRESET' || //  the server unexpectedly closed the connection and the request to the server was not fulfilled
      error.code === 'ENOTFOUND' || // will handle DNS errors
      error.code === 'ERR_NETWORK' // connection refuse or connection reset is combined by Axios as ERR_NETWORK
    ) {
      extra.isNetworkError = true;
      Sentry.captureException(error, { extra });
    } else {
      // capture Sentry exception with detail of request, params, error code, response code
      Sentry.captureException(error, { extra });
    }
    return Promise.reject(error);
  },
);

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // transitional options for backward compatibility that may be removed in the newer versions
    // https://github.com/axios/axios#request-config
    config.transitional = {
      forcedJSONParsing: true, // always parse response body as JSON
      silentJSONParsing: false, // enable warning logs for JSON parsing errors
      // throw ETIMEDOUT error instead of generic ECONNABORTED on request timeouts
      clarifyTimeoutError: true,
    };
    const source = new AbortController();
    config.signal = source.signal;
    config.paramsSerializer = {
      indexes: null, // Axios now supports arrayFormat: 'repeat` by default using indexes: null
      // https://github.com/axios/axios/issues/5058#issuecomment-1272107602
    };
    return config;
  },
  (error: AxiosError) => {
    // Request Setup Error
    Sentry.captureException(error, {
      extra: {
        request: {
          url: error?.config?.url,
          method: error?.config?.method,
          params: JSON.stringify(error?.config?.params),
          data: error.config?.data
            ? JSON.stringify(error.config.data)
            : 'unknown',
        },
      },
    });
    return Promise.reject(error);
  },
);

// add a second `options` argument here if you want to pass extra options to each generated query
export const customInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<ResponseType<T>> => {
  const response = await axiosInstance<T>({
    ...config,
    ...options,
  });
  return {
    __status: response?.status,
    ...response?.data,
  };
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

// Or, in case you want to wrap the body type (optional)
// (if the custom instance is processing data before sending it, like changing the case for example)
export type BodyType<BodyData> = BodyData;

export type ResponseType<ResponseData = any> = ResponseData & {
  __status: number;
};
