import { Box } from '@mui/material';
import Loader from 'react-loader-spinner';

export const LoaderContainer = () => (
  <Box
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    width={'100%'}
    height={`80vh`}
  >
    <ConsoleLoader />
  </Box>
);

export const ConsoleLoader = () => (
  <Loader type="TailSpin" color="#ffb224" height={80} width={80} />
);
