// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
}

export default function RHFTextField({
  name,
  ...other
}: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          sx={{
            '& .MuiInputLabel-root': {
              whiteSpace: 'pre-wrap',
            },
            '& .Mui-focused': { whiteSpace: 'nowrap !important' },
          }}
          {...other}
        />
      )}
    />
  );
}
