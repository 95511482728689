export const baseUrl =
  import.meta.env.VITE_API_URL || 'https://api.chkk.dev/v1';

export const baseHostPort = `${new URL(baseUrl).hostname}:443`;
export const baseHostPath = `${new URL(baseUrl).pathname}`;

export const loginApi = baseUrl + '/login';
export const clustersApi = baseUrl + '/clusters';
export const feedbackApi = baseUrl + '/feedbacks';
export const analyticsApi = baseUrl + '/chkk-analytics';
export const quickStartApi = baseUrl + `/quick-start`;

export const ingestionTokensApi = baseUrl + '/ingestion_scopes/{scopeId}/token';

export const teamsListApi = `${baseUrl}/accounts/default/teams`;

export const invitationsListApi = `${baseUrl}/accounts/default/teams/default/invitations`;

export const orgsSettingsApi = `${baseUrl}/orgs/{orgSlug}`;
export const acceptInviteApi = `${baseUrl}/accept/{inviteKey}`;

export const integrationsApi = `${baseUrl}/integrations`;
export const integrationApi = `${baseUrl}/integrations/{integrationId}`;
export const integrationsAlertManagerConfigApi = `${baseUrl}/integrations/{integrationId}/alert_manager_config`;
export const prometheusVerificationApi = `${integrationsApi}/prometheus/status?integration_name=prometheus`;
export const datadogVerificationApi = `${integrationsApi}/prometheus/status?integration_name=datadog`;
export const quickStartProdApi = `${baseUrl}/quick-start`;

export const kbaDetailsApi = `${baseUrl}/kbas/{signatureId}`;

export const ticketsApi = `${baseUrl}/tickets`;
export const larStatusApi = `${baseUrl}/clusters/{clusterId}/lars/status/{signatureId}`;
export const larIgnoreApi = `${baseUrl}/clusters/{clusterId}/lars/status/{signatureId}/ignore`; //put post - ignore
export const larAcknowledgeApi = `${baseUrl}/clusters/{clusterId}/lars/status/{signatureId}/acknowledge`; //put post acknowledge
export const allLarsStatusApi = `${baseUrl}/clusters/{clusterId}/lars/status`; //get list of lars status

//Flipped Arsigs View
export const availabilityRisksApi = `${baseUrl}/availability_risks`;
