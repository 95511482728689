import { Stack } from './Stack';
import React from 'react';
import classNames from 'classnames';

interface Props extends React.ComponentProps<typeof Stack> {}

/**
 * Use <Container /> to wrap content that should be constrained to the page width.
 */
export const Container = React.forwardRef<HTMLDivElement, Props>(
  function Container(props, ref) {
    const { className, ...otherProps } = props;

    return (
      <Stack
        ref={ref}
        // NOTE: page-contents is defined by Atomic in variables.css
        className={classNames(className, 'page-contents')}
        {...otherProps}
      />
    );
  },
);
