import { Link as RouterLink } from 'react-router-dom';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useARTableSort from 'hooks/useARTableSort';
import { severityBadgeMap } from 'models/scans';
import { useEffect, useState } from 'react';
import Label from './Label';
import { VisuallyHidden } from 'components/utils/VisuallyHidden';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { ARViews } from 'models/general';
import styles from './AvailabilityRisksTable.module.scss';
import { Stack } from 'components/utils/Stack';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
}));
const StyledLabelCell = styled(TableCell)(({ theme }) => ({
  '& span': {
    color: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[900],
    borderRadius: '24px',
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    borderBottom: 'none',
  },
  borderBottom: 'none',
}));
const StyledSeverityCell = styled(TableCell)(({ theme }) => ({
  '& span': {
    borderRadius: '6px',
    fontFamily: theme.typography.fontFamily,
    textTransform: 'capitalize',
    fontWeight: 400,
    borderBottom: 'none',
  },
  borderBottom: 'none',
}));

const AvailabilityRisksTableRow = ({
  category,
  key,
  data,
  view,
}: {
  category: string;
  key: string;
  data: any;
  view: ARViews;
}) => {
  const theme = useTheme();
  const { logEvent } = AnalyticsEventLogger();
  const addOns = data?.components?.addons?.join(', ') || '';
  const rowLink = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/availability-risks/${data?.id}?view=${view}`,
  );

  return (
    <TableRow
      id="arsigs-table-row"
      sx={{
        height: '5rem',
        boxShadow: 'none',
        borderBottom: 'none',
        cursor: 'pointer',
        '&:hover': {
          '& .hover-change': {
            color: theme.palette.primary.main,
          },
          backgroundColor: theme.palette.grey[800],
        },
        textDecoration: 'none',
      }}
      onClick={() => {
        logEvent('availability-risks-table-row-click', {
          larId: data?.id,
          category: category,
        });
      }}
      to={rowLink}
      component={RouterLink}
    >
      <StyledTableBodyCell className="hover-change">
        <Stack>
          <Typography id="arsigs-table-row-id">
            {data?.id?.toUpperCase()}
          </Typography>
          <Typography id="arsigs-table-row-addons" variant="body">
            {addOns}
          </Typography>
        </Stack>
      </StyledTableBodyCell>
      <StyledTableBodyCell id="arsigs-table-row-title" className="hover-change">
        {data?.title}
      </StyledTableBodyCell>
      {(category?.toLowerCase() === 'misconfigurations' ||
        category?.toLowerCase() === 'defects') && (
        <StyledSeverityCell id="arsigs-table-row-severity">
          <Label
            variant={'ghost'}
            color={
              severityBadgeMap[data?.severity?.toLowerCase()] || ('info' as any)
            }
          >
            {data?.severity?.slice(0, 1)?.toUpperCase() +
              data?.severity?.slice(1)}
          </Label>
        </StyledSeverityCell>
      )}
      <StyledTableBodyCell
        id="arsigs-table-row-addons-count"
        className="hover-change"
        sx={{ textAlign: 'center' }}
      >
        {data?.components?.addons?.length || 0}
      </StyledTableBodyCell>
      <StyledTableBodyCell
        id="arsigs-table-row-clusters-count"
        className="hover-change"
        sx={{ textAlign: 'center' }}
      >
        {data?.affected_resource_summary?.clusters || 0}
      </StyledTableBodyCell>
      <StyledTableBodyCell
        id="arsigs-table-row-namespaces-count"
        className="hover-change"
        sx={{ textAlign: 'center' }}
      >
        {data?.affected_resource_summary?.namespaces || 0}
      </StyledTableBodyCell>
    </TableRow>
  );
};

export default function AvailabilityRisksTable({
  className,
  loading,
  risks,
  category,
  view,
}: {
  className?: string;
  loading: boolean;
  risks: any;
  category: string;
  view: ARViews;
}) {
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { sortOrder, orderBy, handleRequestSort, sortedData, setSortOrder } =
    useARTableSort(risks, 'asc', 'id');
  const [displayData, setDisplayData] = useState(risks);
  let [isLoading, setIsLoading] = useState(loading);
  const { logEvent } = AnalyticsEventLogger();

  useEffect(() => {
    setDisplayData(sortedData);
    setIsLoading(loading);
  }, [risks, sortedData, loading]);

  useEffect(() => {
    if (orderBy !== 'id') {
      handleRequestSort('id');
    }
    setPage(0);
  }, [category, loading]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Stack className={className}>
      <TableContainer className={styles.table}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: theme.palette.grey[800],
              minHeight: '56px',
              boxShadow: 'inset 0px -1px 0px rgba(145, 158, 171, 0.24)',
            }}
          >
            <TableCell sx={{ width: '15%' }}>
              <TableSortLabel
                direction={
                  orderBy === 'id' ? (sortOrder as 'asc' | 'desc') : 'asc'
                }
                onClick={() => handleRequestSort('id')}
                active={orderBy === 'id'}
              >
                {orderBy === 'id' && (
                  <VisuallyHidden>
                    {sortOrder === 'asc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </VisuallyHidden>
                )}
                ID
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                width:
                  category?.toLowerCase() === 'misconfigurations' ||
                  category?.toLowerCase() === 'defects'
                    ? '40%'
                    : '50%',
              }}
            >
              <TableSortLabel
                direction={
                  orderBy === 'title' ? (sortOrder as 'asc' | 'desc') : 'asc'
                }
                onClick={() => handleRequestSort('title')}
                active={orderBy === 'title'}
              >
                {orderBy === 'title' && (
                  <VisuallyHidden>
                    {sortOrder === 'asc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </VisuallyHidden>
                )}
                Title
              </TableSortLabel>
            </TableCell>
            {(category?.toLowerCase() === 'misconfigurations' ||
              category?.toLowerCase() === 'defects') && (
              <TableCell sx={{ width: '10%' }}>
                <TableSortLabel
                  direction={
                    orderBy === 'severity'
                      ? (sortOrder as 'asc' | 'desc')
                      : 'asc'
                  }
                  onClick={() => handleRequestSort('severity')}
                  active={orderBy === 'severity'}
                >
                  {orderBy === 'severity' ? (
                    <VisuallyHidden>
                      {sortOrder === 'asc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </VisuallyHidden>
                  ) : null}
                  Severity
                </TableSortLabel>
              </TableCell>
            )}
            <TableCell sx={{ width: '10%', textAlign: 'center' }}>
              <Typography>Affected</Typography>
              <Typography> Add-ons</Typography>
            </TableCell>
            <TableCell sx={{ width: '10%', textAlign: 'center' }}>
              <TableSortLabel
                direction={
                  orderBy === 'clusters' ? (sortOrder as 'asc' | 'desc') : 'asc'
                }
                onClick={() => handleRequestSort('clusters')}
                active={orderBy === 'clusters'}
              >
                {orderBy === 'clusters' && (
                  <VisuallyHidden>
                    {sortOrder === 'asc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </VisuallyHidden>
                )}
                <Stack>
                  <Typography>Affected</Typography>
                  <Typography> Clusters</Typography>
                </Stack>
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ width: '10%', textAlign: 'center' }}>
              <TableSortLabel
                direction={
                  orderBy === 'namespaces'
                    ? (sortOrder as 'asc' | 'desc')
                    : 'asc'
                }
                onClick={() => handleRequestSort('namespaces')}
                active={orderBy === 'namespaces'}
              >
                {orderBy === 'namespaces' && (
                  <VisuallyHidden>
                    {sortOrder === 'asc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </VisuallyHidden>
                )}
                <Stack>
                  <Typography>Affected</Typography>
                  <Typography> Namespaces</Typography>
                </Stack>
              </TableSortLabel>
            </TableCell>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow
                sx={{
                  boxShadow: 'none',
                  borderBottom: 'none',
                  cursor: 'pointer',
                  height: '1rem',
                }}
              >
                <StyledTableBodyCell>
                  <Skeleton height={10} width={'90%'} sx={{ m: 1 }} />
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  <Skeleton height={10} width={'90%'} sx={{ m: 1 }} />
                </StyledTableBodyCell>
                {(category?.toLowerCase() === 'misconfigurations' ||
                  category?.toLowerCase() === 'defects') && (
                  <StyledTableBodyCell>
                    <Skeleton height={10} width={'90%'} sx={{ m: 1 }} />
                  </StyledTableBodyCell>
                )}
                <StyledTableBodyCell>
                  <Skeleton height={10} width={'90%'} sx={{ m: 1 }} />
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  <Skeleton height={10} width={'90%'} sx={{ m: 1 }} />
                </StyledTableBodyCell>
                <StyledTableBodyCell>
                  <Skeleton height={10} width={'90%'} sx={{ m: 1 }} />
                </StyledTableBodyCell>
              </TableRow>
            ) : (
              <>
                {displayData.length &&
                  displayData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((risk: any) => {
                      return (
                        <AvailabilityRisksTableRow
                          category={category}
                          key={risk?.id}
                          data={risk}
                          view={view}
                        />
                      );
                    })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isLoading ? null : (
        <TablePagination
          id="arsigs-category-table-pagination"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={risks?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ flex: '0 0 auto' }}
        />
      )}
    </Stack>
  );
}
