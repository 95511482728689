'use client';
/*
 * Documentation:
 * Stack — https://app.subframe.com/library?component=Stack_5e05597d-4609-4021-9519-74ac9a64f77b
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface StackRootProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const StackRoot = React.forwardRef<HTMLElement, StackRootProps>(
  function StackRoot({ className, ...otherProps }: StackRootProps, ref) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex flex-col items-start gap-1 bg-neutral-100 pt-3 pr-3 pb-3 pl-3 shadow-large',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      />
    );
  },
);

export const Stack = StackRoot;
