import classNames from 'classnames';
import styles from './WrapWithBadge.module.scss';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  badge: React.ReactNode;
}

export const WrapWithBadge = React.forwardRef<HTMLDivElement, Props>(
  function WrapWithBadge({ className, children, badge, ...otherProps }: Props) {
    return (
      <div className={classNames(styles.root, className)} {...otherProps}>
        {children}
        <div className={styles.badge}>{badge}</div>
      </div>
    );
  },
);
