import React from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { TextArea } from 'subframe/components/TextArea';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import styles from './AskK8sExpertDialog.module.scss';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'components/FormProvider';
import { SetStateFunction } from 'models/react';
import { submitFeedback } from 'api/frontend';
import { SubmitFeedbackRequest } from 'api/models';
import { toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';

interface AskK8sExpertFields {
  query: string;
  header?: string;
}
interface AskK8sExpertFieldsProps {
  header?: string;
  open: boolean;
  handleOpenChange: SetStateFunction<boolean>;
}

export const AskK8SExpertsDialog = (props: AskK8sExpertFieldsProps) => {
  const { header, open, handleOpenChange } = props;
  const { account, user } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();

  const { logEvent } = AnalyticsEventLogger();
  const SubmissionError =
    'Something went wrong while submitting your feedback. Please try again or escalate to Chkk';
  const FeedbackSchema = Yup.object().shape({
    query: Yup.string().required('Query is required').max(1000),
  });

  const defaultValues: AskK8sExpertFields = {
    query: '',
  };

  const methods = useForm<AskK8sExpertFields>({
    resolver: yupResolver(FeedbackSchema),
    defaultValues: defaultValues,
  });

  // Reset this form upon closing
  React.useEffect(() => {
    if (!open) {
      methods.reset(defaultValues);
    }
  }, [open]);

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const onSubmit = async (data: AskK8sExpertFields) => {
    try {
      const reqBody: SubmitFeedbackRequest = {
        title: 'k8sExpertRequest',
        details: JSON.stringify({
          query: data.query,
        }),
        user_email: user?.email || '',
      };
      await submitFeedback(reqBody, {
        headers: {
          Authorization: `Bearer ${account.token}`,
        },
      });
      logEvent('feedback-submitted');
      enqueueSnackbar('Feedback submitted successfully', {
        variant: 'success',
      });
      handleOpenChange(false);
    } catch (error) {
      Sentry.captureException(error, { level: 'log' });
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <Dialog.Content>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['stack-a6fb635a']}>
            <div className={styles['stack']}>
              <span className={styles['text']}>Tell us how we can help</span>
              <IconButton
                icon="FeatherX"
                size="medium"
                onClick={() => handleOpenChange(false)}
              />
            </div>
            <div className={styles['stack-96454e55']}>
              <TextArea
                id={'ask-k8s-expert-query'}
                className="h-full w-full grow shrink-0 basis-0"
                label="How can we help?"
                helpText={errors.query?.message}
                error={methods.getFieldState('query').invalid}
              >
                <TextArea.Input {...methods.register('query')} />
              </TextArea>
              <div className={styles['stack-ff6e2045']}>
                <Button
                  size="large"
                  variant="brand-primary"
                  icon={null}
                  loading={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
};
