export type Severity = 'critical' | 'high' | 'medium' | 'low';
export type SeverityBadge = 'Critical' | 'High' | 'Medim' | 'Low';
export type JiraPriority =
  | 'highest'
  | 'high'
  | 'medium'
  | 'low'
  | 'very-low'
  | 'lowest';

export type ARViews = 'example' | 'real';

export enum ClustersViewLayout {
  GRID = 'grid',
  LIST = 'list',
}
