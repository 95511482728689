import { Box, IconButton, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import useDialog from 'hooks/useDialog';

interface DialogHeaderProps {
  handleClose: () => void;
  header: string;
}

export const DialogHeader = ({ handleClose, header }: DialogHeaderProps) => {
  const { setDialogType } = useDialog();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '14px 18px',
        paddingLeft: '26px',
        paddingBottom: '0',
      }}
    >
      <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
        {header}
      </Typography>
      <IconButton
        onClick={() => {
          handleClose();
          setDialogType('');
        }}
      >
        <Iconify sx={{ width: 15, height: 15 }} icon="akar-icons:cross" />
      </IconButton>
    </Box>
  );
};
