import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { useLocation } from 'react-router-dom';
import { RouterLink } from './RouterLink';
import { Breadcrumbs } from 'subframe/index';

type Props = {
  rootName: string;
  rootPath: string;
};

export default function BreadCrumbsHeader({ rootName, rootPath }: Props) {
  const breadcrumbNameMap: { [key: string]: [string, string, string?] } = {
    slack: ['Slack', 'slack', 'logos:slack-icon'],
  };
  const path = useAccountIdRoute(rootPath);

  const location = useLocation();
  const paths = location.pathname.split('/');
  const pathnames = [paths[paths.length - 1]];
  const { logEvent } = AnalyticsEventLogger();

  return (
    <Breadcrumbs className="pb-6">
      <Breadcrumbs.Item>Configure</Breadcrumbs.Item>
      <Breadcrumbs.Divider />

      <RouterLink to={path}>
        <Breadcrumbs.Item
          data-cy="fleet-breadcrumb"
          onClick={() => {
            logEvent(`integrations-${rootName}-page`);
          }}
        >
          {rootName}
        </Breadcrumbs.Item>
      </RouterLink>
      <Breadcrumbs.Divider />
      {pathnames.map((value, index) => {
        const to = `${pathnames.slice(0, index + 1).join('/')}`;
        return (
          <RouterLink
            to={`${path}${breadcrumbNameMap[to][1]}`}
            key={`${value}-${index}`}
          >
            <Breadcrumbs.Item active={true}>
              {breadcrumbNameMap[to][0]}
            </Breadcrumbs.Item>
          </RouterLink>
        );
      })}
    </Breadcrumbs>
  );
}
