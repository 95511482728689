//write code for pagination component

import useLocalStorage from 'hooks/useLocalStorage';
import { SetStateFunction } from 'models/react';
import { useState } from 'react';
import { Pagination } from 'subframe/components/Pagination';
import { Select } from 'subframe/components/Select';
import { Button, SkeletonText } from 'subframe/index';

const ROWS_PER_PAGE = 5;

interface Props {
  page: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  totalItemsToDisplay: number;
  rowsPerPage?: number;
  isLoading?: boolean;
  settings?: {
    defaultRowsPerPage: number;
    pageSizeOptions?: number[];
    localStorageKey?: string;
    dataCyPrefix?: string;
  };
  setDisplayData?: SetStateFunction<T[]>;
}
export function getPaginatedChunk<T>(
  page: number,
  sortedData: T[] | undefined,
  rowsPerPage = ROWS_PER_PAGE,
): T[] | undefined {
  const start = page * rowsPerPage;
  const end = page * rowsPerPage + rowsPerPage;

  return sortedData?.slice(start, end);
}
export interface TablePaginationUserSettings {
  pageSize: number;
}

export const TablePagination = ({
  page,
  onChangePage,
  totalItemsToDisplay,
  rowsPerPage = ROWS_PER_PAGE,
  isLoading,
  settings,
  setDisplayData,
}: Props) => {
  const rowsPerPageOptions = settings?.pageSizeOptions || [5, 10, 15, 20, 25];

  const defaultUserSettings: TablePaginationUserSettings = {
    pageSize: settings?.defaultRowsPerPage || 25,
  };
  const [currentPage, setCurrentPage] = useState(0);

  const [userSettings, setUserSettings] = settings?.localStorageKey
    ? useLocalStorage<TablePaginationUserSettings>(
        settings?.localStorageKey,
        defaultUserSettings,
      )
    : useState<TablePaginationUserSettings>(defaultUserSettings);

  if (currentPage * userSettings?.pageSize > totalItemsToDisplay) {
    setCurrentPage(0);
  }

  const displayStart = page * rowsPerPage + 1;
  const displayEnd = Math.min((page + 1) * rowsPerPage, totalItemsToDisplay);

  return isLoading ? (
    <SkeletonText className="h-[22px] w-[40%]" />
  ) : (
    <Pagination
      data-cy={`${settings?.dataCyPrefix || ''}-table-pagination`}
      text={`Rows per Page`}
      rightText={`Showing ${displayStart} to ${displayEnd} of ${totalItemsToDisplay} results`}
      leftActions={
        <Select
          placeholder={`${userSettings?.pageSize}`}
          onValueChange={(value) => {
            setUserSettings((old) => {
              return { ...old, pageSize: Number(value) };
            });
          }}
        >
          {rowsPerPageOptions.map((option, idx) => (
            <Select.Item key={idx} value={String(option)}>
              {`${option}`}
            </Select.Item>
          ))}
        </Select>
      }
      nextButton={
        <Button
          variant="neutral-secondary"
          iconRight="FeatherChevronRight"
          size="medium"
          disabled={displayEnd === totalItemsToDisplay}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </Button>
      }
      previousButton={
        <Button
          variant="neutral-secondary"
          icon="FeatherChevronLeft"
          size="medium"
          disabled={displayStart === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </Button>
      }
    />
  );
};
