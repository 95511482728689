import { Container, Grid } from '@mui/material';
import Scrollbar from './Scrollbar';
import { useParams } from 'react-router';

import { ARSigsSeverityCounts, getNeedAttentionTooltip } from 'utils/filters';
import useSettings from 'hooks/useSettings';
import { ARSigCategoryCard } from 'components/ARSigCategoryCard';
import { Text } from '@subframe/core';
import { Stack } from 'components/utils/Stack';
import styles from './ClusterARSigsOverview.module.scss';
import { RouterLink } from './RouterLink';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { ClusterDetectedLar } from 'api/models';
import { useGate } from 'statsig-react';

type ARSigData = {
  UnignoredLARsCount: number;
  Critical: number;
  High: number;
  Medium: number;
  Low: number;
  NACount: number;
};

type ARSigOverviewCardProps = {
  title: string;
  type: string;
  icon: string;
  totalCount?: number;
  data?: ARSigData;
  hideSeverity?: boolean;
  loading?: boolean;
};

type ARSigOveriewProps = {
  DefectsCount?: number;
  MisconfigurationsCount?: number;
  SystemRequirementsCount?: number;
  DeprecationsCount?: number;
  UnsupportedVersionsCount?: number;
  VersionIncompatibilitiesCount?: number;
  BestPracticesCount?: number;
  UnignoredDefects?: ClusterDetectedLar[];
  UnignoredMisconfigurations?: ClusterDetectedLar[];
  UnignoredSystemRequirements?: ClusterDetectedLar[];
  UnignoredDeprecations?: ClusterDetectedLar[];
  UnignoredUnsupportedVersions?: ClusterDetectedLar[];
  UnignoredVersionIncompatibilities?: ClusterDetectedLar[];
  UnignoredBestPractices?: ClusterDetectedLar[];
  loading?: boolean;
};

function ARSigsOverviewCard({
  title,
  type,
  icon,
  totalCount,
  data,
  hideSeverity,
  loading,
}: ARSigOverviewCardProps) {
  let { clusterId } = useParams<{ clusterId: string }>();
  const linkTo = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/${clusterId}?view=${type}`,
  );

  const { RiskPopoverData } = ARSigCategoryCard;

  return (
    <RouterLink to={linkTo}>
      <ARSigCategoryCard
        title={title}
        icon={icon}
        tooltip={
          type !== 'guardrails' ? (
            <RiskPopoverData
              title={getNeedAttentionTooltip(type)}
              detailedCounts={!hideSeverity}
              critical={data?.Critical.toString()}
              high={data?.High.toString()}
              medium={data?.Medium.toString()}
              low={data?.Low.toString()}
              ignored={(
                (totalCount || 0) - (data?.UnignoredLARsCount || 0)
              ).toString()}
              total={data?.UnignoredLARsCount.toString()}
            />
          ) : undefined
        }
        subText={type === 'guardrails' ? 'Not Followed' : undefined}
        loading={loading || data === undefined || totalCount === undefined}
        NACounts={
          type === 'guardrails' ? data?.UnignoredLARsCount : data?.NACount
        }
      />
    </RouterLink>
  );
}

export default function ClusterARSigsOverview({
  DefectsCount,
  MisconfigurationsCount,
  SystemRequirementsCount,
  DeprecationsCount,
  UnsupportedVersionsCount,
  VersionIncompatibilitiesCount,
  BestPracticesCount,
  UnignoredDefects,
  UnignoredMisconfigurations,
  UnignoredSystemRequirements,
  UnignoredDeprecations,
  UnignoredUnsupportedVersions,
  UnignoredVersionIncompatibilities,
  UnignoredBestPractices,
  loading,
}: ARSigOveriewProps) {
  const { themeStretch } = useSettings();
  const showSystemRequirements = useGate('show_system_requirements');

  return (
    <Container maxWidth={themeStretch ? false : '100%'}>
      <Scrollbar>
        <Stack>
          <Text
            variant="subheader"
            className={styles['section-text-alignment']}
            id="availability-risks-overview-title"
          >
            {' '}
            Availability Risks
          </Text>
          <Grid
            container
            id="availability-risks-overview-details"
            spacing={5}
            padding={2}
            direction={'row'}
            sx={{ minWidth: 720 }}
          >
            <Grid item>
              <ARSigsOverviewCard
                title="Unsupported Versions"
                type="unsupported-versions"
                icon="mdi:layers-remove"
                data={
                  UnignoredUnsupportedVersions
                    ? ARSigsSeverityCounts(UnignoredUnsupportedVersions)
                    : undefined
                }
                hideSeverity={true}
                totalCount={UnsupportedVersionsCount}
                loading={loading}
              />
            </Grid>
            <Grid item>
              <ARSigsOverviewCard
                title="Defects"
                type="defects"
                icon="fluent-mdl2:defect-solid"
                data={
                  UnignoredDefects
                    ? ARSigsSeverityCounts(UnignoredDefects)
                    : undefined
                }
                totalCount={DefectsCount}
                loading={loading}
              />
            </Grid>
            <Grid item>
              <ARSigsOverviewCard
                title="Deprecations"
                type="deprecations"
                icon="carbon:in-progress-error"
                data={
                  UnignoredDeprecations
                    ? ARSigsSeverityCounts(UnignoredDeprecations)
                    : undefined
                }
                hideSeverity={true}
                totalCount={DeprecationsCount}
                loading={loading}
              />
            </Grid>
            <Grid item>
              <ARSigsOverviewCard
                title="Version Incompatibilities"
                type="version-incompatibilities"
                icon="teenyicons:layers-difference-solid"
                data={
                  UnignoredVersionIncompatibilities
                    ? ARSigsSeverityCounts(UnignoredVersionIncompatibilities)
                    : undefined
                }
                hideSeverity={true}
                totalCount={VersionIncompatibilitiesCount}
                loading={loading}
              />
            </Grid>
            <Grid item>
              <ARSigsOverviewCard
                title="Misconfigurations"
                type="misconfigurations"
                icon="fluent:error-circle-settings-20-regular"
                data={
                  UnignoredMisconfigurations
                    ? ARSigsSeverityCounts(UnignoredMisconfigurations)
                    : undefined
                }
                totalCount={MisconfigurationsCount}
                loading={loading}
              />
            </Grid>
            {showSystemRequirements?.value && (
              <Grid item>
                <ARSigsOverviewCard
                  title="System Requirements"
                  type="system-requirements"
                  icon="pajamas:requirements"
                  data={
                    UnignoredSystemRequirements
                      ? ARSigsSeverityCounts(UnignoredSystemRequirements)
                      : undefined
                  }
                  hideSeverity={true}
                  totalCount={SystemRequirementsCount}
                  loading={loading}
                />
              </Grid>
            )}
          </Grid>

          <Text
            variant="subheader"
            className={styles['section-text-alignment']}
            id="guardrails-overview-title"
          >
            Guardrails
          </Text>
          <Grid
            container
            id="guardrails-overview-details"
            spacing={5}
            padding={2}
            direction={'row'}
          >
            <Grid item>
              <ARSigsOverviewCard
                title="Guardrails"
                type="guardrails"
                icon="mingcute:certificate-fill"
                data={
                  UnignoredBestPractices
                    ? ARSigsSeverityCounts(UnignoredBestPractices)
                    : undefined
                }
                hideSeverity={true}
                totalCount={BestPracticesCount}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Stack>
      </Scrollbar>
    </Container>
  );
}
