import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

export default function useLocalStorage<ValueType>(
  key: string,
  defaultValue: ValueType,
): [
  ValueType,
  (newValue: ((oldValue: ValueType) => ValueType) | ValueType) => void,
] {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue === null ? defaultValue : JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = (
    newValue: ((oldValue: ValueType) => ValueType) | ValueType,
  ) => {
    setValue((currentValue: any) => {
      const result = isSetterFunction(newValue)
        ? newValue(currentValue)
        : newValue;
      localStorage.setItem(key, JSON.stringify(result));
      return result;
    });
  };

  return [value, setValueInLocalStorage];
}

function isSetterFunction<ValueType>(
  newValue: ((oldValue: ValueType) => ValueType) | ValueType,
): newValue is (oldValue: ValueType) => ValueType {
  return typeof newValue === 'function';
}
