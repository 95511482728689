import React, { useMemo } from 'react';
// components
import { Grid, Stack, SxProps, Typography, useTheme } from '@mui/material';

interface TextViewProps {
  label: string;
  value?: string;
  noColon?: boolean;
  sx?: SxProps;
  idString?: string;
}
const TextView: React.FC<TextViewProps> = ({
  label,
  value,
  noColon = false,
  children,
  sx,
  idString,
}) => {
  const theme = useTheme();
  const render = useMemo(() => {
    return !children ? (
      <Typography id={idString || ''} variant="body2" sx={{ textAlign: 'end' }}>
        {value}
      </Typography>
    ) : (
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        {children}
      </Stack>
    );
  }, [children, value]);

  return (
    <Grid
      container
      alignItems="center"
      sx={Object.assign(sx || {}, { paddingBottom: theme.spacing(1) })}
      width="100%"
    >
      <Grid item md={5}>
        <Typography
          variant="h5"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {label} {!noColon}
        </Typography>
      </Grid>
      <Grid item md={7}>
        {render}
      </Grid>
    </Grid>
  );
};

export default TextView;
