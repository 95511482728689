import { useState } from 'react';
import { Button } from 'subframe/index';
import { submitFeedback } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';
import { toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';

interface Props extends React.ComponentProps<typeof Button> {
  handleClose?: () => void;
}
// TODO: Refactor RequestFreeTrialExtensionCTA, GuidedPocContactUsButton, ProductPlanContactUsButton to use a common component
export const GuidedPocContactUsButton = ({
  size = 'small',
  variant = 'brand-primary',
  handleClose,
  ...otherProps
}: Props) => {
  const { account, user, currentOrganization } = useUserAccountState();

  const [isSubmitting, setisSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();

  const submitGuidedPOCRequest = async () => {
    logEvent('start-guided-poc-request', {
      view: 'Upgrades',
      planRequest: 'Guided POC',
    });

    try {
      await submitFeedback(
        {
          title: 'ContactUsGuidedPOCRequest',
          details: JSON.stringify({
            request:
              '🔔 Heads up, a customer 🙋‍♂️🙋‍♀️ just requested for Guided POC! ',
            accountId: account?.accountId,
            orgId: currentOrganization?.id,
            view: 'Upgrades',
            planRequest: 'Guided POC',
          }),
          user_email: user.email || user.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );

      enqueueSnackbar(
        'We have received your request to start a Guided POC. We will be in touch with you shortly',
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        `Failed to request Guided POC. Please try again or escalate to Chkk`,
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } finally {
      setisSubmitting(false);
    }
  };
  return (
    <Button
      variant={variant}
      icon="FeatherContact"
      size={size}
      loading={isSubmitting}
      disabled={isSubmitting}
      onClick={async (event) => {
        event.stopPropagation();
        event.preventDefault();
        setisSubmitting(true);
        await submitGuidedPOCRequest();
        handleClose && handleClose();
      }}
      {...otherProps}
    >
      Contact us for Guided POC
    </Button>
  );
};
