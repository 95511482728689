import {
  Cluster,
  ResolvedSubscription,
  SubscriptionPlan,
  UpgradePlan,
  UpgradeTemplate,
} from 'api/models';
import {
  addMinutes,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  endOfMonth,
  format,
  fromUnixTime,
  getUnixTime,
  isBefore,
  subMonths,
} from 'date-fns';
import { getDateFromUnixTimestamp } from './formatTime';
import { isEmpty } from 'lodash';

// This method checks if the first month has passed since the user created their first cluster.
// The month is considered to have passed if the last date of the previous month has passed regardless of the day of the month.
export const isFirstMonthPassedSinceOnboarded = (clusters: Cluster[]) => {
  const lastDateOfPreviousMonth = endOfMonth(subMonths(new Date(), 1));
  const lastTimestampOfPreviousMonth = getUnixTime(lastDateOfPreviousMonth);

  return clusters.some(
    (cluster) =>
      cluster.internal_k8s_ref !== 'example-cluster-ref' &&
      cluster.created < lastTimestampOfPreviousMonth,
  );
};

export function getMostRelevanSubscription(
  subs: ResolvedSubscription[] | undefined,
): ResolvedSubscription | undefined {
  if (subs === undefined || subs.length === 0) return undefined;

  return (subs || []).sort(
    (a, b) =>
      (b.expiry || Number.MAX_SAFE_INTEGER) -
      (a.expiry || Number.MAX_SAFE_INTEGER),
  )[0];
}

export function getHumanReadableDate(
  unixTimestamp: number | undefined, //unix time
): string {
  if (!unixTimestamp) return '-';

  const date = getDateFromUnixTimestamp(unixTimestamp);
  return date ? format(addMinutes(date, date.getTimezoneOffset()), 'PP') : '-';
}

export function showUpdateNow(subscriptionPlan: SubscriptionPlan | undefined) {
  if (
    subscriptionPlan &&
    ['FreeTrial', 'GuidedPOC', 'Demo'].includes(subscriptionPlan)
  )
    return true;
  else return false;
}
export function isSubscriptionPlan(plan: string | undefined) {
  return Object.values(SubscriptionPlan).includes(plan as SubscriptionPlan);
}
function isTrialOrPOC(plan: string | undefined) {
  return (
    plan === SubscriptionPlan.FreeTrial || plan === SubscriptionPlan.GuidedPOC
  );
}

export function canRequestPlanExtension(subscription?: ResolvedSubscription) {
  return (
    !isEmpty(subscription) &&
    isTrialOrPOC(subscription?.plan) &&
    (getExpiryInDays(subscription?.expiry) || 0) <= 14
  );
}
export function getExpiryInText(subscriptionPlan: SubscriptionPlan) {
  if (subscriptionPlan === undefined) return '';
  if (['Starter', 'Business', 'Enterprise'].includes(subscriptionPlan))
    return 'Renews';
  else return 'Ends';
}

export function getExpiryInDays(
  unixTimeStamp: number | undefined,
): number | undefined {
  const date = getDateFromUnixTimestamp(unixTimeStamp);

  if (!unixTimeStamp || !date) {
    return undefined;
  }

  return differenceInDays(date, new Date());
}

export function getExpiryInDaysText(unixTimeStamp: number | undefined): string {
  let expiryDays = getExpiryInDays(unixTimeStamp);
  if (expiryDays === 0) return 'expires today';
  else if (expiryDays && expiryDays > 0)
    return `${
      expiryDays > 1 ? `${expiryDays} days` : `${expiryDays} day`
    } remaining`;
  else if (expiryDays && expiryDays < 0) return ExpiryDisplay(unixTimeStamp);
  else return '';
}

export function getHeaderExpiryInDaysText(
  unixTimeStamp: number | undefined,
  subscriptionPlan: string | undefined,
): string {
  let expiryDays = getExpiryInDays(unixTimeStamp);
  if (expiryDays === 0) return `Your ${subscriptionPlan} Plan expires today.`;
  else if (expiryDays && expiryDays > 0)
    return `${
      expiryDays > 1 ? `${expiryDays} days` : `${expiryDays} day`
    } left in your ${subscriptionPlan} Plan.`;
  else if (expiryDays && expiryDays < 0)
    return `Your ${subscriptionPlan} Plan ${ExpiryDisplay(unixTimeStamp)}.`;
  else return '';
}

export function getAlertBannerExpiryInDaysText(
  unixTimeStamp: number | undefined,
  subscriptionPlan: string | undefined,
): string {
  if (unixTimeStamp === undefined)
    return `You are currently using the ${subscriptionPlan} Plan`;
  let expiryDays = getExpiryInDays(unixTimeStamp);
  if (expiryDays === 0) return `Your ${subscriptionPlan} Plan expires today`;
  else if (expiryDays && expiryDays > 0)
    return `Your ${subscriptionPlan} Plan ends in ${
      expiryDays > 1 ? `${expiryDays} days` : `${expiryDays} day`
    }`;
  else if (expiryDays && expiryDays < 0)
    return `Your ${subscriptionPlan} Plan ${ExpiryDisplay(unixTimeStamp)}.`;
  else return '';
}

export function getPlanExpiryState(
  expiryDate: number | undefined, //unix time
): 'default' | 'warning' | 'error' | 'expired' | null {
  const earliestExpiry = getExpiryInDays(expiryDate);
  if (earliestExpiry === undefined) {
    return null;
  } else if (earliestExpiry > 31) {
    return 'default';
  } else if (earliestExpiry > 7) {
    return 'warning';
  } else if (earliestExpiry < 0) {
    return 'expired';
  } else return 'error';
}

export const getNodeUsageCount = (activeClusters: Cluster[] | undefined) => {
  if (activeClusters === undefined) return undefined;
  else
    return activeClusters.reduce(
      (accumulator, cluster) => accumulator + cluster.node_count,
      0,
    );
};

export const getProgressValue = (
  usage: number | undefined,
  total: number | undefined,
) => {
  if (!usage || !total) return 0;

  return Math.min((usage / total) * 100, 100);
};

export const getUpgradeTemplatesCount = (
  upgradeTemplates: UpgradeTemplate[],
) => {
  //TODO: add logic to filter out upgrade plans within a year
  return upgradeTemplates.filter(
    (upgradeTemplates) =>
      // new plans
      (upgradeTemplates?.first_published &&
        upgradeTemplates.first_published > 0) ||
      // plans published before subscriptions feature
      (upgradeTemplates?.created &&
        ['in-progress', 'completed', 'update-in-progress'].includes(
          upgradeTemplates?.status,
        )),
  ).length;
};

export const getUpgradePlansCount = (upgradePlans: UpgradePlan[]) => {
  //TODO: add logic to filter out upgrade plans within a year
  return upgradePlans.filter(
    (upgradePlan) =>
      // new plans
      (upgradePlan?.first_published && upgradePlan.first_published > 0) ||
      // plans published before subscriptions feature
      (upgradePlan.created &&
        ['in-progress', 'completed'].includes(upgradePlan.status)),
  ).length;
};

export function ExpiryDisplay(expiryTimestamp: number | undefined): string {
  // Check if expiry date is in the past and return a string with the number of days/months/years since expiry
  // If the string is empty, the expiry date is either in the future or undefined
  if (!expiryTimestamp) return '';
  const currentDate = new Date();
  const expiryDate = fromUnixTime(expiryTimestamp);

  if (isBefore(expiryDate, currentDate)) {
    const daysUntilExpiry = Math.abs(differenceInDays(expiryDate, currentDate));

    if (daysUntilExpiry < 30) {
      return `expired ${daysUntilExpiry} day${
        daysUntilExpiry !== 1 ? 's' : ''
      } ago`;
    } else {
      const yearsUntilExpiry = Math.abs(
        differenceInYears(expiryDate, currentDate),
      );
      const monthsUntilExpiry = Math.abs(
        differenceInMonths(expiryDate, currentDate),
      );

      if (yearsUntilExpiry > 0) {
        return `expired ${yearsUntilExpiry} year${
          yearsUntilExpiry !== 1 ? 's' : ''
        } ago`;
      } else {
        return `expired ${monthsUntilExpiry} month${
          monthsUntilExpiry !== 1 ? 's' : ''
        } ago`;
      }
    }
  } else {
    return '';
  }
}
