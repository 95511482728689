/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * ???
 */
export type KubernetesProvider =
  (typeof KubernetesProvider)[keyof typeof KubernetesProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KubernetesProvider = {
  EKS: 'EKS',
  KinD: 'KinD',
  GKE: 'GKE',
  AKS: 'AKS',
  Minikube: 'Minikube',
  local: 'local',
  RKE: 'RKE',
  K3S: 'K3S',
  'OpenShift-AWS': 'OpenShift-AWS',
  'OpenShift-Azure': 'OpenShift-Azure',
  'OpenShift-GCP': 'OpenShift-GCP',
  'OpenShift-local': 'OpenShift-local',
} as const;
