'use client';

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface LogoIconRootProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'x-small' | 'small' | 'medium';
  image?: string;
  className?: string;
}

const LogoIconRoot = React.forwardRef<HTMLElement, LogoIconRootProps>(
  function LogoIconRoot(
    { size = 'x-small', image, className, ...otherProps }: LogoIconRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/13f819c9 flex h-5 w-5 flex-col items-center justify-center gap-2 rounded-full bg-neutral-50',
          { 'h-9 w-9': size === 'medium', 'h-7 w-7': size === 'small' },
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {image ? (
          <img
            className={SubframeCore.twClassNames('h-3 w-3 flex-none', {
              'h-5 w-5 flex-none': size === 'medium',
              'h-3.5 w-3.5 flex-none': size === 'small',
            })}
            src={image}
          />
        ) : null}
      </div>
    );
  },
);

export const LogoIcon = LogoIconRoot;
