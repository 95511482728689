'use client';
/*
 * Documentation:
 * See all button — https://app.subframe.com/library?component=See+all+button_6c277935-fa63-49cf-84d2-037df9f9a413
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface SeeAllButtonRootProps extends React.HTMLAttributes<HTMLDivElement> {
  text?: string;
  icon?: SubframeCore.IconName;
  className?: string;
}

const SeeAllButtonRoot = React.forwardRef<HTMLElement, SeeAllButtonRootProps>(
  function SeeAllButtonRoot(
    {
      text,
      icon = 'FeatherChevronRight',
      className,
      ...otherProps
    }: SeeAllButtonRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/6c277935 flex w-full cursor-pointer items-center gap-2 rounded bg-neutral-100 pt-2 pr-3 pb-2 pl-3',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {text ? (
          <span className="w-full grow shrink-0 basis-0 text-label-bold font-label-bold text-subtext-color group-hover/6c277935:text-default-font">
            {text}
          </span>
        ) : null}
        <div className="flex items-center gap-1">
          <SubframeCore.Icon
            className="text-body font-body text-subtext-color group-hover/6c277935:text-default-font"
            name={icon}
          />
        </div>
      </div>
    );
  },
);

export const SeeAllButton = SeeAllButtonRoot;
