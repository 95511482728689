import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function TableSortLabel(theme: Theme) {
  return {
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '.MuiTableSortLabel-icon': {
            color: 'white !important',
          },
          '&:hover, &.Mui-active': {
            color: theme.palette.grey[500],
          },
        },
      },
    },
  };
}
