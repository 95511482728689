/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chkk API v1.0
 * Chkk API for Kubernetes deployment and upgrade safety.
 * OpenAPI spec version: v1.0
 */

/**
 * The type of action
 */
export type ActionType = (typeof ActionType)[keyof typeof ActionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActionType = {
  VERIFY: 'VERIFY',
} as const;
