import { useTheme } from '@mui/material/styles';

import {
  Container,
  Divider,
  Skeleton,
  Stack,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { Spacer } from 'components/Spacer';
import { clearSensitivity, DetectedTerm, NotDetectedTerm } from 'utils/filters';
import {
  StyledDivider,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from '../../layouts/dashboard/ClusterDetailsSearchHeader/ClusterDetailsTabs';

interface Props {
  title: string;
  hideSeverity?: boolean;
}
interface ExpandableRowSkeletonProps {
  hideSeverity: boolean;
}
const ExpandableRowSkeleton = ({
  hideSeverity,
}: ExpandableRowSkeletonProps) => {
  return (
    <TableRow>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      {!hideSeverity && (
        <TableCell>
          <Skeleton />
        </TableCell>
      )}
    </TableRow>
  );
};

export default function ARSigsTabSkeleton({ title, hideSeverity }: Props) {
  const theme = useTheme();
  return (
    <Container sx={{ mt: theme.spacing(3.5), pr: '0px !important' }}>
      {/*Search header skeleton*/}
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <StyledToggleButtonGroup
            aria-label="text button group"
            value={'Null'}
            exclusive
            sx={{ border: 'none' }}
          >
            {clearSensitivity(title) !== 'guardrails' && (
              <Divider
                orientation="vertical"
                flexItem
                color="red"
                sx={{
                  width: '3px',
                  height: '70px',
                }}
              />
            )}
            {clearSensitivity(title) !== 'guardrails' && (
              <StyledToggleButton
                value="NeedsAttention"
                aria-label="NeedsAttention"
                disableTouchRipple
                disableRipple
                disableFocusRipple
              >
                <Stack direction="column">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <span>Need Attention </span>
                    <Skeleton
                      variant="circular"
                      width={18}
                      height={18}
                      sx={{ ml: 1 }}
                    />{' '}
                  </Stack>
                  <span style={{ fontSize: '20px' }} id="need-attention-count">
                    <Skeleton
                      sx={{ border: 'none', height: '40px', width: '30px' }}
                    />
                  </span>
                </Stack>
              </StyledToggleButton>
            )}
            <Divider
              orientation="vertical"
              flexItem
              color={theme.palette.grey[700]}
              sx={{ width: '3px', height: '66px' }}
            />
            <StyledToggleButton value="Ignored">
              <Stack>
                {DetectedTerm(title)}
                <Typography sx={{ fontSize: '20px' }}>
                  <Skeleton
                    sx={{ border: 'none', height: '40px', width: '30px' }}
                  />
                </Typography>
              </Stack>
            </StyledToggleButton>

            <Divider
              orientation="vertical"
              flexItem
              color="#007B55"
              sx={{
                width: '3px',
                height: '66px',
              }}
            />
            <StyledToggleButton value="UnDetected">
              <Stack>
                Ignored
                <span style={{ fontSize: '20px' }}>
                  <Skeleton
                    sx={{ border: 'none', height: '40px', width: '30px' }}
                  />
                </span>
              </Stack>
            </StyledToggleButton>

            <StyledDivider
              orientation="vertical"
              flexItem
              color={theme.palette.grey[100]}
            />
            <StyledToggleButton
              value="Detected"
              aria-label="Detected"
              sx={{ minWidth: '85px' }}
            >
              <Stack>
                {NotDetectedTerm(title)}
                <span style={{ fontSize: '20px' }}>
                  <Skeleton
                    sx={{ border: 'none', height: '40px', width: '30px' }}
                  />
                </span>
              </Stack>
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Stack>
        <Stack
          direction={'row'}
          spacing={3}
          sx={{ mt: 2 }}
          alignItems={'center'}
        >
          <Skeleton width={300} height={55} />
          {!hideSeverity && (
            <Skeleton variant="circular" width={50} height={50} />
          )}
        </Stack>
      </Stack>

      <Spacer value={42} />

      {/*ARSigs table Skeleton*/}
      <Stack alignItems={'center'}>
        <TableContainer
          sx={{
            minWidth: 720,
            backgroundColor: 'rgba(145, 158, 171, 0.08)',
            boxShadow: '0px 12px 24px -4px rgba(0, 0, 0, 0.12)',
            borderRadius: 'var(--rounded)',
            height: '432px',
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              minWidth: '100%',
              height: '31rem',
              backgroundColor: theme.palette.grey[800],
              boxShadow: '0px 12px 24px -4px rgba(0, 0, 0, 0.12)',
              borderRadius: '16px',
            }}
          />
        </TableContainer>
        <Skeleton width={370} height={64} />
      </Stack>
    </Container>
  );
}
