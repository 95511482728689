'use client';
/*
 * Documentation:
 * Check - Base — https://app.subframe.com/library?component=Check+-+Base_2230b05d-3651-4c3f-a1b8-a0c8457f1c87
 * Accordion — https://app.subframe.com/library?component=Accordion_d2e81e20-863a-4027-826a-991d8910efd9
 * Icon with background — https://app.subframe.com/library?component=Icon+with+background_c5d68c0e-4c0c-4cff-8d8c-6ff334859b3a
 * Tooltip — https://app.subframe.com/library?component=Tooltip_ccebd1e9-f6ac-4737-8376-0dfacd90c9f3
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { Accordion } from './Accordion';
import { IconWithBackground } from './IconWithBackground';
import { Tooltip } from './Tooltip';

interface CheckBaseRootProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'success' | 'error' | 'loading' | 'waiting';
  description?: string;
  checkNumber?: string;
  title?: string;
  className?: string;
}

const CheckBaseRoot = React.forwardRef<HTMLElement, CheckBaseRootProps>(
  function CheckBaseRoot(
    {
      variant = 'default',
      description,
      checkNumber,
      title,
      className,
      ...otherProps
    }: CheckBaseRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/2230b05d flex w-full cursor-pointer flex-col items-start gap-2',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full flex-col items-start gap-2">
          <Accordion
            trigger={
              <div className="flex w-full items-center gap-2 rounded-full">
                <Accordion.Chevron />
                {title ? (
                  <span className="text-body font-body text-default-font">
                    {title}
                  </span>
                ) : null}
                <SubframeCore.Loader
                  className={SubframeCore.twClassNames(
                    'hidden text-body font-body text-brand-600',
                    {
                      'inline-block': variant === 'loading',
                      hidden: variant === 'error' || variant === 'success',
                    },
                  )}
                />
                <IconWithBackground
                  className={SubframeCore.twClassNames('hidden', {
                    flex: variant === 'success',
                  })}
                  variant="success"
                />
                <IconWithBackground
                  className={SubframeCore.twClassNames('hidden', {
                    flex: variant === 'error',
                  })}
                  variant="error"
                  icon="FeatherAlertTriangle"
                />
                <SubframeCore.Tooltip.Provider>
                  <SubframeCore.Tooltip.Root>
                    <SubframeCore.Tooltip.Trigger asChild={true}>
                      <IconWithBackground
                        className={SubframeCore.twClassNames('hidden', {
                          flex: variant === 'waiting',
                        })}
                        icon="FeatherSlash"
                      />
                    </SubframeCore.Tooltip.Trigger>
                    <SubframeCore.Tooltip.Portal>
                      <SubframeCore.Tooltip.Content
                        side="bottom"
                        align="center"
                        sideOffset={8}
                        asChild={true}
                      >
                        <Tooltip>
                          This check couldn&#39;t run because your Cloud
                          Accounts are not onboarded to Chkk
                        </Tooltip>
                      </SubframeCore.Tooltip.Content>
                    </SubframeCore.Tooltip.Portal>
                  </SubframeCore.Tooltip.Root>
                </SubframeCore.Tooltip.Provider>
              </div>
            }
            defaultOpen={false}
          >
            <div className="flex h-full w-full grow shrink-0 basis-0 flex-col items-start gap-2 pt-2 pr-3 pb-2 pl-7">
              {description ? (
                <span className="whitespace-pre-wrap text-body font-body text-subtext-color">
                  {description}
                </span>
              ) : null}
            </div>
          </Accordion>
        </div>
        <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-300" />
      </div>
    );
  },
);

export const CheckBase = CheckBaseRoot;
