import useAmplitude from 'hooks/useAmplitude';
import usePostHog from 'hooks/usePostHog';

const AnalyticsEventLogger = () => {
  const { ampLogEvent, ampIdentify, ampOptInTracking, ampOptOutTracking } =
    useAmplitude();
  const {
    phCaptureEvent,
    phIdentify,
    phReset,
    phOptInTracking,
    phOptOutTracking,
  } = usePostHog();

  const logEvent = (event: string, metadata?: {}) => {
    ampLogEvent(event, metadata);
    phCaptureEvent && phCaptureEvent(event, metadata);
  };

  const optInTracking = () => {
    ampOptInTracking();
    phOptInTracking && phOptInTracking();
  };

  const optOutTracking = () => {
    ampOptOutTracking();
    phOptOutTracking && phOptOutTracking();
  };

  const reset = () => {
    phReset && phReset();
  };

  const identify = (email: string, name: string, userId: string) => {
    ampIdentify && ampIdentify(userId, name, email);
  };

  const postHogIdentify = (userId: string, accountId?: string) => {
    phIdentify && phIdentify(userId, accountId);
  };

  return {
    identify,
    postHogIdentify,
    logEvent,
    reset,
    optInTracking,
    optOutTracking,
  };
};

export default AnalyticsEventLogger;
