import { TableContents } from 'models/docs';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { LinkButton, Stack } from 'subframe/index';

interface TableOfContentsProps {
  contents:
    | TableContents[]
    | { head: string; id: string; subheads: TableContents[] }[];
  pageName: string;
  subhead?: boolean;
}

export const TableOfContents = ({
  contents,
  pageName,
  subhead,
}: TableOfContentsProps) => {
  const { logEvent } = AnalyticsEventLogger();

  return (
    <div className="flex flex-col items-start gap-2 self-stretch sticky w-2/6 overflow-auto float-none h-[100vh] top-12">
      {contents && contents.length && (
        <>
          <span className="w-full text-body-bold font-body-bold text-default-font">
            Table of Contents
          </span>
          {subhead ? (
            <Stack className="bg-black">
              {contents.map(({ head, id, subheads }, idx) => (
                <>
                  <LinkButton
                    key={idx}
                    onClick={() =>
                      logEvent(`${pageName}-table-of-contents`, {
                        contentClick: head,
                      })
                    }
                    className="text-start"
                    variant="neutral"
                  >
                    <a href={`#${id}`} className="text-body text-subtext-color">
                      {head}
                    </a>
                  </LinkButton>
                  <div className="mb-[10px]">
                    {subheads.map(({ label, link }, idx2) => (
                      <LinkButton
                        key={idx2}
                        onClick={() =>
                          logEvent(`${pageName}-table-of-contents`, {
                            contentClick: head,
                          })
                        }
                        className="text-start"
                        variant="neutral"
                      >
                        <a
                          href={`#${link}`}
                          className="text-body text-subtext-color"
                        >
                          {label}
                        </a>
                      </LinkButton>
                    ))}
                  </div>
                </>
              ))}
            </Stack>
          ) : (
            <>
              {contents.map(({ label, link }, idx) => (
                <LinkButton
                  key={idx}
                  onClick={() =>
                    logEvent(`${pageName}-table-of-contents`, {
                      contentClick: label,
                    })
                  }
                  className="text-start"
                  variant="neutral"
                >
                  <a href={`#${link}`} className="text-body text-subtext-color">
                    {label}
                  </a>
                </LinkButton>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};
