import { styled } from '@mui/material/styles';

interface SpacerProps {
  value: number;
}

export const Spacer = styled('div')(({ value }: SpacerProps) => {
  return {
    marginTop: value / 2,
    marginBottom: value / 2,
  };
});
