'use client';
/*
 * Documentation:
 * Card - CTA - Integration — https://app.subframe.com/library?component=Card+-+CTA+-+Integration_24d22211-65ef-41b6-86d7-ee74e020d3c4
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface CardCtaIntegrationRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  cta?: string;
  className?: string;
}

const CardCtaIntegrationRoot = React.forwardRef<
  HTMLElement,
  CardCtaIntegrationRootProps
>(function CardCtaIntegrationRoot(
  { cta, className, ...otherProps }: CardCtaIntegrationRootProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/24d22211 flex h-36 w-96 cursor-pointer flex-col items-center justify-center gap-2 rounded border border-dashed border-neutral-border pt-6 pr-6 pb-6 pl-6 hover:border hover:border-dashed hover:border-neutral-border hover:bg-neutral-100',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      <SubframeCore.Icon
        className="text-section-header font-section-header text-neutral-500"
        name="FeatherPlus"
      />
      {cta ? (
        <span className="text-body-bold font-body-bold text-neutral-500">
          {cta}
        </span>
      ) : null}
    </div>
  );
});

export const CardCtaIntegration = CardCtaIntegrationRoot;
