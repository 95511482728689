'use client';
/*
 * Documentation:
 * ActivityFeedbackFrame — https://app.subframe.com/library?component=ActivityFeedbackFrame_a0280fb1-4a83-4656-b858-c00094baaef3
 * Button — https://app.subframe.com/library?component=Button_3b777358-b86b-40af-9327-891efc6826fe
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface ActivityFeedbackFrameRootProps
  extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  variant?: 'default' | 'success' | 'error';
  className?: string;
}

const ActivityFeedbackFrameRoot = React.forwardRef<
  HTMLElement,
  ActivityFeedbackFrameRootProps
>(function ActivityFeedbackFrameRoot(
  {
    children,
    variant = 'default',
    className,
    ...otherProps
  }: ActivityFeedbackFrameRootProps,
  ref,
) {
  return (
    <div
      className={SubframeCore.twClassNames(
        'group/a0280fb1 flex items-center gap-2',
        className,
      )}
      ref={ref as any}
      {...otherProps}
    >
      {children ? (
        <div className="flex items-center gap-2">{children}</div>
      ) : null}
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          'hidden text-body font-body text-success-600 activity-feedback-animation',
          { 'inline-flex': variant === 'success' },
        )}
        name="FeatherCheck"
      />
      <SubframeCore.Icon
        className={SubframeCore.twClassNames(
          'hidden text-body font-body text-error-600 activity-feedback-animation',
          { 'inline-flex': variant === 'error' },
        )}
        name="FeatherX"
      />
    </div>
  );
});

export const ActivityFeedbackFrame = ActivityFeedbackFrameRoot;
